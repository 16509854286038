import {
  genCol,
  IBaseCamp,
  ITtdCloneCampaign,
  ITtdCloneCampaignObject,
  prepareReadCampAdgroups
} from './../../../utils/processExcelFile';
import XLSX from 'sheetjs-style';
import {
  getCookies,
  numSort,
  readTemplate,
  sortJsonArr,
  // generatePromiseErrorHandlers,
  mergeCondArray,
  // kf_download,
  genValPromise,
  kf_download
  // kf_download
} from '../../../utils';
import {
  determineCampaignAndFlightOperations,
  determinePackageOperations,
  getColumn,
  getIndexedPackages,
  ICampaign,
  IPackage,
  IIdxOpPackage,
  ITtdCampaignObject,
  templateHeaders,
  campNameIndex,
  CampaignFlight,
  ICampNameRegIdx,
  getIndexedCampaigns,
  ITtdCampaign,
  IExcelDataArray,
  getUniqueAdvertiserCategoryIds,
  getAllAdvertiserIds,
  ICloneAdvIds,
  prepareReadAdgroups,
  getAdgroupLoadStrategy,
  ICampAdgroups,
  splitExcelData,
  IPrepareReadAdgroups,
  indexCampaignAdgroups,
  determineCampaignsToClone,
  // ICloneCampaign,
  splitExcel
} from '../../../utils/processExcelFile';
import {
  createNewPackages,
  updatePackages,
  createNewCampaigns,
  updateCampaigns,
  exportPackages
} from '../../../services/packageService';

import { loadAdvAdGroups, loadCampaignData } from '../PackageExport';
import {
  postCampaignFlights,
  putCampaignFlights,
  postCampaigns,
  putCampaigns,
  reportProgress,
  cloneCampaigns,
  updateAdgroupBudgets,
  registerTokenRefresher,
  unregisterTokenRefresher
} from './../../../ttdApi/ttdapi';

import { AxiosResponse } from 'axios';
import { IClonePollingData } from '../../../ttdApi/postClonePolling';
import { generateCampaignLink } from '../../../utils/ttdUtils';

interface IExcelOutPut {
  wb: XLSX.WorkBook;
  campJson: Array<any>;
  campExtraHeaders: Array<any>;
  baseSheet: string;
  extraHeaderStart: string;
  extraHeaderCol: string;
  HeaderEnd: string;
  campLinkHeaderStart: string;
  campLinkHeaderCol: string;
  colWidthArr: Array<any>;
  bCampaignSheet: Boolean;
  bAddCampLink: Boolean;
}

const { utils } = XLSX;
//prettier-ignore
var part5Progress = (funcCallback: Function, step: number ) => {
  var part1 = 0, part2 = 0, part3 = 0, part4 = 0, part5 = 0;
  const adjustProgress = () => {
    var pct = (part1 + part2 + part3 + part4 + part5*8) /12 ;
      var multiplier = 0.9;
      var remPct = Math.floor(pct * multiplier*100)/100;
      //prettier-ignore
      console.log('partialProgress pct ' + pct +  " mult 0.9 :" + remPct);
      if (typeof funcCallback === "function") funcCallback(step, remPct);
  };
  const update1 = (percent: number) => {
    part1 = percent;
    adjustProgress();
  };
  const update2 = (percent: number) => {
    part2 = percent;
    adjustProgress();
  };
  const update3 = (percent: number) => {
    part3 = percent;
    adjustProgress();
  };
  const update4 = (percent: number) => {
    part4 = percent;
    adjustProgress();
  };
  const update5 = (percent: number | Object) => {
    if(typeof percent === 'number'){
      part5 = percent;
    }else {
      part5 = percent['percent'];
    }
    adjustProgress();
  };
  return {f1:update1, f2:update2, f3: update3, f4:update4, f5:update5}
};

//prettier-ignore
var clProgress = (funcCallback: Function, step: number ) => {
  const adjustProgress = (pct) => {
      console.log('clone Progress ' + pct);
      if (typeof funcCallback === "function") funcCallback(step, pct/100);
  };
  return adjustProgress
};

//prettier-ignore
var part3Progress = (funcCallback: Function, step: number ) => {
  var part1 = 0, part2 = 0, part3 = 0;
  const adjustProgress = () => {
    var pct = (part1 + part2+part3 ) /3 ;
      var multiplier = 0.8;
      var remPct = Math.floor(pct * multiplier*100)/100;
      //prettier-ignore
      console.log('partialProgress pct ' + pct +  " mult 0.8 :" + remPct);
      if (typeof funcCallback === "function") funcCallback(step, remPct);
  };
  const update2 = (percent: number) => {
    part2 = percent;
    adjustProgress();
  };
  const update3 = (percent: number) => {
    part3 = percent;
    adjustProgress();
  };
  const pkgProgress_p = (pct) => {
    console.log('pkgProgress_p' + pct);
    part1 = pct;
    adjustProgress();
  };
  // non ttd api function - introduce similar handling by invoking reportProgress explicitely
  var pkgProgress = reportProgress(pkgProgress_p, true, '')!; // ! enforces a not undefined check here
  // fake progress for package loading as we have no real feedback
    pkgProgress({ percent: 20 });
    setTimeout(() => {
        pkgProgress({ percent: 50 });
    }, 500);
    setTimeout(() => {
        pkgProgress({ percent: 75 });
    }, 1000);
  return {f1:pkgProgress as Function, f2:update2, f3: update3}
};

// //prettier-ignore
// const handleLoadProgress = (funcCallback: Function, divider:number = 1) => {

//   const pkgProgress_p = (pct) => {
//     console.log('pkgProgress_p' + pct);
//     if(typeof funcCallback === 'function')
//       funcCallback(0, pct);
//   };
//   const remainingProgress = (pct) => {
//     if(divider > 1) {
//      var multiplier = ((divider - 1) / divider)
//       var remPct = Math.floor((pct * multiplier) + (1/divider*100))
//       console.log('remainingProgress pct' + pct +' remPct ' + remPct);
//       if(typeof funcCallback === 'function')
//         funcCallback(0, remPct);
//     }
//   }
//   // non ttd api function - introduce similar handling by invoking reportProgress explicitely
//   var pkgProgress = reportProgress(pkgProgress_p, true, '');
//   if(typeof pkgProgress === 'function' ){
//     pkgProgress({ percent: Math.floor(20/divider) });
//     setTimeout(() => {
//       if (typeof pkgProgress === 'function') {
//         pkgProgress({ percent: Math.floor(50/divider) });
//       }
//     }, 500);
//     setTimeout(() => {
//       if (typeof pkgProgress === 'function') {
//         pkgProgress({ percent: Math.floor(75/divider) });
//       }
//     }, 1000);
//   }
//   return {pkgProgress, remainingProgress}
// };

export const prepareCampCloneAgData = async (
  campData: Array<any>,
  campAdvIds: Array<string>,
  cloneData: Array<any>,
  cloneAdvIds: Array<string>,
  progressCallback: Function
) => {
  var agData: ICampAdgroups[] = [],
    cAdvAgCamp: IPrepareReadAdgroups = {},
    clAdvAgCamp: IPrepareReadAdgroups = {},
    shouldLoadAg = false,
    shouldLoadCloneAg = false,
    shouldLoadCampAg = false,
    combAdvIds: Array<string> = []; // campAdvIds.concat(cloneAdvIds);

  if (cloneData && cloneData.length > 0) {
    clAdvAgCamp = prepareReadAdgroups(cloneData);
    // ToDo: perhaps refine strategy later - currently we just test if any clone campaigns are present
    shouldLoadCloneAg = getAdgroupLoadStrategy(clAdvAgCamp);
    combAdvIds = cloneAdvIds;
  }
  if (campData && campData.length > 0) {
    cAdvAgCamp = prepareReadCampAdgroups(campData);
    shouldLoadCampAg = getAdgroupLoadStrategy(cAdvAgCamp);
    combAdvIds = Array.from(new Set(combAdvIds.concat(campAdvIds)));
  }
  shouldLoadAg = shouldLoadCloneAg || shouldLoadCampAg;
  if (shouldLoadAg) {
    agData = await loadAdvAdGroups(combAdvIds, progressCallback);
    // kf_download('agData.json', JSON.stringify(agData));
  }
  return { agData, cAdvAgCamp, clAdvAgCamp, shouldLoadAg };
};

export const determineCampaignIds = (
  advAgCamp: IPrepareReadAdgroups,
  campNamesIdx: Object
) => {
  if (advAgCamp) {
    var advArrKeys = Object.keys(advAgCamp);
    var allCampIds: Array<string> = [];
    // lookup campaign ids if we just have only names provided for some campaigns
    advArrKeys.forEach((adv) => {
      advAgCamp[adv].CampaignNames.forEach((campName) => {
        var idxCamp = campNamesIdx[campName];
        if (idxCamp) {
          var cIdx = advAgCamp[adv].CampaignIds;
          // campaign name is unique and we don't have a campaign id registered yet
          var cId = idxCamp.id;
          if (idxCamp.count === 1 && !cIdx.includes(cId)) {
            cIdx.push(cId);
          }
        }
      });
      var cAdvIds = advAgCamp[adv].CampaignIds;
      if (cAdvIds && cAdvIds.length > 0) {
        allCampIds = allCampIds.concat(cAdvIds);
      }
    });
    if (!advAgCamp.all) {
      advAgCamp.all = {
        CampaignIds: [] as Array<string>,
        CampaignNames: [] as Array<string>
      };
    }

    advAgCamp.all.CampaignIds = advAgCamp.all.CampaignIds.concat(allCampIds);
  }
  return advAgCamp;
};

/**
 * Index campaign adgroup data
 * (will look up missing campaign ids + includes ad group data in indexed base campains)
 * @param agData - loaded ad group data from API
 * @param advAgCamp - advertiser indexed campaigns
 * @param iCampData - indexed campaigns from API
 * @param campNamesIdx - indexed campaign names
 * @param bFilterCampaigns - true to only index campaigns in advAgCamp
 */
export const indexCampaignAdgroupData = (
  agData: ICampAdgroups[],
  advAgCamp: IPrepareReadAdgroups,
  iCampData: ITtdCampaignObject,
  campNamesIdx: Object,
  bFilterCampaigns = false
) => {
  // translate campaign names into campaign ids
  advAgCamp = determineCampaignIds(advAgCamp, campNamesIdx);
  // don't filter ad group names for cloning, but filter for package campaign budget updates
  var cAgData = agData.filter(
    (el) =>
      !bFilterCampaigns || advAgCamp.all.CampaignIds.includes(el.CampaignId)
  );
  // index Adgroups
  iCampData = indexCampaignAdgroups(iCampData, cAgData, !bFilterCampaigns);
};

export const preparePackageCampaignCloneData = async (
  advCatIds: ICloneAdvIds,
  excelData: IExcelDataArray,
  progressCallback: Function
) => {
  // var errorArr = [],
  //   errorMsgArr = [];

  try {
    // var { handle_errors } = generatePromiseErrorHandlers(errorArr, errorMsgArr);
    // get data for each tab

    var { pkgData, pkgCampData, clCampData } = splitExcelData(excelData);
    // kf_download('excelData.json', JSON.stringify(excelData));

    var { f1, f2, f3 } = part3Progress(progressCallback, 0);
    var promiseArr: Promise<any>[] = [];
    // create promises for each load; those will only be executed, when we have data for the matching tab in Excel
    // prettier-ignore
    var pkgIdx = pushPromiseOrFullProgress( pkgData, f1, promiseArr, exportPackages, advCatIds.AdvertiserId || []);
    // will be true (non empty) for campaign or clone tasks
    var cArr = mergeCondArray(pkgCampData, clCampData);
    // potentially we need a campaign lookup for packages as well
    var cArr1 = mergeCondArray(pkgData, cArr);
    // prettier-ignore
    var pkgCampIdx = pushPromiseOrFullProgress(cArr1, f2, promiseArr, loadCampaignData, getAllAdvertiserIds(advCatIds), f2);

    // prettier-ignore
    var clCampIdx = pushPromiseOrFullProgress( cArr, f3, promiseArr, prepareCampCloneAgData, pkgCampData, advCatIds.AdvertiserId||[], clCampData,advCatIds.BaseAdvertiserId|| [], f3);
    var results = await Promise.all(promiseArr);
    // only for package loading we don't have a percentage feedback after full loading in place
    f1({ percent: 100, immediate: true, noForward: true });
    // get results
    var pkgArr = getResIndex(results, pkgIdx),
      pkgCampResult = getResIndex(results, pkgCampIdx),
      clCampRes = getResIndex(results, clCampIdx);
    var mPkgData = getIndexedPackages(pkgArr);
    var campData = pkgCampResult.campData;

    var iCampData = getIndexedCampaigns(campData);
    var campNamesIdx = campNameIndex(campData);
    var { agData, cAdvAgCamp, clAdvAgCamp } = clCampRes;
    // handle potential ad group data for cloning;
    // prettier-ignore
    indexCampaignAdgroupData(agData, clAdvAgCamp,  iCampData, campNamesIdx);
    // also index Adgroup data for campaigns we modify
    // prettier-ignore
    indexCampaignAdgroupData(agData, cAdvAgCamp,  iCampData, campNamesIdx, true );

    // kf_download('cf_campdata.json', JSON.stringify(campData));
    // kf_download('agdata.json', JSON.stringify(agData));
    // kf_download('iCampData.json', JSON.stringify(iCampData));
    // kf_download('campNamesIdx.json', JSON.stringify(campNamesIdx));
    // kf_download('advAgCamp.json', JSON.stringify(advAgCamp));
    return {
      pkgData: mPkgData,
      iCampData,
      campNamesIdx,
      cAdvAgCamp,
      clAdvAgCamp
    };
  } catch (e) {
    if (e instanceof Error) {
      console.log('preparePackageCampaignCloneData error' + e.message);
    }
    // prettier-ignore
    return { pkgData: {}, iCampData: {}, campNamesIdx: {}, cAdvAgCamp: {} as IPrepareReadAdgroups, clAdvAgCamp: {} as IPrepareReadAdgroups};
  }
};

const checkCampaigns = (
  excelData: Array<any>,
  iCampData: ITtdCampaignObject,
  campNamesIdx: ICampNameRegIdx
) => {
  var { pkgCampSheetData } = splitExcel(excelData),
    kLen = Object.keys(iCampData).length;
  if (kLen > 0 && pkgCampSheetData) {
    var campJson: ICampaign[] = pkgCampSheetData.sheetData;
    var extraHeaders = pkgCampSheetData.extraHeaders;
    var date1904 = pkgCampSheetData.date1904;
    // TODO: Kokai testing
    // prettier-ignore
    var { campError, campVerifyErrors, flightCreate, flightUpdate, campCreate, campUpdate, agBudgetUpdateCamps } = determineCampaignAndFlightOperations(campJson, iCampData, campNamesIdx, date1904);
    if (campError) {
      campVerifyErrors.forEach((err, idx) => {
        if (err) {
          campJson[idx]['Error Report'] = err;
          // console.log(campJson[idx]);
        }
      });
    }
    // prettier-ignore
    return { campJson, campError, campVerifyErrors, flightCreate, flightUpdate, campCreate, campUpdate, agBudgetUpdateCamps,  campExtraHeaders: extraHeaders };
  } else {
    // prettier-ignore
    return { campError: false, campJson: [], flightCreate:[], flightUpdate:[], campCreate:{}, campUpdate:{}, agBudgetUpdateCamps:[], campExtraHeaders:[] };
  }
};

const checkCloneCampaigns = (
  excelData: Array<any>,
  iCampData: ITtdCampaignObject,
  campNamesIdx: ICampNameRegIdx
) => {
  var { clCampSheetData } = splitExcel(excelData),
    kLen = Object.keys(iCampData).length;
  if (kLen > 0 && clCampSheetData) {
    var clCampJson: ICampaign[] = clCampSheetData.sheetData;
    var extraHeaders = clCampSheetData.extraHeaders;
    var date1904 = clCampSheetData.date1904;
    // TODO: Kokai testing
    // prettier-ignore
    var { clCampError, clCampVerifyErrors,  clCampCreate } = determineCampaignsToClone(clCampJson, iCampData, campNamesIdx, date1904);
    if (clCampError) {
      clCampVerifyErrors.forEach((err, idx) => {
        if (err) {
          clCampJson[idx]['Error Report'] = err;
          // console.log(clCampJson[idx]);
        }
      });
    }
    // prettier-ignore
    return { clCampJson, clCampError, clCampVerifyErrors, clCampCreate, clCampExtraHeaders: extraHeaders };
  } else {
    // prettier-ignore
    return { clCampError: false, clCampJson: [], clCampExtraHeaders:[] };
  }
};

const prepareExcelOutPut = (params: IExcelOutPut) => {
  var {
    wb,
    campJson,
    campExtraHeaders,
    baseSheet,
    extraHeaderStart,
    extraHeaderCol,
    HeaderEnd,
    campLinkHeaderStart,
    campLinkHeaderCol,
    colWidthArr,
    bCampaignSheet,
    bAddCampLink
  } = params;
  var mySheet = wb.Sheets[baseSheet];

  var sHeader = templateHeaders[baseSheet];
  var aCampExtraHeaders = campExtraHeaders.filter(
    (el) => el !== 'Error Report'
  );
  var sExtraHeader = ['Error Report'];

  if (bAddCampLink && bCampaignSheet) {
    sExtraHeader = ['Error Report', 'Campaign Link'];
  } else if (bCampaignSheet) {
    // remove CampaignLink column to avoid confusion
    aCampExtraHeaders = aCampExtraHeaders.filter(
      (el) => el !== 'Campaign Link'
    );
    campJson = campJson.map((el) => {
      if (el['Campaign Link']) {
        delete el['Campaign Link'];
      }
      return el;
    });
  }

  sExtraHeader = sExtraHeader.concat(
    aCampExtraHeaders.filter((el) => !sExtraHeader.includes(el))
  );
  var campSheet = utils.sheet_add_aoa(mySheet, [sExtraHeader], {
    origin: extraHeaderStart
  });
  sHeader = sHeader.concat(sExtraHeader);

  campJson = sortJsonArr(campJson, numSort, 'rowIdx');
  campJson = campJson.map((el) => {
    delete el.rowIdx;
    return el;
  });
  campSheet = utils.sheet_add_json(
    campSheet, // worksheet
    campJson, // data
    {
      // options
      header: sHeader, // properties to read from each object
      skipHeader: true, // do not include header row
      origin: 'A2', // start from cell A2
      dateNF: 'yyyy-mm-dd hh:mm:ss' //22,
    }
  );
  campSheet['!rows'] = [{ hpt: 36 }];
  // prettier-ignore
  campSheet['!cols'] = colWidthArr;
  campSheet[extraHeaderStart].s = campSheet[HeaderEnd].s;
  var campExtraColOffset = 0;
  if (bCampaignSheet && campSheet[campLinkHeaderStart]) {
    campSheet[campLinkHeaderStart].s = campSheet[HeaderEnd].s;
  }
  if (bAddCampLink) {
    campExtraColOffset = 1;
  }
  var ehLen = aCampExtraHeaders.length;
  if (ehLen > 0) {
    // copy header style for extra columns
    var colArr = genCol(ehLen + campExtraColOffset, extraHeaderCol);
    colArr.forEach((colLetter) => {
      if (campSheet[colLetter + '1']) {
        campSheet[colLetter + '1'].s = campSheet[HeaderEnd].s;
        getColumn(colLetter, campSheet).forEach((el) => {
          var cell = campSheet[el];
          if (cell) {
            if (el !== colLetter + '1') {
              cell.s = {
                alignment: {
                  horizontal: 'left',
                  vertical: 'center'
                }
              };
            }
          }
        });
      }
    });
  }
  [extraHeaderCol].forEach((col) => {
    getColumn(col, campSheet).forEach((el) => {
      if (el !== extraHeaderStart) {
        var cell = campSheet[el];
        if (cell) {
          cell.s = {
            alignment: {
              wrapText: 1,
              horizontal: 'left',
              vertical: 'center'
            }
          };
        }
      }
    });
  });
  if (bCampaignSheet) {
    [campLinkHeaderCol].forEach((col) => {
      getColumn(col, campSheet).forEach((el) => {
        if (el !== campLinkHeaderStart) {
          var cell = campSheet[el];
          if (cell) {
            cell.l = { Target: cell.v, Tooltip: cell.v };
            cell.s = {
              alignment: {
                wrapText: 1,
                horizontal: 'left',
                vertical: 'center'
              }
            };
          }
        }
      });
    });
  }
  var ref = campSheet['!ref'];
  campSheet['!ref'] = ref;
  wb.Sheets[baseSheet] = campSheet;
};

const prepareCampOutPut = (
  wb: XLSX.WorkBook,
  campJson,
  campExtraHeaders,
  bAddCampLink = false
) => {
  prepareExcelOutPut({
    wb,
    campJson,
    campExtraHeaders,
    baseSheet: 'Package_Campaigns',
    extraHeaderStart: 'N1',
    extraHeaderCol: 'N',
    HeaderEnd: 'M1',
    campLinkHeaderStart: 'O1',
    campLinkHeaderCol: 'O',
    //             a        b        c        d        e        f          g
    // h           i          j           k          l        m        n    o
    // prettier-ignore
    colWidthArr:[{wch:11},{wch:11},{wch:56},{wch:15},{wch:17.25},{wch:17.25},{wch:20},{wch:20},{wch:14},{wch:14},{wch:15},{wch:18},{wch:14},{wch:50},{wch:50}],
    bCampaignSheet: true,
    bAddCampLink
  });
};

const prepareCloneCampOutPut = (
  wb: XLSX.WorkBook,
  campJson,
  campExtraHeaders,
  bAddCampLink = false
) => {
  prepareExcelOutPut({
    wb,
    campJson,
    campExtraHeaders,
    baseSheet: 'Campaign_Cloning',
    extraHeaderStart: 'N1',
    extraHeaderCol: 'N',
    HeaderEnd: 'M1',
    campLinkHeaderStart: 'O1',
    campLinkHeaderCol: 'O',
    //             a        b          c           d        e          f         g         h         i         j       k        l        m       n        o
    // prettier-ignore
    colWidthArr:[{wch:8.5},{wch:8.5},{wch:31},{wch:8.5},{wch:31},{wch:17.25},{wch:17.25},{wch:13},{wch:13},{wch:13},{wch:13},{wch:45},{wch:9},{wch:30},{wch:30}],
    bCampaignSheet: true,
    bAddCampLink
  });
};

// prettier-ignore
const checkPackages = (excelData: Array<any>, iCampData,campNamesIdx, pkgData, advIds) => {
  var {pkgSheetData} = splitExcel(excelData),
    kLen = Object.keys(iCampData).length;
  if (kLen > 0 && pkgSheetData) {
    var pkgJson: ICampaign[] = pkgSheetData.sheetData;
    var extraHeaders = pkgSheetData.extraHeaders;
    // prettier-ignore
    var { pkgError, pkgVerifyErrors, pkgUpdate, pkgCreate } = determinePackageOperations(pkgJson, pkgData, iCampData, campNamesIdx, advIds );
    if (pkgError) {
      pkgVerifyErrors.forEach((err, idx) => {
        if (err) {
          pkgJson[idx]['Error Report'] = err;
          // console.log(campJson[idx]);
        }
      });
    }
    return {pkgJson, pkgError, pkgVerifyErrors, pkgUpdate, pkgCreate, pkgExtraHeaders: extraHeaders, campNamesIdx }
  }else {
    return {pkgJson : [], pkgError : false, pkgExtraHeaders:[], pkgVerifyErrors:'', pkgUpdate:{}, pkgCreate:{}}
  }
}

const preparePkgOutPut = (wb: XLSX.WorkBook, campJson, campExtraHeaders) => {
  prepareExcelOutPut({
    wb,
    campJson,
    campExtraHeaders,
    baseSheet: 'Packages',
    extraHeaderStart: 'F1',
    extraHeaderCol: 'F',
    HeaderEnd: 'E1',
    campLinkHeaderStart: '',
    campLinkHeaderCol: '',
    // prettier-ignore
    colWidthArr:[{wch:11},{wch:9.5},{wch:46},{wch:12.5},{wch:72},{wch:50},{wch:50},{wch:50}],
    bCampaignSheet: false,
    bAddCampLink: false
  });
};

// prettier-ignore
export const provideErrorFeedback = (wb, campJson, pkgJson, clCampJson, campExtraHeaders, pkgExtraHeaders, clCampExtraHeaders,bCampLink = true) => {
  if (wb) {
      prepareCampOutPut(wb, campJson, campExtraHeaders, bCampLink);
      preparePkgOutPut(wb, pkgJson, pkgExtraHeaders);
      prepareCloneCampOutPut(wb, clCampJson, clCampExtraHeaders, bCampLink)

    XLSX.writeFile(wb, 'processed_template.xlsx', {
      bookType: 'xlsx',
      type: 'file',
      cellDates: false
    });
  }
}

const indexPkgResponse = (
  respData: AxiosResponse<any> | false | undefined,
  baseIdx = {}
) => {
  var pkIdx = baseIdx;
  if (respData) {
    var data = respData.data;
    if (data) {
      var res = data.result;
      if (res) {
        pkIdx = res.reduce((acc, pk) => {
          //@ts-ignore
          var camps = pk.members.reduce((acc, m) => {
            //@ts-ignore
            acc[m.campaign_id] = m.campaign_name;
            acc[m.campaign_name] = m.campaign_id;
            return acc;
          }, {});
          //@ts-ignore
          acc[pk.package_id] = camps;
          //@ts-ignore
          acc[pk.package_name] = camps;
          return acc;
        }, pkIdx);
      }
    }
  }
  return pkIdx;
};

const determinePackageResults = (
  pkCrRes: AxiosResponse<any> | false | undefined,
  pkUpRes: AxiosResponse<any> | false | undefined,
  pkgJson: IPackage[]
) => {
  var lookupIdx = indexPkgResponse(pkCrRes, {});
  lookupIdx = indexPkgResponse(pkUpRes, lookupIdx);
  // console.log(JSON.stringify(lookupIdx));
  pkgJson.forEach((pkg, idx) => {
    var pId = pkg.PackageId,
      pN = pkg.PackageName,
      cId = pkg.CampaignId,
      cN = pkg.CampaignName;
    // @ts-ignore
    const getCompEl = (eId, eN, pKey = '') => {
      var eIdx = eId,
        eEl;
      if (!eIdx && eN) {
        eIdx = eN.trim();
      }
      if (!pKey) {
        //@ts-ignore
        eEl = lookupIdx[eIdx];
      } else {
        // @ts-ignore
        eEl = pKey[eIdx];
      }
      return eEl;
    };

    var pEl = getCompEl(pId, pN),
      cEl;
    if (pEl) {
      cEl = getCompEl(cId, cN, pEl);
    }
    if (cEl) {
      if (!pId) {
        pkg['Error Report'] = 'Package created successfully';
      } else {
        pkg['Error Report'] = 'Package updated successfully';
      }
    } else {
      if (!pId) {
        pkg['Error Report'] = 'Error creating Package';
      } else {
        pkg['Error Report'] = 'Error updating Package';
      }
    }
    pkgJson[idx] = pkg;
  });
};

/**
 * processes the cloning Results and prepares registration of the new campaigns
 * @param clCampRes - clone result data
 * @param clJsonArr - clone JSON array
 * @param clCampJSON - array of campaigns to clone
 * @returns
 */
export const determineCloneResults = (
  clCampRes: IClonePollingData[] | undefined,
  clJsonArr: Array<any>,
  clCampJson: ICampaign[]
) => {
  // var lookupIdx = indexPkgResponse(pkCrRes, {});
  // lookupIdx = indexPkgResponse(pkUpRes, lookupIdx);
  // console.log(JSON.stringify(lookupIdx));
  if (clCampRes) {
    var regCamp: ITtdCampaignObject = {},
      arrCamp: IBaseCamp[] = [],
      sCamp: Object = {};
    clCampRes.forEach((camp) => {
      // runIdx reflects the position in clCmpJson
      // rowIdx is the last row in clJson if we have more than 1 campaign flight
      var {
        Status,
        // FailureMessage,
        CampaignId,
        runIdx,
        rowIdx,
        warning,
        adgroupMessages
      } = camp;
      rowIdx = rowIdx || 0;
      runIdx = runIdx || 0;
      var jsArrEl = clJsonArr[runIdx];
      var rows =
          (jsArrEl.CampaignFlights && jsArrEl.CampaignFlights.length) || 0,
        bSuccess = false,
        report;
      if (Status === 'Completed') {
        if (warning) {
          report = warning;
        } else {
          report = 'Campaign was cloned successfully;';
        }
        if (adgroupMessages && adgroupMessages.length > 0) {
          report += '\n' + adgroupMessages.join(';');
        }
        bSuccess = true;
      } else {
        if (CampaignId === null || !CampaignId) {
          report = 'Campaign cloning timed out, please try again';
        }
      }
      var adv;
      for (let i = rowIdx; i > rowIdx - rows; i--) {
        var jsonElement = clCampJson[i];
        jsonElement['Error Report'] = report;
        if (bSuccess) {
          // generate campaign link
          adv =
            jsonElement['TargetAdvertiserId'] ||
            jsonElement['BaseAdvertiserId'];
          // generate Campaign Link
          jsonElement['Campaign Link'] = generateCampaignLink({
            campaign_id: CampaignId as string,
            advertiser_id: adv
          });
        }
      }
      if (bSuccess) {
        // var bCamp = clJsonArr[runIdx];
        var { CampaignFlights, CampaignName } = jsArrEl;
        CampaignId = CampaignId || 'x';
        // set fake campaign flight id
        CampaignFlights = CampaignFlights.map((cf, idx) => {
          return { ...cf, CampaignFlightId: CampaignId + '_' + idx };
        });
        var bsCamp = {
          CampaignId,
          CampaignName
        };
        arrCamp.push(bsCamp);
        // prepare registration of campaign + flights
        var rCamp = {
          ...bsCamp,
          CampaignFlights,
          AdvertiserId: adv,
          PacingMode: 'PaceAhead',
          TimeZone: 'UTC'
        };
        // @ts-ignore
        regCamp[CampaignId] = rCamp;
        sCamp[CampaignId] = 'c';
      }
    });
    var { pkCreateCamp } = generatePkgCampaignData(sCamp, regCamp);
    var ccCampIdx = campNameIndex(arrCamp);
    // console.log(JSON.stringify(pkCreateCamp));
    // console.log(JSON.stringify(ccCampIdx));
    // console.log(JSON.stringify(clCampJson));
    return { clCampJson, clPkCreateCamp: pkCreateCamp, ccCampIdx };
  }
  return { clCampJson, clPkCreateCamp: [], ccCampIdx: {} };
};

export const getClCampArray = (
  clCampObj: ITtdCloneCampaignObject | undefined
) => {
  if (clCampObj) {
    var campKeys = Object.keys(clCampObj);
    var clJson = campKeys.map((k, idx) => {
      var mapArr = [
        'CampaignFlights',
        'BaseCampaignId',
        'TargetCampaignName',
        'agIds',
        'rowIdx',
        'UpdateAdGroupBudgets'
      ];
      var camp: ITtdCloneCampaign = clCampObj[k];
      if (camp.TargetAdvertiserId) {
        mapArr.push('TargetAdvertiserId');
      }
      var agClone = camp.AdGroupsToClone || '',
        bAgCloning = !/^all$|^$/i.test(agClone),
        baseObj = { runIdx: idx };
      if (bAgCloning) {
        // mapArr.push('agNames', 'agIds');
        var agKeys = Object.keys(camp.agIds || {});
        // @ts-ignore
        baseObj.AdGroupIds = agKeys;
      }
      var mJson = Object.assign(
        baseObj,
        ...mapArr.map((el) => {
          if (el === 'BaseCampaignId') {
            return { CampaignId: camp[el] };
          }
          if (el === 'TargetCampaignName') {
            return { CampaignName: camp[el] };
          }
          return { [el]: camp[el] };
        })
      );
      return mJson;
    });
    // console.log(JSON.stringify(clJson));
    return clJson;
  }
  return [];
};

/*
        //   cObj['CampaignConversionReportingColumns'] = [{
        //     "TrackingTagId": "gssalf7",
        //     "TrackingTagName": "KBA Bucket Conversion Pixel",
        //     "ReportingColumnId": 1,
        //     "CrossDeviceAttributionModelId": "IdentityAllianceWithHousehold",
        //     "IncludeInCustomCPA": false,
        //     "Weight": 1.0000
        // }];
*/

// const getCampArray = (campObj: ITtdCampaignObject | undefined) => {
//   if (campObj) {
//     var cpUpdate:string[] = Object.keys(campObj); // get list of camp ids, as campObj is indexed by campaign id // @ts-ignore

//     return cpUpdate.map((el) => {
//       var cObj = campObj[el];

//       // Kokai testing
//       if (cObj.Version && cObj.Version.toLowerCase() === 'kokai') {
//         // return gql ready, separate array
//       }
//       // normal handling
//       if (!cObj['TimeZone']) {
//         cObj['TimeZone'] = 'America/New_York';
//       }
//       if (!cObj['CampaignConversionReportingColumns']) {
//         cObj['CampaignConversionReportingColumns'] = [];
//       }
//       // TODO: test for conversion sublevel objcts to ignore
//       if (cObj['AutoAllocatorEnabled'] === undefined) {
//         cObj['AutoAllocatorEnabled'] = true;
//       }
//       // if (cObj['AutoPrioritizationEnabled'] === undefined) {
//       //   cObj['AutoPrioritizationEnabled'] = true;
//       // }
//       return cObj;
//     });
//   }
//   return [];
// };

const translatePacingToGql = (mode: string): string => {
  var pm;
  switch (mode.toLowerCase()) {
    case 'off':
      pm = 'PACE_TO_DAILY_CAP';
      break;
    case 'paceahead':
      pm = 'PACE_AHEAD';
      break;
    default:
      pm = 'PACE_EVENLY';
      break;
  }
  return pm;
};

const getCampArray = (
  campObj: ITtdCampaignObject | undefined
): { gqlPayload: any[]; restPayload: any[] } => {
  if (!campObj) {
    return { gqlPayload: [], restPayload: [] };
  }
  const cpUpdate = Object.keys(campObj); // get list of campaign ids

  return cpUpdate.reduce(
    (acc, el) => {
      const cObj = { ...campObj[el] }; // Clone each campaign object to avoid side effects

      // only process updates here and skip creations
      if (cObj.op && cObj.op === 'c') {
        // no op
        campObj[el]['op'] = undefined;
        return acc;
      }

      // verify we hav real changes to apply for updates

      if (!(cObj.nameUpdate || cObj.pacingUpdate || cObj.tzUpdate)) {
        // campObj[el]['op'] = undefined;
        return acc; // no changes, skip this campaign object
      }
      // Separate GraphQL and REST based on the 'Version' field

      if (cObj.Version && cObj.Version.toLowerCase() === 'kokai') {
        // properties needed for GraphQL payload are CampaignId, CampaignName, and TimeZone, PacingMode
        acc.gqlPayload.push({
          CampaignId: el,
          CampaignName: cObj.CampaignName,
          TimeZoneId: cObj.TimeZone || 'America/New_York',
          PacingMode: translatePacingToGql(cObj.PacingMode)
        });
      } else {
        // Apply default handling for REST payload only
        if (!cObj.TimeZone) {
          cObj.TimeZone = 'America/New_York';
        }
        if (!cObj.CampaignConversionReportingColumns) {
          cObj.CampaignConversionReportingColumns = [];
        }
        if (cObj.AutoAllocatorEnabled === undefined) {
          cObj.AutoAllocatorEnabled = true;
        }

        acc.restPayload.push(cObj); // Add to REST payload
      }

      return acc;
    },
    {
      gqlPayload: [] as any[],
      restPayload: [] as any[]
    }
  );
};

/**
 * generates the JSON Arrays for POST / PUT pkg/campaign
 * @param successCamp - Object containing campaign ids
 *                      that  were updated / created
 * @param cReg - campaign registry of all campaigns
 * @returns pkCreateCamp - Post array , pkUpdateCamp - Put array
 */

export const generatePkgCampaignData = (
  successCamp: Object,
  cReg: ITtdCampaignObject
) => {
  var cKeys = Object.keys(successCamp);
  var pkUpdateCamp: Array<any> = [],
    pkCreateCamp: Array<any> = [],
    campKeys = [
      'AdvertiserId',
      'CampaignId',
      'CampaignName',
      'TimeZone',
      'PacingMode'
    ],
    flightKeys = [
      'CampaignFlightId',
      'BudgetInAdvertiserCurrency',
      'StartDateInclusiveUTC',
      'EndDateExclusiveUTC'
    ];
  cKeys.forEach((campId) => {
    var op = successCamp[campId];
    var camp = cReg[campId];
    if (camp) {
      // non flight keys for pCamp
      var pCamp = campKeys.reduce((acc, k) => {
        acc[k] = camp[k] || '';
        return acc;
      }, {});
      // flight keys for each flight
      pCamp['CampaignFlights'] = camp.CampaignFlights.map((fl) => {
        return flightKeys.reduce((acc, fk) => {
          var val = fl[fk];
          if (fk.includes('Date')) {
            val = val.replace('.000', '');
          }
          acc[fk] = val;
          return acc;
        }, {});
      });
      if (op === 'c') {
        pkCreateCamp.push(pCamp);
      } else {
        pkUpdateCamp.push(pCamp);
      }
    }
  });
  return { pkCreateCamp, pkUpdateCamp };
};

/**
 * updates campaign index with new flight details
 * @param camp - campaign data for update
 * @param cReg - registry of campaigns
 */
const updateCampaignIndex = (camp: ITtdCampaign, cReg: ITtdCampaignObject) => {
  var campId = camp.CampaignId;
  var ttdCamp: ITtdCampaign;
  if (campId) {
    ttdCamp = cReg[campId];
    if (ttdCamp) {
      // for existing campaign we just update name + pacing
      ttdCamp.CampaignName = camp.CampaignName;
      ttdCamp.PacingMode = camp.PacingMode;
    } else {
      // new campaign -> register with id
      cReg[camp.CampaignId] = camp;
    }
  }
};

/**
 * updates campaign index with new flight details
 * @param cf - flight data for update
 * @param cReg - registry of campaigns
 */
const updateCampaignIndexWithFlight = (
  cf: CampaignFlight,
  cReg: ITtdCampaignObject
) => {
  var campId = cf.CampaignId;
  var ttdCamp: ITtdCampaign;
  if (campId) {
    ttdCamp = cReg[campId];
    if (ttdCamp) {
      var tCampFlights = ttdCamp.CampaignFlights;
      var curFlight = cf.CampaignFlightId as number;
      var resIdx = tCampFlights.findIndex(
        (fl: CampaignFlight) => fl.CampaignFlightId === curFlight
      );
      if (resIdx !== -1) {
        // flight index found -> update the flight
        tCampFlights[resIdx] = cf;
      } else {
        // no current flight with matching flight Id found
        tCampFlights.push(cf);
      }
    }
  }
};

/**
 * updates campaign registry includes  error / success messages in campJson
 * @param baseArr
 * @param resObj
 * @param campJson
 * @param iCampData
 * @param op
 * @returns
 */
const mapApiResults = (
  baseArr,
  resObj,
  campJson,
  iCampData,
  successCamp,
  op
) => {
  var rData = resObj.data,
    rMsg = resObj.messages,
    campOp = 'u';
  if (rData.length > 0) {
    baseArr.forEach((bEl, idx) => {
      var rowIdx = bEl['rowIdx'];
      var dEl = rData[idx];
      var mEl = rMsg[idx],
        msg;
      if (rowIdx !== undefined) {
        var jEl = campJson[rowIdx];
        var campLink = '';
        if (dEl) {
          // success
          switch (op) {
            case 'cc':
              msg = 'campaign created';
              updateCampaignIndex(dEl, iCampData);
              campOp = 'c';
              break;
            case 'cu':
              msg = 'campaign updated';
              updateCampaignIndex(dEl, iCampData);
              break;
            case 'fc':
              msg = 'campaign flight created';
              updateCampaignIndexWithFlight(dEl, iCampData);
              campOp = 'u';
              if (jEl && jEl['CampaignFlightId']) {
                // update flight id in response JSON
                jEl['CampaignFlightId'] = dEl.CampaignFlightId;
              }
              break;
            case 'fu':
              msg = 'campaign flight updated';
              updateCampaignIndexWithFlight(dEl, iCampData);
              break;
          }
          var campId = dEl['CampaignId'];
          if (campId) {
            if (!successCamp[campId]) {
              successCamp[campId] = campOp;
            }
            campLink = `https://desk.thetradedesk.com/Campaigns/Detail/${dEl.CampaignId}#rtb-ad-groups`;
          }
          msg += ' successfully';
        } else {
          msg = mEl;
        }
        if (jEl) {
          var jMsg = jEl['Error Report'];
          if (jMsg) {
            jMsg += '\x0d\n';
          } else {
            jMsg = '';
          }
          jEl['Error Report'] = jMsg + msg;
          if (campLink) {
            jEl['Campaign Link'] = campLink;
          }
          campJson[rowIdx] = jEl;
        }
      }
    });
  }
};

/**
 * creates promise for an API function if Testobj is not empty
 * @param testObj
 * @param progressFunc
 * @param apiFunc
 * @param fnArgs
 */
const pushApiPromiseOrFullProgress = (
  testObj: Array<any>,
  progressFunc: Function,
  promiseArr: Array<any>,
  apiFunc,
  ...fnArgs
) => {
  if (testObj && testObj.length > 0) {
    promiseArr.push(genValPromise(apiFunc, ...fnArgs, testObj, progressFunc));
    return promiseArr.length - 1;
  } else {
    // otherwise set progress to 100% for this part
    progressFunc(100);
    return -1;
  }
};

/**
 * creates generic promise for function if Testobj is not empty
 * @param testObj
 * @param progressFunc
 * @param apiFunc
 * @param fnArgs
 */
const pushPromiseOrFullProgress = (
  testObj: Array<any>,
  progressFunc: Function,
  promiseArr: Array<any>,
  func,
  ...fnArgs
) => {
  if (testObj && testObj.length > 0) {
    promiseArr.push(genValPromise(func, ...fnArgs));
    return promiseArr.length - 1;
  } else {
    // otherwise set progress to 100% for this part
    progressFunc(100);
    return -1;
  }
};

const getResIndex = (resArr: Array<any>, idx: number) => {
  if (idx !== -1) {
    return resArr[idx];
  }
  // prepare fake data for possibly empty resArrays
  // prettier-ignore
  return {data: [], messages: [], campData: [], agData: [],   advAgCamp: {} as IPrepareReadAdgroups, shouldLoadAg: false};
};

const pkgCampIdUpdate = (pkgBase: IIdxOpPackage, campNamesIdx) => {
  var pkgKeys = Object.keys(pkgBase);
  var mapped = pkgKeys.map((pkgKey) => {
    var pkg = pkgBase[pkgKey];
    var pNames = pkg.Campaigns;
    if (pNames) {
      var nameKeys = Object.keys(pNames);
      nameKeys.forEach((nKey) => {
        var campEntry = campNamesIdx[nKey];
        if (campEntry) {
          pkg.Members.push(campEntry.id);
        }
      });
    }
    // update in base as well
    return pkg;
  });
  return mapped;
};

/**
 * generate jobs for ad group budget updates of updated campaigns
 */
const generateCampAdgroupBudgetJobs = (agBudgetUpdateCamps, iCampData) => {
  var agJobs: Array<any> = [];
  // TODO: test for Kokai here?
  agBudgetUpdateCamps.forEach((campId) => {
    var camp: ITtdCampaign = iCampData[campId];
    if (
      camp &&
      camp.agIds &&
      camp.Version &&
      camp.Version.toLowerCase() === 'kokai'
    ) {
      agJobs.push({ ttdCampaignId: campId, AdGroupMap: camp.agIds });
    }
  });
  // console.log('agJobs: ' + JSON.stringify(agJobs));
  return agJobs;
};

const myTokenRefresher = () => {
  return getCookies('ttdToken');
};

export const doBulkUpdate = async (
  excelData: IExcelDataArray,
  progressCallback: Function
) => {
  // progState = progressCallback(0, 0.01, 'u');
  // kf_download('cf_excelData.json', JSON.stringify(excelData));
  if (excelData.length > 0) {
    // check for used advertiser ids
    var advCatIds = getUniqueAdvertiserCategoryIds(excelData);
    // var adv_Ids = getAllAdvertiserIds(advCatIds);
    // register token refresh Function for long jobs
    registerTokenRefresher(myTokenRefresher);
    // prettier-ignore
    var { pkgData, iCampData, campNamesIdx} = await preparePackageCampaignCloneData(advCatIds,excelData, progressCallback);
    progressCallback(1, 20);
    // prettier-ignore
    var {campJson, campError, flightCreate, flightUpdate, campCreate, campUpdate, agBudgetUpdateCamps, campExtraHeaders } = checkCampaigns(excelData, iCampData, campNamesIdx);
    // kf_download('flightCreate.json', JSON.stringify(flightCreate));
    // kf_download('flightUpdate.json', JSON.stringify(flightUpdate));
    // kf_download('campCreate.json', JSON.stringify(campCreate));
    // kf_download('campUpdate.json', JSON.stringify(campUpdate));

    progressCallback(1, 35);
    // prettier-ignore
    var {clCampJson, clCampError, clCampCreate, clCampExtraHeaders } = checkCloneCampaigns(excelData, iCampData, campNamesIdx);
    progressCallback(1, 45);
    // prettier-ignore
    var {pkgJson, pkgError, pkgUpdate, pkgCreate, pkgExtraHeaders } = checkPackages (excelData, iCampData,campNamesIdx, pkgData, advCatIds.AdvertiserId || []);
    progressCallback(1, 55);
    // prettier-ignore
    var wb = await readTemplate('./Package_Campaign_Cloning_Template.xlsx');
    progressCallback(1, 100);
    // kf_download('wb.json', JSON.stringify(wb));
    // toDo reenable
    if (campError || pkgError || clCampError) {
      // prettier-ignore
      return provideErrorFeedback(wb, campJson, pkgJson, clCampJson, campExtraHeaders, pkgExtraHeaders, clCampExtraHeaders, false);
    }
    // prepare combined progress reporting
    var { f1, f2, f3, f4, f5 } = part5Progress(progressCallback, 2);
    var ttdToken = getCookies('ttdToken');

    var campUpdateBase = getCampArray(campUpdate);
    // stop supporting creation without cloning
    var campCreateArr = [];
    // var campCreateBase = getCampArray(campCreate);
    // var campCreateArr = campCreateBase.restPayload;
    // var campCreateArrGql = campCreateBase.gqlPayload;

    var campUpdateArr = campUpdateBase.restPayload;
    // TODO: implement gql handling later
    var campUpdateArrGql = campUpdateBase.gqlPayload;

    // TODO: do job logging here
    var promiseArr: Promise<any>[] = [];
    // @ts-ignore
    // process.env.DEBUG = true;
    // prettier-ignore
    var fcIdx = pushApiPromiseOrFullProgress(flightCreate as Array<any>, f1, promiseArr,
      postCampaignFlights,ttdToken);
    // prettier-ignore
    var fuIdx = pushApiPromiseOrFullProgress(flightUpdate as Array<any>,f2,promiseArr, putCampaignFlights, ttdToken);
    // prettier-ignore
    var ccIdx =pushApiPromiseOrFullProgress(campCreateArr as Array<any>,f3,promiseArr,postCampaigns,ttdToken,);
    // prettier-ignore
    var cuIdx =pushApiPromiseOrFullProgress(campUpdateArr as Array<any>, f4, promiseArr,putCampaigns, ttdToken );
    // if we don't need to do any ad group updates, set full progress for those now; otherwise we need to wait, until campaign / flight updates are finished
    if (agBudgetUpdateCamps.length === 0) {
      f5(100);
    }

    var results = await Promise.all(promiseArr);
    // console.log(JSON.stringify(results));
    // prettier-ignore
    var fcResult = getResIndex(results,fcIdx), fuResult = getResIndex(results,fuIdx), ccResult = getResIndex(results,ccIdx), cuResult = getResIndex(results,cuIdx);

    var successCamp = {};
    // update campaign registry, include messages in campJson + maintain list of success campaigns;  campaign updates need to come first, because we aren't sure of the TTD API execution order
    // prettier-ignore
    mapApiResults(campUpdateArr,cuResult,campJson, iCampData,successCamp,'cu');
    // prettier-ignore
    mapApiResults(flightCreate, fcResult, campJson, iCampData, successCamp, 'fc');
    // prettier-ignore
    mapApiResults(flightUpdate, fuResult, campJson, iCampData, successCamp, 'fu');
    // prettier-ignore
    mapApiResults(campCreateArr,ccResult,campJson, iCampData,successCamp,'cc');
    // kf_download('fcResult.json', JSON.stringify(fcResult));
    // kf_download('flightUpdate.json', JSON.stringify(flightUpdate));

    // handling of ad group updates
    /*
    1) check if we need to update Ad group budgets at all and remember number of campaigns to update
    2) for all camps to update, get list of ad group ids from campaign index
    3) call ad group updates
  */

    if (agBudgetUpdateCamps.length > 0) {
      var agJobArr = generateCampAdgroupBudgetJobs(
        agBudgetUpdateCamps,
        iCampData
      );
      var agRes = await updateAdgroupBudgets(ttdToken, agJobArr, f5);
      f5(100);
      console.log('agRes: ' + JSON.stringify(agRes));
    }

    // update campaign data in DB via Package API

    // generate payload for pkg/Campaign create / update
    var { pkCreateCamp, pkUpdateCamp } = generatePkgCampaignData(
      successCamp,
      iCampData
    );
    // successfully created campaigns get sent to package api
    var pcCrRes, pcUpRes;
    if (pkCreateCamp.length > 0) {
      pcCrRes = await createNewCampaigns(pkCreateCamp);
      console.log(JSON.stringify(pcCrRes));
    }
    progressCallback(2, 95);
    if (pkUpdateCamp.length > 0) {
      pcUpRes = await updateCampaigns(pkUpdateCamp);
      console.log(JSON.stringify(pcUpRes));
    }
    // progressCallback(2, 90);
    progressCallback(2, 100);

    /* campaign Cloning needs to handle similar steps
      1) API cloning / polling
      2) Error Feedback
      3) Including new campaigns in Campaign index in preparation for using those in Package assignments
    */
    // prepare combined progress reporting
    var clJsonArr = getClCampArray(clCampCreate);
    // kf_download('clCampJson.json', JSON.stringify(clCampJson));
    // kf_download('clJsonArr.json', JSON.stringify(clJsonArr));
    // kf_download('clCampCreate.json', JSON.stringify(clCampCreate));
    var cloneProgress = clProgress(progressCallback, 3);
    var clCampRes = await cloneCampaigns(ttdToken, clJsonArr, cloneProgress);
    // kf_download('clCampRes.json', JSON.stringify(clCampRes));
    var cRes = determineCloneResults(clCampRes.data, clJsonArr, clCampJson);
    clCampJson = cRes.clCampJson;
    var clPkCreateCamp = cRes.clPkCreateCamp;
    var cclCampIdx = cRes.ccCampIdx;
    progressCallback(4, 20);
    // successfully cloned campaigns get sent to package api
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var pclCrRes;
    if (clPkCreateCamp.length > 0) {
      pclCrRes = await createNewCampaigns(clPkCreateCamp);
      // console.log(JSON.stringify(pclCrRes));
      // pcCrRes = Object.assign(pcCrRes,pclCrRes)
    }

    // needs to check duplicates between new campaign names + existing names later
    // new id for new Campaigns goes to name lookup for packages
    var ccCampIdx = campNameIndex(ccResult.data);
    var cuCampIdx = campNameIndex(cuResult.data);
    campNamesIdx = Object.assign(
      campNamesIdx,
      ccCampIdx,
      cuCampIdx,
      cclCampIdx
    );

    // // update names for package updates
    var pkgUpdateArr = pkgCampIdUpdate(
      pkgUpdate as IIdxOpPackage,
      campNamesIdx
    );
    var pkgUpArr: IPackage[] = pkgUpdateArr.map((el) => {
      var oPkg: IPackage = {};
      var keys = ['AdvertiserId', 'PackageId', 'PackageName', 'Members'];
      keys.forEach((k) => {
        oPkg[k] = el[k];
      });
      return oPkg;
    });
    var pkgCreateArr = pkgCampIdUpdate(
      pkgCreate as IIdxOpPackage,
      campNamesIdx
    );
    var pkgCrArr: IPackage[] = pkgCreateArr.map((el) => {
      var oPkg: IPackage = {};
      var keys = ['AdvertiserId', 'PackageName', 'Members'];
      keys.forEach((k) => {
        oPkg[k] = el[k];
      });
      return oPkg;
    });
    // after that package update / creation happens
    var pkgUpRes;
    if (pkgUpArr && pkgUpArr.length > 0) {
      pkgUpRes = await updatePackages(pkgUpArr);
    }
    progressCallback(4, 60);
    // progressCallback(2, 95);
    var pkgCrRes;
    if (pkgCrArr && pkgCrArr.length > 0) {
      pkgCrRes = await createNewPackages(pkgCrArr);
    }
    // progressCallback(2, 98);
    // console.log(JSON.stringify(pkgCrRes));
    // console.log(JSON.stringify(pkgUpRes));
    determinePackageResults(pkgCrRes, pkgUpRes, pkgJson);
    // console.log(JSON.stringify(pkgJson));
    progressCallback(4, 100);
    // prettier-ignore
    provideErrorFeedback(wb, campJson, pkgJson, clCampJson, campExtraHeaders, pkgExtraHeaders, clCampExtraHeaders,true);
    unregisterTokenRefresher();
  }
};

export const runBulkUpdate = (
  excelData: IExcelDataArray,
  setStart: Function,
  progressCallback: Function
) => {
  // set progress bar to bulk update mode
  setStart(true);
  progressCallback(0, 0.01, 'u');
  doBulkUpdate(excelData, progressCallback).then(() => {
    // reset progress step state
    setTimeout(() => {
      progressCallback(-1, 0);
    }, 500);
    setStart(false);
  });
};
