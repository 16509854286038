import { concatMap, delay, take } from 'rxjs/operators';
import { of, SubscriptionLike, Subject, Observable } from 'rxjs';
// import { Subject } from 'rxjs/internal/Subject';
import { apiLogin } from './apiLogin';
import {
  IMultiResultsData,
  loadMultiData$,
  ILoadMultiDataFunc,
  APIRequest
} from './LoadData';
import { readCampaign$ } from './readCampaign';
import { readAdvertiser$ } from './readAdvertiser';
import { readPartner$ } from './readPartner';
import {
  APICreateUpdate,
  createUpdateData$,
  ICreateUpdateDataFunc,
  ICreateUpdateResultData
} from './UpdateData';
// import { postPutBidList$ } from './postPutBidList';
import { PostPutCampaignFlight$ } from './postPutCampaignFlight';
import { postPutCampaign$ } from './postPutCampaign';
import { postPutAdditionalFees$ } from './postAdditionalFees';
import { postAdditionalFeesStop$ } from './postAdditionalFeesStop';
import { readPartnerContract$ } from './readPartnerContract';
import { readAdvAdgroup$ } from './readAdvAdgroup';
import { readCampAdgroup$ } from './readCampAdgroup';
import { readAdgroup$ } from './readAdgroup';
import { postPutAdGroup$ } from './postPutAdGroup';
import { postClonePolling$ } from './postClonePolling';
import { readZipCountry$ } from './readZipCountry';
import { postPutGeoSegment$ } from './postPutGeoSegment';
import { readCityRegion$ } from './readCityRegion';
import { handleCloneAgComplete } from './updateAdgroupBudgets';
import { IResultsData } from './requestTtdApi';
// import { postPutBidListBatch$ } from './postPutBidListBatch';
import { agBudgetUpdateRunner$ } from './updateAdgroupBudgets';
import { addKokaiCampaignData } from '../utils/gqlCampaignHandling';
import { IDeltaCampaignData } from './postDeltaCampaignAdvertiser';
import { part2Progress } from './advertiserLiveCampaigns';

export { advertiserLiveCampaigns } from './advertiserLiveCampaigns';
export {
  setUrlBase,
  registerTokenRefresher,
  unregisterTokenRefresher
} from './ttdajax';
export { responseFilter } from './responsefilter';
export { postPutCampaign$ } from './postPutCampaign';
export { PostPutCampaignFlight$ } from './postPutCampaignFlight';
export {
  getPredictedRequestMinutes,
  getRequestLimitPerMinute
} from './ttd_request_tracker';
// export { ICreateUpdateResultData } from './UpdateData';
// export { IGeoSegmentData, IGeoSegmentDataArray } from './postPutGeoSegment';

const enableDebug =
  process.env.REACT_APP_DEBUG &&
  process.env.REACT_APP_DEBUG.toLocaleLowerCase() !== 'false';

// const enableDebug = process.env.DEBUG && process.env.DEBUG.toLocaleLowerCase() !== 'false';

export interface ICreateUpdateApi {
  token: string;
  apiCreateUpdate: APICreateUpdate;
  JSONArr: Array<any>;
  method: 'POST' | 'PUT';
  updateProgress?: Function;
  forwardProgress?: Function;
  name?: string;
  disableResponseFilter?: boolean;
  measurePerformance?: boolean;
  measureName?: string;
  parallelRuns?: number;
}

export interface IMyAPIRequest {
  // eslint-disable-next-line no-unused-vars
  (props: any): Observable<IResultsData>;
}

export interface IGetApi {
  token: string;
  apiRequest: IMyAPIRequest;
  idArr: Array<any>;
  idName?: string;
  updateProgress?: Function;
  // forwardProgress?: Function;
  sortField?: string;
  limitOverride?: number;
  disableResponseFilter?: boolean;
  countOnly?: boolean;
  parallelRuns?: number;
}

export function reportProgress(callback: Function, bRead = true, sUsage = '') {
  // holds progress Queue as Subject
  var pQ = new Subject();
  // holds Subscription for unsubscribing if required
  var pQSub = {},
    usage;

  if (sUsage) {
    if (bRead) {
      usage = 'read';
    } else {
      usage = 'postPut';
    }
    if (sUsage === 'AdvertiserId') {
      usage += 'Campaigns';
    }
    if (sUsage === 'PartnerId') {
      usage += 'Advertisers';
    }
    if (sUsage === 'CampaignId') {
      usage += 'AdditionalFees';
    }
  }

  const finish = () => {
    (pQSub as SubscriptionLike).unsubscribe();
    pQ.complete();
  };

  // update progress bar
  const doProgress = () => {
    pQSub = pQ
      .pipe(
        concatMap((obj) => {
          // @ts-ignore
          if (obj.immediate) {
            // immediate flag will skip the delay
            return of(obj);
          }
          return of(obj).pipe(delay(250));
        })
      )
      .subscribe((obj) => {
        // @ts-ignore
        let { percent, noForward } = obj;
        if (!percent) {
          // console.log(
          //   'reportProgress - setting 1 as default' +
          //     (usage ? ' for ' + usage : '')
          // );
          percent = 1;
        } else {
          if (percent === -1) {
            // error
            console.log(
              'reportProgress - error -> finish' +
                (usage ? ' for ' + usage : '')
            );
            finish();
            return;
          }
          if (percent > 100) {
            percent = 100;
          }
          // console.log(
          //   'reportProgress - setting value ' +
          //     percent +
          //     (usage ? ' for ' + usage : '')
          // );
        }
        if (!noForward) {
          callback(percent);
        }
        if (percent === 100) {
          var myFinish = finish;
          setTimeout(() => {
            myFinish();
          }, 200);
        }
      });
  };

  // handle progressbar updates
  const setProgress = ({ percent, immediate = false, noForward = false }) => {
    // queue to delay output by 500ms
    if (!immediate) {
      pQ.next({ percent });
    } else {
      // flush unsubscribe + queue
      finish();
      // recreate queue
      pQ = new Subject();
      // and subscribe again
      doProgress();
      // immediate update
      pQ.next({ percent, immediate, noForward });
    }
  };

  if (typeof callback === 'function') {
    doProgress();
    return setProgress;
  }
  // function invalid -> don't provide Subject and finish
  return undefined;
}

/**
 * gets our API token or rejects with an error message
 * @param {*} email
 * @param {*} password
 * @returns TTD Api Token if credentials are fine
 *          throws an error with message otherwise
 */
export function getApiToken(email, password) {
  return new Promise((resolve, reject) => {
    // all error handling happens automatically in apiLogin
    // it will respond with token for a successful login or throw an error message
    apiLogin({ email, password })
      .pipe(take(1))
      .subscribe(
        (res) => {
          if (!res.message) {
            // we should have a token
            var token = (res.data && res.data.Token) || '';
            if (token) {
              resolve(token);
              return;
            }
          }
          // remove credentials in message
          // @ts-ignore
          var message = res.message.replace(/\{[^}]*\}\s+/, '');
          // console.error("TTD API Login error: " + message);
          reject(new Error('TTD API Login error: ' + message));
        },
        (error) => {
          console.error(error.message);
          reject(error);
        }
      );
  });
}

/**
 * Runs bulk Update or Creation for the provided API function and returns results
 * @param token - Authentication token
 * @param apiFunction$ - Observable Api function to subscribe to
 * @param JSONArr - JSON Payload
 * @param method - POST or PUT
 * @param updateProgress - function for progress updates
 * @param name - name to use in progress updates
 * @param disableResponseFilter - true for deactivating response filtering
 * @param postPipeFunction$ - function for postprocessing with further API request
 * @returns {
 *  data: array of API response objects for success,
 *  count: number of API responses,
 *  messages: Array of error messages,
 *  rateLimit: (optional) rate limit, in case the rate limit was hit and resulted in a 429 response with the limit;
 * }
 */
export const createUpdateApi = async (
  props: ICreateUpdateApi
): Promise<ICreateUpdateResultData> => {
  return new Promise((resolve, reject) => {
    // prettier-ignore
    var {token, apiCreateUpdate, JSONArr, method, updateProgress, forwardProgress, name, disableResponseFilter = false, measurePerformance = false,measureName = '', parallelRuns = undefined}  = props;
    if (token !== '') {
      var obj: ICreateUpdateDataFunc = {
        ttdAuthToken: token,
        apiCreateUpdate,
        JSONArr,
        method,
        disableResponseFilter,
        measurePerformance,
        measureName,
        parallelRuns,
        forwardProgress
      };
      if (!Array.isArray(JSONArr)) {
        obj.JSONArr = [JSONArr];
      } else {
        if (JSONArr.length === 0) {
          resolve({
            data: [],
            count: 0,
            messages: ['createUpdateApi:  empty JSONArr']
          });
        }
      }
      if (updateProgress && typeof updateProgress === 'function') {
        obj.progress = reportProgress(updateProgress, false, name);
      }
      createUpdateData$(obj).subscribe(
        (p) => {
          // console.log(JSON.stringify(p));
          if (p.count && p.count > 0) {
            if (p.data && p.data.length > 0) {
              if (enableDebug) console.log(JSON.stringify(p.data));
            }
          }
          resolve((p as unknown) as ICreateUpdateResultData);
        },
        // an error will be thrown when a login request fails
        (err) => {
          console.error(
            err.message +
              (err.response && err.response.Message
                ? ': ' + err.response.Message
                : '')
          );
          reject(err);
        }
      );
    } else {
      resolve({
        data: [],
        count: 0,
        messages: ['createUpdateApi error:  missing API Token']
      });
    }
  });
};

/**
 * Gets Data for the provided (read) API function
 * @param token - authToken
 * @param loadFunction$ - Observable Api function to subscribe to
 * @param idArr  - String or Array of Ids
 * @param idName - name of the Id e.g. AdvertiserId / CampaignId
 * @param sortField - optional sort Field
 * @param limitOverride - limit to use instead of default limit (or 0 to keep default)
 * @param disableResponseFilter - true for turning off responseFiltering (default false)
 * @param countOnly - true counting only and ignoring the rest of the response
 * @returns {
 *  data: array of objects with successful API responses
 *  count: number of responses
 *  messages: array of error messages in case of API errors
 * }
 */
export const getApiData = async (
  props: IGetApi
): Promise<IMultiResultsData> => {
  return new Promise((resolve, reject) => {
    // prettier-ignore
    var {token, apiRequest, idArr, idName, updateProgress,sortField, limitOverride, disableResponseFilter, parallelRuns, countOnly} = props;

    if (token !== '') {
      var pArr = idArr;
      var pLen = 0;
      if (!Array.isArray(idArr)) {
        pArr = [idArr];
        if (idArr) {
          pLen = 1;
        }
      } else {
        pLen = pArr.length;
      }
      if (pLen === 0) {
        resolve({
          data: [],
          messages: ['error - empty Id Array or undefined Id'],
          count: 0,
          controlCount: 0
        });
      }
      limitOverride = limitOverride || 0;
      disableResponseFilter = disableResponseFilter || false;
      countOnly = countOnly || false;
      var obj: ILoadMultiDataFunc = {
        ttdAuthToken: token,
        apiRequest: (apiRequest as unknown) as APIRequest,
        BaseIds: pArr,
        BaseIdName: idName || '',
        limitOverride,
        disableResponseFilter,
        parallelRuns,
        countOnly
      };
      if (idName) {
        obj[idName] = pArr;
      }
      if (sortField) {
        obj.sortField = sortField;
      }
      if (updateProgress && typeof updateProgress === 'function') {
        obj.progress = reportProgress(updateProgress, true, idName);
      }
      loadMultiData$(obj).subscribe(
        (p) => {
          // console.log(JSON.stringify(p));
          if (p.count && p.count > 0) {
            if (p.data && p.data.length > 0) {
              if (enableDebug) console.log(JSON.stringify(p.data));
            }
          }
          resolve(p as IMultiResultsData);
        },
        // an error will be thrown when a login request fails
        (err) => {
          console.error(
            err.message +
              (err.response && err.response.Message
                ? ': ' + err.response.Message
                : '')
          );
          reject(err);
        }
      );
    } else {
      resolve({
        data: [],
        messages: ['error - missing token'],
        count: 0,
        controlCount: 0
      });
    }
  });
};

// export const getApiData = async (
//   token,
//   loadFunction$,
//   idArr,
//   idName,
//   updateProgress?: Function | undefined,
//   sortField = '',
//   limitOverride = 0,
//   disableResponseFilter = false,
//   countOnly = false
// ): Promise<IMultiResultsData> => {
//   return new Promise((resolve, reject) => {
//     if (token !== '') {
//       var pArr = idArr;
//       var pLen = 0;
//       if (!Array.isArray(idArr)) {
//         pArr = [idArr];
//         if (idArr) {
//           pLen = 1;
//         }
//       } else {
//         pLen = pArr.length;
//       }
//       if (pLen === 0) {
//         resolve({
//           data: [],
//           messages: ['error - empty Id Array or undefined Id'],
//           count: 0,
//           controlCount: 0
//         });
//       }
//       var obj: ILoadMultiDataFunc = {
//         ttdAuthToken: token,
//         apiRequest: loadFunction$,
//         BaseIds: pArr,
//         BaseIdName: idName,
//         limitOverride,
//         disableResponseFilter,
//         countOnly
//       };
//       if (idName) {
//         obj[idName] = pArr;
//       }
//       if (sortField) {
//         obj.sortField = sortField;
//       }
//       if (updateProgress && typeof updateProgress === 'function') {
//         obj.progress = reportProgress(updateProgress, true, idName);
//       }
//       loadMultiData$(obj).subscribe(
//         (p) => {
//           // console.log(JSON.stringify(p));
//           if (p.count && p.count > 0) {
//             if (p.data && p.data.length > 0) {
//               if (enableDebug) console.log(JSON.stringify(p.data));
//             }
//           }
//           resolve(p as IMultiResultsData);
//         },
//         // an error will be thrown when a login request fails
//         (err) => {
//           console.error(
//             err.message +
//               (err.response && err.response.Message
//                 ? ': ' + err.response.Message
//                 : '')
//           );
//           reject(err);
//         }
//       );
//     } else {
//       resolve({
//         data: [],
//         messages: ['error - missing token'],
//         count: 0,
//         controlCount: 0
//       });
//     }
//   });
// };

/**
 * Get Partners from API
 * @param token - Authentication Token
 * @returns {
 *  data - array of {PartnerName, PartnerId} - for success
 *  messages - array of error messages in case of any errors
 *  count - number of API responses
 * }
 */
export const getPartners = async (token) => {
  return getApiData({ token, apiRequest: readPartner$, idArr: [1] });
};

/**
 * Get Advertisers for PartnerIds from API
 * @param token - Authentication Token
 * @param ttdPartnerIds - PartnerId (string) or Array of PartnerIds
 * @param updateProgress - optional function to handle progress updates
 * @param sortField - optional sort field
 * @returns {
 *  data array of {AdvertiserId, AdvertiserName, PartnerId} - for success
 *  messages - array of error messages in case of any errors
 *  count - number of API responses
 * }
 */
export const getAdvertisers = async (
  token,
  ttdPartnerIds,
  updateProgress: Function | undefined = undefined,
  sortField = ''
) => {
  // prettier-ignore
  return getApiData({token, apiRequest: readAdvertiser$, idArr: ttdPartnerIds, idName:'PartnerId', updateProgress, sortField, limitOverride:2000});
};

/**
 * Get Campaigns for AdvertiserIds from API
 * @param token - Authentication Token
 * @param ttdAdvertiserIds - AdvertiserId (string) or Array of AdvertiserIds
 * @param updateProgress - optional function to handle progress updates
 * @param sortField  - optional sort field
 * @returns {
 *  data array of { CampaignName, CampaignId, AdvertiserId, StartDate, EndDate, Budget,
 *                 DailyBudget, BudgetInImpressions, DailyBudgetInImpressions,
 *                 PacingMode, TimeZone, CampaignFlights, CreatedAtUTC, LastUpdatedAtUTC,
 *                 CampaignConversionReportingColumns } - for success
 *  messages - array of error messages in case of any errors
 *  count - number of API responses
 * }
 */
export const getCampaigns = async (
  token,
  ttdAdvertiserIds,
  updateProgress: Function | undefined = undefined,
  sortField = ''
) => {
  var campRes = {data:[] as any[], messages:[] as string []}
  // use dual part progress function now, 1 for REST, 1 for GQL
  const {f1, f2} = part2Progress(updateProgress)
  try {
    // prettier-ignore
    campRes = await getApiData({token, apiRequest: readCampaign$, idArr: ttdAdvertiserIds, idName:'AdvertiserId', updateProgress:f1, sortField, limitOverride:2000});
    if (
      campRes?.data && ((campRes.data as unknown) as IDeltaCampaignData) &&
      campRes.data.length > 0
    ) {
      return addKokaiCampaignData(campRes?.data || [], token, f2);
    }
  }
  catch (error) {
    const errorMessage = (error as Error).message || 'Unknown error';
    campRes.messages.push(errorMessage);
  }

};

/**
 * Get Adgroups for AdvertiserIds from API
 * @param token - Authentication Token
 * @param ttdAdvertiserids - AdvertiserId (string) or Array of AdvertiserId
 * @param updateProgress - optional function to handle progress updates
 * @param sortField - optional sort field
 * @returns data array of { 'AdGroupName', 'AdGroupId', 'CampaignId',
 *  'IsEnabled', 'RTBAttributes.BudgetSettings.Budget', 'RTBAttributes.BudgetSettings.DailyBudget',
 * 'RTBAttributes.BudgetSettings.AdGroupFlights'} - for success
 *  messages - array of error messages in case of any errors
 *  count - number of API responses
 * }
 */
export const getAdvertiserAdgroups = async (
  token,
  ttdAdvertiserIds,
  updateProgress: Function | undefined = undefined,
  sortField = '',
  parallelRuns = 5
) => {
  // prettier-ignore
  return getApiData({token, apiRequest: readAdvAdgroup$, idArr: ttdAdvertiserIds, idName:'AdvertiserId', updateProgress, sortField, limitOverride:1000, parallelRuns});
};

/**
 * Get Adgroups for AdvertiserIds from API
 * @param token - Authentication Token
 * @param ttdAdvertiserids - AdvertiserId (string) or Array of AdvertiserId
 * @returns { messages: [],
 * totalCounts {AdvertiserId1: [totalCount], AdvertiserId2: [totalCount]} }
 */
export const countAdvertiserAdgroups = async (token, ttdAdvertiserIds) => {
  // setting countOnly means we just retrun the totalCount from the request that's used to determine the number of ad groups we have
  // prettier-ignore
  var agResp = await getApiData({token, apiRequest: readAdvAdgroup$, idArr: ttdAdvertiserIds, idName:'AdvertiserId', limitOverride:1, countOnly:true});

  var { totalCounts, messages } = agResp;
  return { totalCounts, messages };
};

/**
 * Get Adgroups for CampaignIds from API
 * @param token - Authentication Token
 * @param ttdCampaignids - CampaignId (string) or Array of CampaignIds
 * @param updateProgress - optional function to handle progress updates
 * @param sortField - optional sort field
 * @param disableResponseFilter - optional - true to disable ResponseFiltering (default false)
 * @returns data array of { 'AdGroupName', 'AdGroupId', 'CampaignId',
 *  'IsEnabled', 'RTBAttributes.BudgetSettings.Budget', 'RTBAttributes.BudgetSettings.DailyBudget',
 * 'RTBAttributes.BudgetSettings.AdGroupFlights'} - for success if response is filtered
 *  messages - array of error messages in case of any errors
 *  count - number of API responses
 * }
 */
export const getCampaignAdgroups = async (
  token,
  ttdCampaignIds,
  updateProgress: Function | undefined = undefined,
  sortField = '',
  disableResponseFilter = false
) => {
  // prettier-ignore
  return getApiData({token, apiRequest: readCampAdgroup$, idArr: ttdCampaignIds, idName:'CampaignId', updateProgress, sortField, limitOverride:500, disableResponseFilter});
};

/**
 * Get Adgroups for AdGroupIds from API
 * @param token - Authentication Token
 * @param ttdAdGroupids - AdGroupId (string) or Array of AdGroupIds
 * @param updateProgress - optional function to handle progress updates
 * @param sortField - optional sort field
 * @param disableResponseFilter - optional - true to disable ResponseFiltering (default false)
 * @returns data array of { 'AdGroupName', 'AdGroupId', 'CampaignId',
 *  'IsEnabled', 'RTBAttributes.BudgetSettings.Budget', 'RTBAttributes.BudgetSettings.DailyBudget',
 * 'RTBAttributes.BudgetSettings.AdGroupFlights'} - for success if response is filtered
 *  messages - array of error messages in case of any errors
 *  count - number of API responses
 * }
 */
export const getAdgroups = async (
  token,
  ttdAdGroupIds,
  updateProgress: Function | undefined = undefined,
  sortField = '',
  disableResponseFilter = false
) => {
  // prettier-ignore
  return getApiData({token, apiRequest: readAdgroup$, idArr: ttdAdGroupIds, idName:'AdGroupId', updateProgress, sortField, limitOverride:200, disableResponseFilter});
};

/**
 * Get Contracts for PartnerIds from API
 * @param token - Authentication Token
 * @param ttdPartnerIds - PartnerId (string) or Array of PartnerIds
 * @param updateProgress - optional function to handle progress updates
 * @param sortField - optional sort field
 * @returns {data array of  'OwnerPartnerId', 'Name', 'EndDateUTC',
 *   'Deals.SupplyVendorDealId', 'ContractType', 'ApprovalState'} - for success
 *  messages - array of error messages in case of any errors
 *  count - number of API responses
 * }
 */
export const getContracts = async (
  token,
  ttdPartnerIds,
  updateProgress: Function | undefined = undefined,
  sortField = ''
) => {
  // prettier-ignore
  return getApiData({token, apiRequest: readPartnerContract$, idArr: ttdPartnerIds, idName:'PartnerId', updateProgress, sortField, limitOverride:5000});
};

/**
 * Get ZipCode Geoelements for Countries from API
 * @param token - Authentication Token
 * @param CountryCodes - CountryCode (string) or Array of CountryCodes
 * @param updateProgress - optional function to handle progress updates
 * @param sortField - optional sort field
 * @returns {data array of 'ZipCode', 'GeoElementId'
            } - for success
 *  messages - array of error messages in case of any errors
 *  count - number of API responses
 * }
 */
export const getZipCountry = async (
  token,
  CountryCodes,
  updateProgress: Function | undefined = undefined,
  sortField = ''
) => {
  // prettier-ignore
  return getApiData({token, apiRequest: readZipCountry$, idArr: CountryCodes, idName:'CountryCode', updateProgress, sortField, limitOverride:100});
};

/**
 * Get Cities for Regions from API
 * @param token - Authentication Token
 * @param CountryCodes - CountryCode (string) or Array of CountryCodes
 * @param updateProgress - optional function to handle progress updates
 * @param sortField - optional sort field
 * @returns {data array of 'ZipCode', 'GeoElementId'
            } - for success
 *  messages - array of error messages in case of any errors
 *  count - number of API responses
 * }
 */
export const getCityRegion = async (
  token,
  RegionCodes,
  updateProgress: Function | undefined = undefined,
  sortField = ''
) => {
  // prettier-ignore
  return getApiData({token, apiRequest: readCityRegion$, idArr: RegionCodes, idName:'RegionCode', updateProgress, sortField, limitOverride:100});
};

/**
 *
 * @param token - Authentication Token
 * @param campaignIds - campaignId (string) or Array of campaignIds
 * @param updateProgress - optional function to handle progress updates
 * @returns {
 *  data array of { StartDateUtc, Fees, OwnerId, OwnerType } - for success
 *                    OwnerId is a CampaignId and OwnerType = campaign
 *  messages - array of error messages in case of any errors
 *  count - number of API responses
 * }
 */
export const getAdditionalCampaignFees = async (
  token,
  campaignIds,
  updateProgress: Function | undefined = undefined
) => {
  // prettier-ignore
  return getApiData({token, apiRequest: readCityRegion$, idArr: campaignIds, idName:'CampaignId', updateProgress});
};

/** usage examples for CreateUpdateApi **/

// /**
//  * Runs putBidList based on array of JSON Payloads
//  * @param token - Authentication token
//  * @param arrJsonPayload - array of JSON payloads
//  * @param updateProgress - optional function to handle progress updates
//  * @returns {
//  *  data - Array of { 'BidListId', 'Name', 'BidListOwner', 'BidListOwnerId' } - for success ,
//  *  count - number of API responses
//  *  messages - Array of error messages in case of errors
//  * }
//  */
// export const putBidList = async (
//   token,
//   arrJsonPayload,
//   updateProgress: Function | undefined = undefined
// ) => {
//   var name = '';
//   if (updateProgress) {
//     name = 'putBidList';
//   }
//   // prettier-ignore
//   return createUpdateApi({ token, apiCreateUpdate: postPutBidList$, JSONArr: arrJsonPayload, method:'PUT',updateProgress, name});
// };

// /**
//  * Runs postBidList based on array of JSON Payloads
//  * @param token - Authentication token
//  * @param arrJsonPayload - array of JSON payloads
//  * @param updateProgress - optional function to handle progress updates
//  * @returns {
//  *  data - Array of { 'BidListId', 'Name', 'BidListOwner', 'BidListOwnerId' } - for success ,
//  *  count - number of API responses
//  *  messages - Array of error messages in case of errors
//  * }
//  */
// export const postBidList = async (
//   token,
//   arrJsonPayload,
//   updateProgress: Function | undefined = undefined
// ) => {
//   var name = '';
//   if (updateProgress) {
//     name = 'putBidList';
//   }
//   // prettier-ignore
//   return createUpdateApi({ token, apiCreateUpdate: postPutBidList$, JSONArr: arrJsonPayload, method:'POST',updateProgress, name});
// };

// /**
//  * Runs putBidListBatch based on array of JSON Payloads
//  * @param token - Authentication token
//  * @param arrJsonPayload - array of JSON payloads
//  * @returns {
//  *  data - Array of { 'BidListId', 'Name', 'BidListOwner', 'BidListOwnerId' } - for success ,
//  *  count - number of API responses
//  *  messages - Array of error messages in case of errors
//  * }
//  */
// export const putBidListBatch = async (token, arrJsonPayload) => {
//   return new Promise((resolve, reject) => {
//     if (token !== '') {
//       postPutBidListBatch$({
//         ttdAuthToken: token,
//         method: 'PUT',
//         sJSON: arrJsonPayload
//       }).subscribe(
//         (res) => {
//           resolve(res);
//         },
//         (error) => {
//           console.error(error.message);
//           reject(error);
//         }
//       );
//     } else {
//       reject(new Error('putBidListBatch error: missing token'));
//     }
//   });
// };

// /**
//  * Runs postBidListBatch based on array of JSON Payloads
//  * @param token - Authentication token
//  * @param arrJsonPayload - array of JSON payloads
//  * @returns {
//  *  data - Array of { 'BidListId', 'Name', 'BidListOwner', 'BidListOwnerId' } - for success ,
//  *  count - number of API responses
//  *  messages - Array of error messages in case of errors
//  * }
//  */
// export const postBidListBatch = async (token, arrJsonPayload) => {
//   return new Promise((resolve, reject) => {
//     if (token !== '') {
//       postPutBidListBatch$({
//         ttdAuthToken: token,
//         method: 'POST',
//         sJSON: arrJsonPayload
//       }).subscribe(
//         (res) => {
//           resolve(res);
//         },
//         (error) => {
//           console.error(error.message);
//           reject(error);
//         }
//       );
//     } else {
//       reject(new Error('postBidListBatch error: missing token'));
//     }
//   });
// };

/**
 * Runs putAdditionalFees based on array of JSON Payloads
 * @param token - Authentication token
 * @param arrJsonPayload - array of JSON payloads
 * @param updateProgress - optional function to handle progress updates
 * @returns {
 *  data - Array of { StartDateUtc, Fees, OwnerId, OwnerType } - for success ,
 *  count - number of API responses
 *  messages - Array of error messages in case of errors
 * }
 */
export const putAdditionalFees = async (
  token,
  arrJsonPayload,
  updateProgress: Function | undefined = undefined
) => {
  var name = '';
  if (updateProgress) {
    name = 'putAdditionalFees';
  }
  // prettier-ignore
  return createUpdateApi({ token, apiCreateUpdate: postPutAdditionalFees$, JSONArr: arrJsonPayload, method:'PUT',updateProgress, name});
};

/**
 * Runs postAdditionalFees based on array of JSON Payloads
 * @param token - Authentication token
 * @param arrJsonPayload - array of JSON payloads
 * @param updateProgress - optional function to handle progress updates
 * @returns {
 *  data - Array of { StartDateUtc, Fees, OwnerId, OwnerType } - for success ,
 *  count - number of API responses
 *  messages - Array of error messages in case of errors
 * }
 */
export const postAdditionalFees = async (
  token,
  arrJsonPayload,
  updateProgress: Function | undefined = undefined
) => {
  var name = '';
  if (updateProgress) {
    name = 'postAdditionalFees';
  }
  // prettier-ignore
  return createUpdateApi({ token, apiCreateUpdate: postPutAdditionalFees$, JSONArr: arrJsonPayload, method:'POST',updateProgress, name});
};

/**
 * Runs postAdditionalFeesStop based on array of JSON Payloads
 * @param token - Authentication token
 * @param arrJsonPayload - array of JSON payloads
 * @param updateProgress - optional function to handle progress updates
 * @returns {
 *  data - Array of { StartDateUtc, Fees, OwnerId, OwnerType } - for success ,
 *  count - number of API responses
 *  messages - Array of error messages in case of errors
 * }
 */
export const postAdditionalFeesStop = async (
  token,
  arrJsonPayload,
  updateProgress: Function | undefined = undefined
) => {
  var name = '';
  if (updateProgress) {
    name = 'postAdditionalFeesStop';
  }
  // prettier-ignore
  return createUpdateApi({ token, apiCreateUpdate: postAdditionalFeesStop$, JSONArr: arrJsonPayload, method:'POST',updateProgress, name});
};

/**
 * Runs PostCampaignFlight based on array of JSON Payloads
 * @param token - Authentication token
 * @param arrJsonPayload - array of JSON payloads
 * @param updateProgress - optional function to handle progress updates
 * @returns {
 *  data - Array of { CampaignId, CampaignFlightId, BudgetInAdvertiserCurrency,
 *                    BudgetInImpressions, DailyTargetInAdvertiserCurrency,
 *                    DailyTargetInImpressions, StartDateInclusiveUTC,
 *                    EndDateExclusiveUTC } - for success ,
 *  count - number of API responses
 *  messages - Array of error messages in case of errors
 * }
 */
export const postCampaignFlights = async (
  token,
  arrJsonPayload,
  updateProgress: Function | undefined = undefined,
  disableResponseFilter = false
) => {
  var name = '';
  if (updateProgress) {
    name = 'postCampaignFlights';
  }
  // prettier-ignore
  return createUpdateApi({ token, apiCreateUpdate: PostPutCampaignFlight$, JSONArr: arrJsonPayload, method:'POST',updateProgress, name, disableResponseFilter});
};

/**
 * Runs PutCampaignFlight based on array of JSON Payloads
 * @param token - Authentication token
 * @param arrJsonPayload - array of JSON payloads
 * @param updateProgress - optional function to handle progress updates
 * @returns {
 *  data - Array of { CampaignId, CampaignFlightId, BudgetInAdvertiserCurrency,
 *                    BudgetInImpressions, DailyTargetInAdvertiserCurrency,
 *                    DailyTargetInImpressions, StartDateInclusiveUTC,
 *                    EndDateExclusiveUTC } - for success ,
 *  count - number of API responses
 *  messages - Array of error messages in case of errors
 * }
 */
export const putCampaignFlights = async (
  token,
  arrJsonPayload,
  updateProgress: Function | undefined = undefined,
  disableResponseFilter = false
) => {
  var name = '';
  if (updateProgress) {
    name = 'postCampaignFlights';
  }
  // prettier-ignore
  return createUpdateApi({ token, apiCreateUpdate: PostPutCampaignFlight$, JSONArr: arrJsonPayload, method:'PUT',updateProgress, name, disableResponseFilter});
};

/**
 * Runs PutCampaign based on array of JSON Payloads
 * @param token - Authentication token
 * @param arrJsonPayload - array of JSON payloads
 * @param updateProgress - optional function to handle progress updates
 * @returns {
 *  data array of { CampaignName, CampaignId, AdvertiserId, StartDate, EndDate, Budget,
 *                 DailyBudget, BudgetInImpressions, DailyBudgetInImpressions,
 *                 PacingMode, TimeZone, CampaignFlights,
 *                 CampaignConversionReportingColumns } - for success
 *  messages - array of error messages in case of any errors
 *  count - number of API responses
 * }
 */
export const putCampaigns = async (
  token,
  arrJsonPayload,
  updateProgress: Function | undefined = undefined,
  disableResponseFilter = false
) => {
  var name = '';
  if (updateProgress) {
    name = 'putCampaigns';
  }
  // prettier-ignore
  return createUpdateApi({ token, apiCreateUpdate: postPutCampaign$, JSONArr: arrJsonPayload, method:'PUT',updateProgress, name, disableResponseFilter});
};

/**
 * Runs PostCampaign based on array of JSON Payloads
 * @param token - Authentication token
 * @param arrJsonPayload - array of JSON payloads
 * @param updateProgress - optional function to handle progress updates
 * @returns {
 *  data array of { CampaignName, CampaignId, AdvertiserId, StartDate, EndDate, Budget,
 *                 DailyBudget, BudgetInImpressions, DailyBudgetInImpressions,
 *                 PacingMode, TimeZone, CampaignFlights,
 *                 CampaignConversionReportingColumns } - for success
 *  messages - array of error messages in case of any errors
 *  count - number of API responses
 * }
 */
export const postCampaigns = async (
  token,
  arrJsonPayload,
  updateProgress: Function | undefined = undefined,
  disableResponseFilter = false
) => {
  var name = '';
  if (updateProgress) {
    name = 'postCampaigns';
  }
  // prettier-ignore
  return createUpdateApi({ token, apiCreateUpdate: postPutCampaign$, JSONArr: arrJsonPayload, method:'POST',updateProgress, name, disableResponseFilter});
};

/**
 * Runs PostAdGroup based on array of JSON Payloads
 * @param token - Authentication token
 * @param arrJsonPayload - array of JSON payloads
 * @param updateProgress - optional function to handle progress updates
 * @returns {
 *  data array of { CampaignId, AdGroupId, AdGroupName, IsEnabled,
 *  RTBAttributes.BudgetSettings.Budget, RTBAttributes.BudgetSettings.DailyBudget,
 *  RTBAttributes.BudgetSettings.AdGroupFlights } - for success
 *  messages - array of error messages in case of any errors
 *  count - number of API responses
 * }
 */
export const postAdGroups = async (
  token,
  arrJsonPayload,
  updateProgress: Function | undefined = undefined,
  disableResponseFilter = false
) => {
  var name = '';
  if (updateProgress) {
    name = 'postAdGroups';
  }
  // prettier-ignore
  return createUpdateApi({ token, apiCreateUpdate: postPutAdGroup$, JSONArr: arrJsonPayload, method:'POST',updateProgress, name, disableResponseFilter});
};

/**
 * Runs PutAdGroup based on array of JSON Payloads
 * @param token - Authentication token
 * @param arrJsonPayload - array of JSON payloads
 * @param updateProgress - optional function to handle progress updates
 * @returns {
 *  data array of { CampaignId, AdGroupId, AdGroupName, IsEnabled,
 *  RTBAttributes.BudgetSettings.Budget, RTBAttributes.BudgetSettings.DailyBudget,
 *  RTBAttributes.BudgetSettings.AdGroupFlights } - for success
 *  messages - array of error messages in case of any errors
 *  count - number of API responses
 * }
 */
export const putAdGroups = async (
  token,
  arrJsonPayload,
  updateProgress: Function | undefined = undefined,
  disableResponseFilter = false
) => {
  var name = '';
  if (updateProgress) {
    name = 'putAdGroups';
  }
  // prettier-ignore
  return createUpdateApi({ token, apiCreateUpdate: postPutAdGroup$, JSONArr: arrJsonPayload, method:'PUT',updateProgress, name, disableResponseFilter});
};

/**
 * Runs cloneCampaigns based on array of JSON Payloads
 * @param token - Authentication token
 * @param arrJsonPayload - array of JSON payloads
 * @param forwardProgress - optional function to handle progress updates
 * @returns {
 *  data array of { CampaignName, CampaignId, AdvertiserId, StartDate, EndDate, Budget,
 *                 DailyBudget, BudgetInImpressions, DailyBudgetInImpressions,
 *                 PacingMode, TimeZone, CampaignFlights,
 *                 CampaignConversionReportingColumns } - for success
 *  messages - array of error messages in case of any errors
 *  count - number of API responses
 * }
 */
export const cloneCampaigns = async (
  token,
  arrJsonPayload,
  forwardProgress: Function | undefined = undefined
) => {
  var name = '';
  if (forwardProgress) {
    name = 'cloneCampaigns';
  }
  var res = await createUpdateApi({
    token,
    // @ts-ignore
    apiCreateUpdate: postClonePolling$,
    JSONArr: arrJsonPayload,
    method: 'POST',
    forwardProgress,
    name,
    parallelRuns: 4
  });
  // handle ad group queue cleanup for potential clone issues
  handleCloneAgComplete();
  return res;
};

/**
 * Runs postGeoSegment based on array of JSON Payloads
 * @param token - Authentication token
 * @param arrJsonPayload - array of JSON payloads
 * @param updateProgress - optional function to handle progress updates
 * @returns {
 *  data array of  AdvertiserId, PartnerId, GeoSegmentId, GeoSegmentName, GeoElements } - for success
 *  messages - array of error messages in case of any errors
 *  count - number of API responses
 * }
 */
export const postGeoSegments = async (
  token,
  arrJsonPayload,
  updateProgress: Function | undefined = undefined
) => {
  var name = '';
  if (updateProgress) {
    name = 'postGeoSegments';
  }
  // prettier-ignore
  return createUpdateApi({ token, apiCreateUpdate: postPutGeoSegment$, JSONArr: arrJsonPayload, method:'POST',updateProgress, name});
};

/**
 * Runs putGeoSegment based on array of JSON Payloads
 * @param token - Authentication token
 * @param arrJsonPayload - array of JSON payloads
 * @param updateProgress - optional function to handle progress updates
 * @returns {
 *  data array of  AdvertiserId, PartnerId, GeoSegmentId, GeoSegmentName, GeoElements } - for success
 *  messages - array of error messages in case of any errors
 *  count - number of API responses
 * }
 */
export const putGeoSegments = async (
  token,
  arrJsonPayload,
  updateProgress: Function | undefined = undefined
) => {
  var name = '';
  if (updateProgress) {
    name = 'putGeoSegments';
  }
  // prettier-ignore
  return createUpdateApi({ token, apiCreateUpdate: postPutGeoSegment$, JSONArr: arrJsonPayload, method:'PUT',updateProgress, name});
};

/**
 *
 * @param token
 * @param arrJsonPayload - array [{ttdCampaignId, AdGroupMap}]
 * @returns
 */
export const updateAdgroupBudgets = async (
  token,
  arrJsonPayload,
  updateProgress: Function | undefined = undefined
) => {
  return new Promise((resolve, reject) => {
    if (token !== '') {
      if (!Array.isArray(arrJsonPayload) || arrJsonPayload.length === 0) {
        resolve({ data: [], messages: [] });
      }
      var totalRuns = arrJsonPayload.length;
      agBudgetUpdateRunner$({
        ttdAuthToken: token,
        jobs: arrJsonPayload,
        totalRuns,
        progressFunc: updateProgress
      }).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          console.error(error.message);
          reject(error);
        }
      );
    } else {
      reject(new Error('updateAdgroupBudgets error: missing token'));
    }
  });
};
