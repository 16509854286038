/* eslint-disable @typescript-eslint/no-unused-vars */
import * as XLSX from 'sheetjs-style';
import { sortJsonArr, getSortFunc, trimStr, getExcelDateCode } from './index';
import { filter_advertiser_ids } from '../vars';

const { SSF } = XLSX;

export interface CampaignFlight {
  CampaignId?: string;
  CampaignFlightId?: Int;
  StartDateInclusiveUTC: DateStr;
  EndDateExclusiveUTC?: DateStr;
  BudgetInAdvertiserCurrency: number;
  BudgetInImpressions?: Int;
  DailyTargetInAdvertiserCurrency?: number;
  DailyTargetInImpressions?: Int;
  op?: 'c' | 'u';
  rowIdx?: number;
  [key: string]: any;
}

export interface ICampAdgroups {
  AdGroupName: string;
  AdGroupId: string;
  CampaignId: string;
}

export interface ICampAdgroupIdx {
  [key: string]: string;
}
export interface ITtdCampaignFlightObject {
  [key: string]: CampaignFlight;
}
export interface ITtdCampaign {
  AdvertiserId: string;
  CampaignId: string;
  CampaignName: string;
  PacingMode: campPacing;
  CampaignFlights: CampaignFlight[];
  cfl?: ITtdCampaignFlightObject;
  UpdateAdGroupBudgets: boolean;
  op?: 'c' | 'u';
  nameUpdate?: boolean;
  rowIdx?: number;
  agIds?: ICampAdgroupIdx;
  agNames?: ICampAdgroupIdx;
  agDupl?: Array<string>;
  agCount?: number;
  budgetUpdateError?: boolean;
  TimeZone?: string;
  Version?: string;
  pacingUpdate?: boolean;
  tzUpdate?: boolean;
  CampaignConversionReportingColumns?: any[];
  AutoAllocatorEnabled?: boolean;
}

export interface ITtdCampaignObject {
  [key: string]: ITtdCampaign;
}

export interface IPrepareReadAdgroupsCamps {
  CampaignIds: Array<string>;
  CampaignNames: Array<string>;
  loadAdvAdgroups?: boolean;
  totalCount?: number;
}

export interface ICloneAdvIds {
  AdvertiserId?: string[];
  BaseAdvertiserId?: string[];
  TargetAdvertiserId?: string[];
}

export interface IPrepareReadAdgroups {
  [key: string]: IPrepareReadAdgroupsCamps;
}
export interface IPrepareReadAdgroupsArr extends Array<IPrepareReadAdgroups> {}

enum DateStrBrand {}
export type DateStr = string & DateStrBrand;
export type Int = number & { __int__: void };
export type YesNo = string & ('Yes' | 'No');
type fType = 'date' | 'number' | 'int' | 'pacing' | 'string';
type campPacing = 'PaceToEndOfFlight' | 'PaceAhead' | 'Off';

type errorEntry = string[];

export const ExcelConfig = {
  EXCEL_TYPE:
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',

  EXCEL_EXTENSION: '.xlsx',

  headerStyle: {
    fill: { fgColor: { rgb: 'D3D3D3' } },
    font: { name: 'Arial', sz: 10, bold: true },
    alignment: { wrapText: true, vertical: 'bottom', horizontal: 'center' }
  },

  dateStyle: {
    font: { name: 'Arial', sz: 10 },
    alignment: { wrapText: false, vertical: 'bottom', horizontal: 'center' }
  },

  currencyStyle: {
    font: { name: 'Arial', sz: 10 },
    alignment: { wrapText: false, vertical: 'bottom', horizontal: 'right' }
  },

  textStyle: {
    font: { name: 'Arial', sz: 10 },
    alignment: { wrapText: true, vertical: 'center', horizontal: 'center' }
  },

  generalStyle: {
    font: { name: 'Arial', sz: 10 },
    alignment: { wrapText: true, vertical: 'bottom', horizontal: 'left' }
  },

  dateFmt: 'mm/dd/yyyy',
  currencyFmt: '$#,##0.00;[Red]($#,##0.00)'
};

// const setHeaderStyles = (
//   ws: XLSX.WorkSheet,
//   range: any,
//   numOfHeaders: number
// ) => {
//   let i = 0;
//   for (let C = range.s.c; C <= range.e.c; ++C) {
//     i = 0;
//     while (i < numOfHeaders) {
//       //Set header styles
//       const header1 = utils.encode_cell({ c: C, r: i });
//       ws[header1].s = ExcelConfig.headerStyle;
//       i++;
//     }
//   }
//   //set general cell style
//   for (let R = range.s.r + i; R <= range.e.r; ++R) {
//     for (let C = range.s.c; C <= range.e.c; ++C) {
//       const all = utils.encode_cell({ c: C, r: R });
//       if (ws[all]) {
//         ws[all].s = ExcelConfig.generalStyle;
//       }
//     }
//   }
// };

interface IExcelData {
  sheetName: string;
  sheetData: Array<any>;
  extraHeaders?: Array<any>;
  date1904: boolean;
}

export interface IExcelDataArray extends Array<IExcelData> {}

export interface IPackage {
  AdvertiserId?: string;
  PackageName?: string;
  PackageId?: Int;
  CampaignId?: string;
  CampaignName?: string;
}

export interface ICampaign {
  AdvertiserId?: string;
  CampaignId?: string;
  CampaignName?: string;
  CampaignFlightId?: Int;
  BudgetInAdvertiserCurrency?: number;
  DailyTargetInAdvertiserCurrency?: number;
  BudgetInImpressions?: Int;
  DailyTargetInImpressions?: Int;
  StartDateInclusiveUTC?: number;
  EndDateExclusiveUTC?: number;
  StartDateInclusiveEST?: number;
  EndDateExclusiveEST?: number;
  PacingMode?: campPacing;
  UpdateAdGroupBudgets?: YesNo;
  TimeZone?: string;
  rowIdx?: number;
  'Expected error'?: string;
}

interface IIdxCamp {
  CampaignId: string;
}

export interface ICampNameReg {
  id: string;
  count: number;
}

export interface ICampNameRegIdx {
  [key: string]: ICampNameReg;
}

export interface ICloneCampaign {
  BaseAdvertiserId?: string;
  BaseCampaignId?: string;
  BaseCampaignName?: string;
  TargetAdvertiserId?: string;
  TargetCampaignName?: string;
  BudgetInAdvertiserCurrency?: number;
  DailyTargetInAdvertiserCurrency?: number;
  BudgetInImpressions?: Int;
  DailyTargetInImpressions?: Int;
  StartDateInclusiveUTC?: number;
  EndDateExclusiveUTC?: number;
  StartDateInclusiveEST?: number;
  EndDateExclusiveEST?: number;
  AdGroupsToClone?: string;
  ShouldCloneAdGroupBudgets?: YesNo;
  UpdateAdGroupBudgets?: YesNo;
  campIdUpdated?: Boolean;
  rowIdx?: number;
  Version?: string;
  'Expected error'?: string;
}

export interface ITtdCloneCampaign {
  BaseAdvertiserId?: string;
  BaseCampaignId?: string;
  BaseCampaignName?: string;
  TargetAdvertiserId?: string;
  TargetCampaignName?: string;
  AdGroupsToClone?: string;
  ShouldCloneAdGroupBudgets?: YesNo;
  UpdateAdGroupBudgets?: boolean;
  CampaignFlights: CampaignFlight[];
  cfl?: ITtdCampaignFlightObject;
  nameChange?: boolean;
  rowIdx?: number;
  campIdUpdated?: Boolean;
  agIds?: ICampAdgroupIdx;
  agNames?: ICampAdgroupIdx;
  agDupl?: Array<string>;
  agCount?: number;
  baseCampIdArr?: Array<string>;
  agCloneArr?: Array<string>;
  budgetUpdateError: boolean;
  Version?: string;
}

export interface ITtdCloneCampaignObject {
  [key: string]: ITtdCloneCampaign;
}

interface pCampObj {
  [key: string]: string;
}
export interface IIdxPackage {
  AdvertiserId: string;
  PackageId: Int;
  PackageName: string;
  Members: IIdxCamp[];
  Campaigns: pCampObj;
  [key: number]: any;
}
export interface IIdxPackageObject {
  [key: number]: IIdxPackage;
}

export interface IOpPackage extends IIdxPackage {
  op?: 'c' | 'u';
  nameUpdate?: boolean;
  rowIdx: number;
}

type INumString = number | string;

export interface IBaseCamp {
  CampaignName: string;
  CampaignId?: string;
}

export type IIdxOpPackage = {
  [key in INumString]: IOpPackage;
};

/* prettier-ignore */
// var xlCloneHeader = ['Campaign Name (Existing)', 'New Campaign Name', 'StartDate', 'EndDate', 'Budget', 'Daily Budget Target', 'Impression Budget', 'Daily Impression Target', 'AdGroupsToClone', 'ShouldCloneAdGroupBudgets'];
export var templateTabs = { Packages: 0, Package_Campaigns: 1, Campaign_Cloning:3 };
export var templateHeaders = {
  Packages: [
    'AdvertiserId',
    'PackageId',
    'PackageName',
    'CampaignId',
    'CampaignName'
  ],
  Package_Campaigns: [
    'AdvertiserId',
    'CampaignId',
    'CampaignName',
    'CampaignFlightId',
    'StartDateInclusiveEST',
    'EndDateExclusiveEST',
    'BudgetInAdvertiserCurrency',
    'DailyTargetInAdvertiserCurrency',
    'BudgetInImpressions',
    'DailyTargetInImpressions',
    'PacingMode',
    'TimeZone',
    'UpdateAdGroupBudgets'
  ],
  Campaign_Cloning: [
    'BaseAdvertiserId',
    'BaseCampaignId',
    'BaseCampaignName',
    'TargetAdvertiserId',
    'TargetCampaignName',
    'StartDateInclusiveEST',
    'EndDateExclusiveEST',
    'BudgetInAdvertiserCurrency',
    'DailyTargetInAdvertiserCurrency',
    'BudgetInImpressions',
    'DailyTargetInImpressions',
    'AdGroupsToClone',
    // 'ShouldCloneAdGroupBudgets',
    'UpdateAdGroupBudgets'
  ]
};
/* prettier-ignore */
export var  pkgHeader =['AdvertiserId', 'PackageId', 'PackageName', 'CampaignId', 'CampaignName'],
/* prettier-ignore */
pkgCampHeader = ['AdvertiserId', 'CampaignId', 'CampaignName','CampaignFlightId', 'StartDateInclusiveEST', 'EndDateExclusiveEST', 'BudgetInAdvertiserCurrency', 'DailyTargetInAdvertiserCurrency', 'BudgetInImpressions', 'DailyTargetInImpressions',  'PacingMode', 'TimeZone', 'UpdateAdGroupBudgets'];

export const campaignCopyColumns = [
  'CampaignConversionReportingColumns',
  'AutoAllocatorEnabled',
  'AutoPrioritizationEnabled',
  'Objective',
  'PrimaryGoal',
  'PrimaryChannel',
  // Kokai
  'Version'
];

var pkgClHeader = templateHeaders.Campaign_Cloning;
/* prettier-ignore */
var tCFHeader = {  CampaignId:'string', PackageId:'int', CampaignFlightId:'int', AdvertiserId:'string', CampaignName:'string',  PackageName:'string', BudgetInAdvertiserCurrency:'number', DailyTargetInAdvertiserCurrency:'number', BudgetInImpressions:'int', DailyTargetInImpressions:'int', StartDateInclusiveEST:'date', EndDateExclusiveEST:'date', PacingMode:'pacing', TimeZone:'string', UpdateAdGroupBudgets:'yes_no' },
/* prettier-ignore */
tCLHeader = { BaseAdvertiserId:'string', BaseCampaignId:'string', BaseCampaignName:'string', TargetAdvertiserId:'string', TargetCampaignName:'string', BudgetInAdvertiserCurrency:'number', DailyTargetInAdvertiserCurrency:'number', BudgetInImpressions:'int', DailyTargetInImpressions:'int', StartDateInclusiveEST:'date', EndDateExclusiveEST:'date', AdGroupsToClone:'string', /* ShouldCloneAdGroupBudgets:'yes_no', */ UpdateAdGroupBudgets:'yes_no' },
/* prettier-ignore */
rqHeader = { AdvertiserId:true, CampaignName:true,  PackageName:true, BudgetInAdvertiserCurrency:true, StartDateInclusiveEST:true,PacingMode:true, TimeZone:true },
/* prettier-ignore */
clRqHeader = { BaseAdvertiserId:true, TargetCampaignName:true,  BudgetInAdvertiserCurrency:true, StartDateInclusiveEST:true }
var advertiserFields = [
  'AdvertiserId',
  'BaseAdvertiserId',
  'TargetAdvertiserId'
];
// var RowLimit = 150;

//var sWb = XLSXUtils.book_new();
let outputColumn = 'Error Report';

/**
 * @param pkgArr - Array of exported Packages from API
 * @returns e.g. {2:
 *{"AdvertiserId":"qwu829h","PackageId":2,"PackageName":"Fort Smith/Fayetteville/Springdale/Rogers","Members":[{"CampaignId":"xtr2mfh","CampaignName":"mycampaign"...}]}}
 */
export const getIndexedPackages = (pkgArr: Array<any>): IIdxPackage => {
  var xlHeader1 = ['AdvertiserId', 'PackageId', 'PackageName'],
    xlHeader2 = ['CampaignId', 'CampaignName'];
  var pkgHeader1 = ['ai', 'pi', 'pn'],
    pkgHeader2 = ['ci', 'cn'];
  if (!Array.isArray(pkgArr)) {
    return {} as IIdxPackage;
  }
  return pkgArr.reduce((acc, row, rIdx) => {
    var rowObj = {};
    pkgHeader1.forEach((header, hIdx) => {
      //@ts-ignore
      rowObj[xlHeader1[hIdx]] = row[header];
    });
    // get campaigns
    var camps = row['m'];
    var cIdxObj = {};
    var cArr = camps.map((camp: any) => {
      var cObj = {};
      // translate campaign name / id
      pkgHeader2.forEach((cHeader, cIdx) => {
        //@ts-ignore
        cObj[xlHeader2[cIdx]] = camp[cHeader];
      });
      //@ts-ignore
      cIdxObj[camp['ci']] = camp['cn'];
      // log(JSON.stringify(c2Arr));
      return cObj;
    });
    //@ts-ignore
    rowObj['Members'] = cArr;
    //@ts-ignore
    rowObj['Campaigns'] = cIdxObj;
    //@ts-ignore
    acc[row['pi']] = rowObj;
    return acc;
  }, {});
};

export const getIndexedCampaigns = (
  campArr: Array<any>
): ITtdCampaignObject => {
  var cHeader1 = [
      'AdvertiserId',
      'CampaignId',
      'CampaignName',
      'PacingMode',
      'TimeZone',
      'CampaignConversionReportingColumns',
      'AutoAllocatorEnabled',
      'AutoPrioritizationEnabled',
      'Objective',
      'PrimaryGoal',
      'PrimaryChannel',
      // include Kokai Version
      'Version'
    ],
    cHeader2 = [
      'CampaignFlightId',
      'CampaignId',
      'BudgetInAdvertiserCurrency',
      'DailyTargetInAdvertiserCurrency',
      'BudgetInImpressions',
      'DailyTargetInImpressions',
      'StartDateInclusiveUTC',
      'EndDateExclusiveUTC'
    ];
  if (!Array.isArray(campArr)) {
    return {} as ITtdCampaignObject;
  }

  return campArr.reduce((acc, campEl, idx) => {
    var retObj = {};
    cHeader1.forEach((cEl) => {
      //@ts-ignore
      retObj[cEl] = campEl[cEl];
    });
    var campFlights = campEl['CampaignFlights'];
    var cIdxObj = {};
    var cfArr = campFlights.map((cfl: any) => {
      var cObj = {};
      // translate campaign name / id
      cHeader2.forEach((cfHeader, cIdx) => {
        // only use meaningfull header fields
        if (cfl[cfHeader] !== null) {
          //@ts-ignore
          cObj[cfHeader] = cfl[cfHeader];
        }
      });
      //@ts-ignore
      cIdxObj[cfl['CampaignFlightId']] = cObj;
      // log(JSON.stringify(c2Arr));
      return cObj;
    });
    //@ts-ignore
    retObj['CampaignFlights'] = cfArr;
    //@ts-ignore
    retObj['cfl'] = cIdxObj;
    //@ts-ignore
    acc[campEl['CampaignId']] = retObj;
    return acc;
  }, {});
};

/**
 * creates name index of TTD Campaigns
 * @param ttdCampArr - array of ttd campaigns
 */
export const campNameIndex = (ttdCampArr: IBaseCamp[]) => {
  if (!Array.isArray(ttdCampArr)) {
    return {};
  }
  return ttdCampArr.reduce((acc, el, idx) => {
    var campName = el.CampaignName,
      // @ts-ignore
      fnd = acc[campName];
    if (fnd) {
      fnd.count++;
    } else {
      var id = el.CampaignId;
      // @ts-ignore
      acc[campName] = { id, count: 1 };
    }
    return acc;
  }, {});
};

/**
 * build lookup index for existing and new campaign names
 * @param ttdCampaigns
 * @param campCreate
 * @returns
 */
export const buildCampaignNameIndex = (
  ttdCampaigns: IBaseCamp[],
  campCreate: Object
) => {
  var nameIdx: ICampNameRegIdx = campNameIndex(ttdCampaigns);
  var nameKeys = Object.keys(campCreate);
  var newCamps: ICampNameRegIdx = nameKeys.reduce((acc, el, idx) => {
    acc[el] = { id: 'n_' + idx, count: 1 };
    return acc;
  }, {});
  Object.assign(nameIdx, newCamps);
  return nameIdx;
};

export const indexCampaignAdgroups = (
  campIndex: ITtdCampaignObject,
  adGroups: Array<ICampAdgroups>,
  bCheckDups = true
) => {
  if (campIndex && adGroups && adGroups.length > 0) {
    let agCamps = {};
    adGroups.forEach((adGroup) => {
      let campId = adGroup.CampaignId;
      if (campId) {
        let campEl = campIndex[campId];
        if (campEl) {
          agCamps[campId] = 1;
          if (!campEl.agIds) {
            campEl.agIds = {};
          }
          if (!campEl.agNames) {
            campEl.agNames = {};
          }
          let agNames = campEl.agNames;
          let agIds = campEl.agIds;
          agNames[adGroup['AdGroupName']] = adGroup['AdGroupId'];
          agIds[adGroup['AdGroupId']] = trimStr(adGroup['AdGroupName']);
        }
      }
    });
    if (bCheckDups) {
      let campKeys = Object.keys(agCamps);
      campKeys.forEach((campId) => {
        let campEl = campIndex[campId];
        if (campEl) {
          let agNames = campEl.agNames;
          if (agNames) {
            let agNamesArr = Object.keys(agNames);
            var dupSet = getDuplicatesNamesSet(agNamesArr);

            Object.assign(campEl, {
              agDupl: Array.from(
                new Set(agNamesArr.filter((el: any) => dupSet.has(el)))
              ),
              agCount: agNamesArr.length
            });
          }
        }
      });
    }
  }
  return campIndex;
};

/**
 * updates base campaign ids if we only have base campaign names by
 * looking up the names in campNameIdx and verifying the advertiser
 * using ttdCampData. Afterwards we sort the data again to ensure the expected order
 * @param campJson: ICloneCampaign[] - JSON clone campaign data
 * @param ttdCampData: ITtdCampaignObject - campaign index
 * @param campNameIdx: ICampNameRegIdx - campaign name index
 */
export const updateBaseCampaignIds = (
  campJson: ICloneCampaign[],
  ttdCampData: ITtdCampaignObject,
  campNameIdx: ICampNameRegIdx
) => {
  if (!Array.isArray(campJson)) {
    return campJson;
  }
  campJson.forEach((camp: ICloneCampaign) => {
    if (!camp.BaseCampaignId) {
      let bCampName = trimStr(camp.BaseCampaignName);
      // lookup BaseCampaign by name instead of index
      let campNameEl = campNameIdx[bCampName];
      if (campNameEl && campNameEl.count === 1) {
        // campaign name is unique
        let campId = campNameEl.id;
        if (!campId.startsWith('n_')) {
          let campOk = verifyAdvertiserId(
            { CampaignId: campId, AdvertiserId: camp.BaseAdvertiserId },
            ttdCampData,
            undefined
          );
          if (campOk) {
            camp.BaseCampaignId = campId;
          }
        }
      }
    }
  });
  return sortCloneData(campJson);
};

/**
 * adds error messages from errorArr to campJson
 * @param campJson -  array of campaign objects
 * @param errorArr - array of errors
 * @returns true if errors were found and updated
 */
// prettier-ignore
export const updateErrorMessages = (campJson:ICampaign[], startIdx:number, errorArr:string[]) => {
  var errorsFound = false;
   if (errorArr.length > 0) {
     errorArr.forEach((err, errIdx) => {
       // put error in correct campaign flight row
       errorsFound = setReportStatus(startIdx + errIdx, campJson, err);
     });
   }
 return errorsFound;
}

const parseDate = (dtEl: number, date1904: boolean): Date => {
  //we have a number
  // XLSX.SSF.parse_date_code(43473.791666666664, {date1904:false})
  //{'D':43473,'T':68399,'u':0,'y':2019,'m':1,'d':8,'H':19,'M':0,'S':0,'q':2}
  /*
   y: year
   m: month
   d: day
   T: number of seconds since midnight.
   H: hours in the 24-hour system
   M: minutes
   S: seconds
   u: fraction of a second (unfortunately needed for some odd formatting issues)
   */
  let parsed = SSF.parse_date_code(dtEl, { date1904 });
  // construct date (month are 0 based)
  return new Date(
    parsed.y,
    parsed.m - 1,
    parsed.d,
    parsed.H,
    parsed.M,
    parsed.S
  );
};

/**
 * Checks columns in jsonObj
 * @param jsonObj
 * @param columns - Array of columns to check
 * @param date1904 - indicates 1904 based dates
 * @param errFunc - error function to call;
 * @param baseHeader - header object to use
 * @param requiredHeader - header object to test required fields
 * @returns {msg - errorMesages, obj - Object of verified values }
 */
export const checkObjColumns = (
  jsonObj: Object,
  columns: Array<string>,
  date1904: boolean = false,
  errFunc: Function,
  baseHeader: Object = tCFHeader,
  requiredHeader: Object = rqHeader
) => {
  let errorMsgArr: Array<any> = [];
  let retObj = {};
  columns.forEach((col: string) => {
    var fType = baseHeader[col];
    var required = requiredHeader[col] || false;
    var msg: string = ' is an invalid ';
    var cEl = trimStr(jsonObj[col]);
    var cObjCol = col;
    var mError = false;
    // columns could be undefined, which is valid, so only test existing ones
    if (required || (cEl !== undefined && (cEl !== '' || fType === 'pacing'))) {
      switch (fType) {
        case 'date':
          msg += 'date';
          // test if number
          // console.log('testing date: ' + cEl);
          if (!isNaN(cEl) && cEl > 0) {
            //we have a number -> parse it as date
            // build ISO date with +00:00 as timezone
            let mDt = parseDate(cEl, date1904);
            let compDate = new Date('2019-12-31');
            if (mDt < compDate) {
              msg = ' must start in 2020 or later';
              console.log('error - past date');
              mError = true;
            }
          }
          if (col.includes('EST')) {
            // rename date columns for later UTC conversion already here
            cObjCol = col.replace('EST', 'UTC');
          }
          // else {
          //   // console.log('error - wrong date');
          //   mError = true;
          //   // error
          // }
          break;
        case 'number':
          msg += 'number';
          // test if number
          // console.log('testing number: ' + cEl);
          if (isNaN(cEl)) {
            // console.log('error - wrong number');
            mError = true;
          } else {
            if (cEl < 0 || (cEl === '' && required)) {
              msg = ' must be >= 0';
              // console.log('error - not greater 0');
              mError = true;
            }
          }
          break;
        case 'int':
          msg = ' must be an integer >= 0';
          // test if number
          // console.log('testing integer: ' + cEl);

          if (isNaN(cEl)) {
            mError = true;
          } else {
            if (cEl <= 0 || (cEl === '' && required)) {
              // console.log('error - not greater 0');
              mError = true;
            }
            if (parseFloat(cEl) !== parseInt(cEl)) {
              // console.log('error - wrong int');
              mError = true;
            } else {
              cEl = cEl as Int;
            }
          }
          break;
        case 'string':
          msg = ' must be provided';
          if (!cEl) {
            //  console.log('error - wrong string');
            mError = true;
          }
          break;
        case 'yes_no':
          msg = ' must be "yes", "no" or empty ';
          if (cEl && !/^yes|no$/i.test(cEl)) {
            //  console.log('error - wrong string');
            mError = true;
          }
          break;
        case 'pacing':
          msg = " must be 'PaceToEndOfFlight', 'PaceAhead' or 'Off'";
          // console.log('testing pacing: ' + cEl);
          if (!/PaceToEndOfFlight|PaceAhead|Off/.test(cEl)) {
            //  console.log('error - wrong pacing');
            mError = true;
          }
          break;
      }
      if (mError) {
        errorMsgArr.push(col + msg);
      } else retObj[cObjCol] = cEl;
    }
  });
  errFunc([errorMsgArr]);
  return retObj;
};

/**
 * verifies: start date is provided and before end date if end date exists
 * @param sDt
 * @param eDt
 * @returns error message or ''
 */
export const checkStartEnd = (sDt?: number | string, eDt?: number | string) => {
  // columns could be undefined, which is valid, so only test existing ones
  var msg;
  if (sDt) {
    if (typeof sDt === 'string') {
      if (checkDate(sDt) !== '') {
        msg = 'StartDateInclusiveEST must be a valid date';
      } else {
        sDt = getExcelDateCode(sDt);
      }
    }
    if (eDt && typeof eDt === 'string') {
      if (checkDate(eDt) !== '') {
        msg = 'EndDateExclusiveEST must be a valid date';
      } else {
        eDt = getExcelDateCode(eDt);
      }
    }
    if (eDt && !isNaN(Number(eDt)) && eDt <= sDt) {
      msg = 'EndDateExclusiveEST must be greater StartDateInclusiveEST';
    }
  } else {
    msg = 'StartDateInclusiveEST must be a valid date';
  }
  return msg;
};

/**
 * verifies end date for dt
 * @param dt - campaign flight entry to verify end date for
 * @param isA - true - returns aEd, aNoEnd; false returns bEd, bNoEnd
 * @returns eDt - Number for defined end date, positive Infinity otherwise
 *          noEnd - true for undefined end dates
 */
const verifyEndDateExists = (dt: CampaignFlight, isA: boolean) => {
  var eDt = Number.POSITIVE_INFINITY,
    noEnd = false;
  if (!dt.EndDateExclusiveUTC) {
    noEnd = true;
  } else {
    eDt = Number(dt.EndDateExclusiveUTC);
  }
  if (isA) {
    return { aEd: eDt, aNoEnd: noEnd };
  }
  return { bEd: eDt, bNoEnd: noEnd };
};

/**
 * checks flight date overlap
 * @param cfArr - Array of CampaignFlights
 */
// prettier-ignoreSet
export const checkFlightDateRanges = (
  cfArr: CampaignFlight[],
  errorFunc: Function
) => {
  // sort flights Array
  var cfSorted = cfArr.sort((a, b) => {
    var aSd = Number(a.StartDateInclusiveUTC),
      bSd = Number(b.StartDateInclusiveUTC),
      aEd = Number(a.EndDateExclusiveUTC),
      bEd = Number(b.EndDateExclusiveUTC);
    if (aSd === bSd) {
      if (!isNaN(aEd) && !isNaN(bEd)) {
        return aEd - bEd;
      }
      return 0;
    }
    return aSd - bSd;
  });

  var fLen = cfSorted.length - 1,
    errArr: string[][] = [];

  for (var i = 1; i <= fLen; i++) {
    var b = cfSorted[i],
      bSd = Number(b.StartDateInclusiveUTC),
      errMsg: string[] = [];

    // sets aEd / bEd to a number is end dates exist or aNoEnd / bNoEnd to true otherwise
    var { aEd, aNoEnd } = verifyEndDateExists(cfSorted[i - 1], true);
    var { bEd, bNoEnd } = verifyEndDateExists(b, false);
    if (aNoEnd && bNoEnd) {
      errMsg.push('Only one flight without end date is allowed');
    }
    // @ts-ignore  - check overlaps
    if ((!aNoEnd && (aEd >= bSd || bEd <= aEd)) || (aNoEnd && !bNoEnd)) {
      errMsg.push('Flight dates must not overlap');
    }
    if (errMsg.length > 0) {
      errArr[i] = errMsg;
      if (i === 1) {
        errArr[0] = errMsg;
      }
    }
  }
  if (errArr.length > 0 && typeof errorFunc === 'function') {
    errorFunc(errArr);
  }
  return cfSorted;
};

/**
 * verifies basic flight details (if budgets / targets are valid numbers, dates, campaignid + flight id are valid )
 * @param cf Object with Flight details
 * @param bUpdate true for update, false for create
 * @param bDate1904 - true for 1904 based dates
 * @returns {msg: string of Errors, obj }
 */
// prettier-ignore
export const verifyFlightData = (cf: ICampaign | ICloneCampaign,bUpdate = false, bDate1904 = false, errFunc:Function) => {
  // prettier-ignore
  var cfDetails = ['BudgetInAdvertiserCurrency', 'DailyTargetInAdvertiserCurrency', 'BudgetInImpressions', 'DailyTargetInImpressions', 'StartDateInclusiveEST', 'EndDateExclusiveEST']
  if (bUpdate) {
    cfDetails.push('CampaignFlightId');
  }
  let cf1 = (cf as unknown) as ICampaign
  if(cf1.CampaignId){
    cfDetails.push('CampaignId')
  }
  var  obj  = checkObjColumns(cf, cfDetails, bDate1904, errFunc);
  var msg = checkStartEnd(cf.StartDateInclusiveEST, cf.EndDateExclusiveEST);
  if (msg) {
    errFunc(msg)
  }
  return  (obj as unknown) as CampaignFlight ;
};
// prettier-ignore
export const isFlightModified = (cfOrig: CampaignFlight, cf: ICampaign, dt1904 = false): boolean => {
  // prettier-ignore
  var cfDetails = ['BudgetInAdvertiserCurrency', 'DailyTargetInAdvertiserCurrency', 'BudgetInImpressions', 'DailyTargetInImpressions', 'StartDateInclusiveUTC', 'EndDateExclusiveUTC']
  return cfDetails.some((el) => {
    if (el.includes('Date')) {
      var replaceEl = el.replace('UTC', 'EST');
      var compDt = cf[replaceEl];
      if (!isNaN(compDt) && compDt > 0) {

        var oDate = new Date(cfOrig[el]);
        var compDate = parseDate(compDt, dt1904);
        var dtStr = compDate.toISOString();
        var dtUTC = convertESTtoUTC(dtStr), compDateUTC = new Date();
        if(dtUTC){
          compDateUTC = new Date(dtUTC)
        }
        // summer time conversion happens as part of EST to UTC conversion now
        // var jan = new Date(oDate.getFullYear(), 0, 1);
        // var jul = new Date(oDate.getFullYear(), 6, 1);
        // var stdTimezoneOffset = Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset())
        // var isSummerTime = oDate.getTimezoneOffset() < stdTimezoneOffset
        // if(isSummerTime){
        //   compDate.setHours(compDate.getHours() -1)
        // }
        return oDate.getTime() !== compDateUTC.getTime();
      }
      if(typeof compDt === 'string' && checkDate(compDt)=== ''){
        var oDate = new Date(cfOrig[el]);
        var dtUTC = convertESTtoUTC(compDt), compDateUTC = new Date();
        if(dtUTC){
          compDateUTC = new Date(dtUTC)
        }
        return oDate.getTime() !== compDateUTC.getTime();
      }
      return true;
    } else {
      if(el.includes('Daily') && cf.PacingMode && cf.PacingMode !== 'Off'){
        return false
      }
      var compNum = Number(cf[el]);
      if (!isNaN(compNum)) {
        var oNum = cfOrig[el];
        if (compNum === 0) {
          var compRes = oNum !== undefined ||( !(cf[el] === '' && (cfOrig[el] === undefined || cfOrig[el] === '')) && oNum !== compNum);
          return compRes;
        } else {
          return oNum !== compNum;
        }
      } else {
        return true;
      }
    }
  });
};

const rowFilter = (header: Array<any>, jsonArr: Array<any>) => {
  return jsonArr.filter((row: any, idx) => {
    // filter out rows without useful data
    return header.some((field) => {
      return row[field];
    });
  });
};

const uniqueColFilter = (header: Array<any>, jsonArr: Array<any>) => {
  var re = /[a-z0-9]+/i;
  return jsonArr.reduce((acc, el) => {
    header.forEach((hEl) => {
      var testEl = el[hEl];
      if (testEl && re.test(testEl)) {
        var aEl = acc[hEl];
        if (!aEl) {
          acc[hEl] = {};
          aEl = acc[hEl];
        }
        if (!aEl[testEl]) {
          aEl[testEl] = 1;
        }
      }
    });
    return acc;
  }, {});
};

/**
 * gets packages, packageCampaign and cloneCampaign data
 * @param xlData
 * @returns {pkgData - packages, pkgCampData - packageCampaigns, clCampData - cloneCampaigns}
 */
export const splitExcelData = (xlData: IExcelDataArray) => {
  var pkgData: Array<any> = [],
    pkgCampData: Array<any> = [],
    clCampData: Array<any> = [];
  xlData.forEach((sheetRow) => {
    if (sheetRow.sheetName === 'Packages') {
      pkgData = sheetRow.sheetData;
    } else if (sheetRow.sheetName === 'Package_Campaigns') {
      pkgCampData = sheetRow.sheetData;
    } else if (sheetRow.sheetName === 'Campaign_Cloning') {
      clCampData = sheetRow.sheetData;
    }
  });
  return { pkgData, pkgCampData, clCampData };
};

/**
 * gets packages, packageCampaign and cloneCampaign data
 * @param xlData
 * @returns {pkgData - packages, pkgCampData - packageCampaigns, clCampData - cloneCampaigns}
 */
export const splitExcel = (xlData: IExcelDataArray) => {
  var pkgCampSheetData: IExcelData = {
      sheetName: '',
      sheetData: [],
      extraHeaders: [],
      date1904: false
    },
    pkgSheetData: IExcelData = { ...pkgCampSheetData },
    clCampSheetData: IExcelData = { ...pkgCampSheetData };
  xlData.forEach((sheet) => {
    if (sheet.sheetName === 'Packages') {
      pkgSheetData = sheet;
    } else if (sheet.sheetName === 'Package_Campaigns') {
      pkgCampSheetData = sheet;
    } else if (sheet.sheetName === 'Campaign_Cloning') {
      clCampSheetData = sheet;
    }
  });
  return { pkgSheetData, pkgCampSheetData, clCampSheetData };
};

/**
 * gets the unique Advertiser Ids we need to analyse categorized by the advertiser id field names present in data
 * @param data JSON Array of Excel Data
 * @returns {"AdvertiserId"?:[...],"BaseAdvertiserId"?: [...], "TargetAdvertiserId"?: [...]}
 */
export const getAdvertiserIdsFromData = (data: Array<any>): ICloneAdvIds => {
  var advIds = uniqueColFilter(advertiserFields, data);
  return advertiserFields.reduce((acc, field) => {
    var advObj = advIds[field];
    if (advObj) {
      acc[field] = Object.keys(advObj).filter(
        (el) => !filter_advertiser_ids.includes(el)
      );
    }
    return acc;
  }, {});
};

/**
 * Gets Unique Advertiser Ids from all Excel data sheets
 * @param xlData - ExcelData Array
 * @returns Array<string> of Advertiser ids
 */
export const getUniqueAdvertiserCategoryIds = (
  xlData: IExcelDataArray
): ICloneAdvIds => {
  var combData = xlData.reduce((acc, el) => {
    acc = acc.concat(el.sheetData);
    return acc;
  }, [] as Array<any>);
  return getAdvertiserIdsFromData(combData);
};

/**
 * Gets combines all categorized Advertiser Ids into 1 common array for loading
 * @param advData - {"AdvertiserId"?: [...],"BaseAdvertiserId"?: [...], "TargetAdvertiserId"?: [...]}
 * @returns Array<string> of all Advertiser ids
 */

export const getAllAdvertiserIds = (advData: ICloneAdvIds): Array<string> => {
  var advIds = advertiserFields.reduce((acc, field) => {
    var advArr = advData[field];
    if (advArr) {
      acc = [...acc, ...advArr];
    }
    return acc;
  }, [] as Array<string>);
  // make advIds unique
  return Array.from(new Set(advIds));
};

// sort by key after finding the right sort function
const callSort = (key: string, aData: Array<any>) => {
  var data = aData;
  if (key) {
    data = sortJsonArr(
      data,
      getSortFunc(key),
      key,
      key.includes('Date') ? false : true
    );
  }
  return data;
};

// perform sort operations in revers order of keys until defined end is reached
export const sortInSequence = (
  arrKeys: Array<string>,
  aData: Array<any>,
  end: number
) => {
  var data = aData,
    kLen = arrKeys.length - 1;
  for (var i = kLen; i >= end; i--) {
    var key = arrKeys[i];
    if (key) {
      data = callSort(key, data);
    }
    // console.log(JSON.stringify(data));
  }
  return data;
};

/**
 * sorts our package or campaign data to have updates first (where we have a valid id)
 *   to be followed by creations (where we only have a name)
 * @param data
 * @param bPackage - true for packages , false for campaigns
 * @returns sorted data
 */
const sortCreateUpdate = (data: Array<any>, bPackage = true) => {
  var aKeys = ['PackageId', 'PackageName', 'CampaignId', 'CampaignName'],
    aCreate: Array<any> = [],
    aUpdate: Array<any> = [];
  if (!bPackage) {
    aKeys = [
      'CampaignId',
      'CampaignName',
      'CampaignFlightId',
      'StartDateInclusiveEST',
      'EndDateExclusiveEST'
    ];
  }
  var key1 = aKeys[0];
  data.forEach((el, idx) => {
    var val = el[key1];
    if (typeof val === 'string') {
      val = val.trim();
    }
    if (val) {
      aUpdate.push(el);
    } else {
      aCreate.push(el);
    }
    el['rowIdx'] = idx;
    data[idx]['rowIdx'] = idx;
  });

  aUpdate = sortInSequence(aKeys, aUpdate, 0);
  // for create we only need to sort without package id for packages / campaign id for campaigns
  aCreate = sortInSequence(aKeys, aCreate, 1);
  return aUpdate.concat(aCreate);
};

/**
 * returns campaign ids or campaign names for ad groups we need to read
 * @param data - Json Array
 */
export const prepareReadCampAdgroups = (
  data: Array<any>
): IPrepareReadAdgroups => {
  /**
   * stores either end-trimmed row[field] in storeArray for names or
   *               trimmed row[field] in storeArray for ids
   *  if those don't exists in storeArray yet
   * @param row - row containing the field
   * @param field - field to use
   * @param storeArray - array name to store values
   * @param acc - Object containing advertiserId based storage arrays
   * @returns - true if field was not empty
   */
  const storeUniqueTrimmedEl = (
    row: Object,
    field: string,
    storeArray: string,
    acc: Object
  ) => {
    var testEl = row[field],
      idRe = /[0-9a-z]+/i;
    if (testEl) {
      // convert to string to be extra safe
      testEl = testEl + '';
      testEl = testEl.trimEnd();
      if (!field.endsWith('Id') || idRe.test(testEl.trim())) {
        // if no target advertiser is set, it's the same as the base  advertiser
        var advId = row['AdvertiserId']; // row['TargetAdvertiserId'] //|| row['BaseAdvertiserId'];
        // use trim to be extra safe as we face user input
        if (advId) {
          advId = advId + '';
          advId = advId.trim();
          // verify advertiser id pattern
          if (idRe.test(advId)) {
            var sBase = acc[advId];
            if (!sBase) {
              acc[advId] = { CampaignIds: [], CampaignNames: [] };
              sBase = acc[advId];
            }
            if (!sBase[storeArray].includes(testEl)) {
              sBase[storeArray].push(testEl);
            }
            return true;
          }
        }
      }
    }
  };
  var testRe = /yes/i; //allRe = /^all$/i;
  return data.reduce((acc, el) => {
    if (el['UpdateAdGroupBudgets'] && testRe.test(el['UpdateAdGroupBudgets'])) {
      // try storing base campaign id first
      if (!storeUniqueTrimmedEl(el, 'CampaignId', 'CampaignIds', acc)) {
        // no campaign id found - try storing base campaign name
        storeUniqueTrimmedEl(el, 'CampaignName', 'CampaignNames', acc);
      }
    }
    return acc;
  }, {});
};

export const getAdgroupLoadStrategy = (agObj: IPrepareReadAdgroups) => {
  var finalCount = 0;
  if (agObj) {
    var agArr = Object.keys(agObj);
    agArr.forEach((advId) => {
      var cObj = agObj[advId];
      var idCount = (cObj.CampaignIds && cObj.CampaignIds.length) | 0;
      var nameCount = (cObj.CampaignNames && cObj.CampaignNames.length) | 0;
      // we can load about 1k adgroups per request
      var totalCount = idCount + nameCount;
      if (totalCount > 5) {
        cObj.loadAdvAdgroups = true;
      } else {
        cObj.loadAdvAdgroups = false;
      }
      cObj.totalCount = totalCount;
      finalCount += totalCount;
    });
  }
  return finalCount > 0;
};

/**
 * returns campaign ids or campaign names for ad groups we need to read
 * @param data - Json Array
 */
export const prepareReadAdgroups = (data: Array<any>): IPrepareReadAdgroups => {
  /**
   * stores either end-trimmed row[field] in storeArray for names or
   *               trimmed row[field] in storeArray for ids
   *  if those don't exists in storeArray yet
   * @param row - row containing the field
   * @param field - field to use
   * @param storeArray - array name to store values
   * @param acc - Object containing advertiserId based storage arrays
   * @returns - true if field was not empty
   */
  const storeUniqueTrimmedEl = (
    row: Object,
    field: string,
    storeArray: string,
    acc: Object
  ) => {
    var testEl = row[field],
      idRe = /[0-9a-z]+/i;
    if (testEl) {
      // convert to string to be extra safe
      testEl = testEl + '';
      testEl = testEl.trimEnd();
      if (!field.endsWith('Id') || idRe.test(testEl.trim())) {
        // if no target advertiser is set, it's the same as the base  advertiser
        var advId = row['BaseAdvertiserId']; // row['TargetAdvertiserId'] //|| row['BaseAdvertiserId'];
        // use trim to be extra safe as we face user input
        if (advId) {
          advId = advId + '';
          advId = advId.trim();
          // verify advertiser id pattern
          if (idRe.test(advId)) {
            var sBase = acc[advId];
            if (!sBase) {
              acc[advId] = { CampaignIds: [], CampaignNames: [] };
              sBase = acc[advId];
            }
            if (!sBase[storeArray].includes(testEl)) {
              sBase[storeArray].push(testEl);
            }
            return true;
          }
        }
      }
    }
  };
  var testRe = /yes/i,
    allRe = /^all$/i;
  return data.reduce((acc, el) => {
    if (
      /*(el['ShouldCloneAdGroupBudgets'] &&
        testRe.test(el['ShouldCloneAdGroupBudgets'])) ||*/
      el['AdGroupsToClone'] &&
      !allRe.test(el['AdGroupsToClone'])
    ) {
      // try storing base campaign id first
      if (!storeUniqueTrimmedEl(el, 'BaseCampaignId', 'CampaignIds', acc)) {
        // no campaign id found - try storing base campaign name
        storeUniqueTrimmedEl(el, 'BaseCampaignName', 'CampaignNames', acc);
      }
    }
    return acc;
  }, {});
};

/**
 * sorts clone data to easier determine validity
 * @param data - json Array of Cloning data
 * @returns sorted data
 */
export const sortCloneData = (data: Array<any>) => {
  var aKeys = [
    'TargetCampaignName',
    'TargetAdvertiserId',
    'BaseCampaignId',
    'BaseAdvertiserId',
    'StartDateInclusiveEST',
    'EndDateExclusiveEST'
  ];
  data.forEach((el, idx) => {
    if (el['rowIdx'] === undefined) {
      el['rowIdx'] = idx;
    }
  });
  return sortInSequence(aKeys, data, 0);
};

/**
 * convert EST data to UTC
 */

export const convertExcelDataESTtoUTC = (data: Array<any>) => {
  // Extract headers (first row)
  const keysToConvert = ['StartDateInclusiveEST', 'EndDateExclusiveEST'];
  // Process each object in the list
  return data.map((item) => {
    const newItem: { [key: string]: any } = { ...item }; // Copy the item to avoid mutation

    keysToConvert.forEach((key) => {
      // Check if the key exists in the item and is a number (assuming serial dates are numbers)
      if (key in item && typeof item[key] !== 'undefined') {
        // Convert the date and rename the key
        const newKey = key.replace('EST', 'UTC');
        newItem[newKey] = convertESTtoUTC(item[key]);
        delete newItem[key]; // Remove the old key
      }
    });

    return newItem;
  });
};

/**
 * Removes empty data rows and sorts data for easier verification
 * @param data Json Sheet data
 * @returns cleaned and sorted data
 */
export const cleanData = (data: Array<any>) => {
  return data.map((sheetRow) => {
    var sheetData;
    if (sheetRow.sheetName === 'Packages') {
      sheetData = rowFilter(pkgHeader, sheetRow.sheetData);
      sheetData = sortCreateUpdate(sheetData, true);
    } else if (sheetRow.sheetName === 'Package_Campaigns') {
      sheetData = rowFilter(pkgCampHeader, sheetRow.sheetData);
      // // EST UTC conversion
      // sheetData = convertExcelDataESTtoUTC(sheetData);
      sheetData = sortCreateUpdate(sheetData, false);
    } else if (sheetRow.sheetName === 'Campaign_Cloning') {
      sheetData = rowFilter(pkgClHeader, sheetRow.sheetData);
      // // EST UTC conversion
      // sheetData = convertExcelDataESTtoUTC(sheetData);
      sheetData = sortCloneData(sheetData);
    }
    sheetRow.sheetData = sheetData;
    return sheetRow;
  });
};

export const setOutputColumn = (name: string) => {
  outputColumn = name;
};

/**
 * test if end date is now or in the future
 * @param eDt - end date to test
 * @param date1904 true for 1904 date format
 * @returns
 */
const isLifeEndDate = (eDt: number | undefined, date1904: boolean) => {
  if (!eDt) {
    return true;
  }
  let mDt = parseDate(eDt, date1904);
  let compDate = new Date();
  let cDtUtc = new Date(compDate.toUTCString());
  return mDt >= cDtUtc;
};

/**
 * verifies daily target is set and smaller then lifetime budget
 * @param cfEl - campaign flight
 * @param compArr - Array with values to compare e.g.
 *  ['BudgetInAdvertiserCurrency','DailyTargetInAdvertiserCurrency'], ['BudgetInImpressions', 'DailyTargetInImpressions']
 * @returns message Array - empty if no errors were found
 */
const verifyCompareDailyTarget = (cfEl: CampaignFlight, compArr: string[]) => {
  var baseEl = compArr[0],
    compEl = compArr[1],
    budget = Number(cfEl[baseEl]),
    dailyBudget = Number(cfEl[compEl]),
    msgOff = " for pacing='Off'",
    msgArr: string[] = [];
  if (budget) {
    if (!dailyBudget) {
      msgArr.push(baseEl + ' is required' + msgOff);
    }
    if (dailyBudget > budget) {
      msgArr.push(compEl + ' must not be >= ' + baseEl + msgOff);
    }
  }
  return msgArr;
};

/**
 * tests for life campaigns (end date >= now)
 *       pacing <> Off -> end date is provided
 *                       no daily targets are set
 *       pacing = Off ->  daily targets are set +
 *                        don't exceed lifetime budget
 * @param cfArr - campaign flight array
 * @param campPacing - pacing
 * @param date1904 - true for 1904 based date
 * @param errorFunc - updates error messages
 */
export const checkFlightDatesBudget = (
  cfArr: CampaignFlight[],
  campPacing: campPacing,
  date1904: boolean,
  errorFunc: Function
) => {
  // prettier-ignore
  var dailyBudgetArr = ['DailyTargetInAdvertiserCurrency', 'DailyTargetInImpressions']
  var errorMsgArr: Array<any> = [];
  cfArr.forEach((cfEl: CampaignFlight, cIdx: number) => {
    var msgArr: string[] = [],
      eDt = cfEl.EndDateExclusiveUTC,
      isLife = isLifeEndDate(eDt, date1904);
    if (campPacing && campPacing !== 'Off' && !Number(eDt)) {
      msgArr.push(
        "EndDateExclusiveEST is required for pacing='" + campPacing + "'"
      );
    }
    if (campPacing && isLife) {
      if (campPacing !== 'Off') {
        dailyBudgetArr.forEach((bgCol) => {
          var bgEl = cfEl[bgCol];
          if (bgEl !== undefined) {
            msgArr.push(bgCol + " is only supported for pacing='Off'");
          }
        });
      }
      if (campPacing === 'Off') {
        var budget = Number(cfEl.BudgetInAdvertiserCurrency);
        if (budget) {
          let msg1 = verifyCompareDailyTarget(cfEl, [
            'BudgetInAdvertiserCurrency',
            'DailyTargetInAdvertiserCurrency'
          ]);
          let msg2 = verifyCompareDailyTarget(cfEl, [
            'BudgetInImpressions',
            'DailyTargetInImpressions'
          ]);
          msgArr = [...msg1, ...msg2];
        }
      }
    }
    if (msgArr.length > 0) {
      errorMsgArr[cIdx] = msgArr.join('\x0d\n');
    }
  });
  if (errorMsgArr.length > 0 && typeof errorFunc === 'function') {
    errorFunc(errorMsgArr);
  }
};

/**
 * checks if a new campaign starts in the next row
 * @param camp - package campaign row object
 * @param campComp - package campaign row object
 * @returns
 */
export const shouldCheckAllCampaignRows = (
  camp: ICampaign,
  campComp: ICampaign
) => {
  if (!campComp) {
    // last campaign row reached
    return true;
  }
  var campId = trimStr(camp.CampaignId);
  if (campId) {
    var campCompId = campComp.CampaignId;
    return !campCompId || campCompId !== campId;
  } else {
    var campName = trimStr(camp.CampaignName);
    var campCompName = trimStr(campComp.CampaignName);
    return !campCompName || campCompName !== campName;
  }
};

/**
 * checks if a new campaign starts in the next row
 * @param camp - clone campaign row object
 * @param campComp - clone campaign row object
 * @returns
 */
export const shouldCheckAllCloneCampaignRows = (
  camp: ICloneCampaign,
  campComp: ICloneCampaign
) => {
  const getAdvertiserId = (mCamp: ICloneCampaign) => {
    let targetAdvertiserId = trimStr(camp.TargetAdvertiserId);
    if (!targetAdvertiserId) {
      targetAdvertiserId = trimStr(camp.BaseAdvertiserId);
    }
    return targetAdvertiserId;
  };

  if (!campComp) {
    // last campaign row reached
    return true;
  }
  let targetAdvertiserId = getAdvertiserId(camp),
    compTargetAdvertiserId = getAdvertiserId(campComp);

  let campName = trimStr(camp.TargetCampaignName);
  let campCompName = trimStr(campComp.TargetCampaignName);
  let sameCamp =
    targetAdvertiserId === compTargetAdvertiserId && campName === campCompName;
  return !sameCamp;
};

/**
 * includes campaign id in list of campaigns to update + verifies campaign name is consistent
 * across campaign flights
 * @param campUpdate - object of campaigns to be updated
 * @param oCamp - current campaign object
 * @param campId - campaign id
 * @param campaignName
 * @param idx  - row index in campJson
 * @param campJson - array of campaign objects
 */
// prettier-ignore
export const isNewCampaignUpdate = (campUpdate:ITtdCampaignObject, oCamp:ITtdCampaign, campId, campaignName) => {
      // check if we already have the campaign in our list
      let cUpdate = campUpdate[campId], msg='', isNew = false, retCamp = oCamp;
      if (cUpdate) {
        retCamp = cUpdate;
        if (cUpdate.CampaignName !== campaignName) {
          // prettier-ignore
          msg =  'CampaignName needs to be consistent for campaign name updates';
        }

      }else {
        // update campaign list to include new campaign id
        campUpdate[campId] = oCamp;
        isNew = true;
      }
      return {isNew, msg, retCamp};
    };

/**
 * includes campaign id in list of campaigns to update + verifies campaign name is consistent
 * across campaign flights
 * @param campCreate - object of campaigns to be updated
 * @param oCamp - current campaign object
 * @param campaignName
 * @param idx  - row index in campJson
 * @param campJson - array of campaign objects
 */
// prettier-ignore
export const isNewCampaignCreation = (campCreate:ITtdCampaignObject, oCamp:ITtdCampaign,  campaignName) => {
      // check if we already have the campaign in our list
      var campName = trimStr(campaignName), isNew = false, retCamp = oCamp;
      if (campName) {
        let cCreate = campCreate[campName];
        if (cCreate) {
          retCamp = cCreate;
        }else {
          // update campaign list to include new campaign name
          campCreate[campName] = oCamp;
          isNew = true;
        }
      }
      return {isNew, retCamp};
    };

/**
 * verifies our campaign id exists in list of campaigns from TTD API
 * @param camp - package campaign row object
 * @param ttdCampData - object containing all ttd campaigns from API
 */
// prettier-ignore
export const verifyAdvertiserId = (camp: ICampaign, ttdCampData: ITtdCampaignObject, errFunc: Function|undefined) => {
  var campId = camp.CampaignId;
  if (campId) {
    var ttdCampaign = ttdCampData[campId];
    var advId = trimStr(camp.AdvertiserId);
    if (advId) {
      var tAdvId = ttdCampaign.AdvertiserId;
      //advertiser id should be matched
      if (advId !== tAdvId) {
        if(typeof errFunc === 'function'){
          // prettier-ignore
          errFunc( 'AdvertiserId does not match AdvertiserId ' + tAdvId + ' for provided CampaignId');
        }
        return false;
      }
    }
  }
  return true;
};

/**
 * verifies if the campaign name is being updated
 * @param camp - package campaign row object
 * @param ttdCampData - object containing all ttd campaigns from API
 */
//prettier-ignore
export const checkCampaignNameUpdate = (camp: ICampaign, ttdCampData: ITtdCampaignObject) => {
      var campId = camp.CampaignId;
      if (campId) {
        var ttdCampaign = ttdCampData[campId];
        var campName = trimStr(camp.CampaignName);
        if (campName) {
          var tCampName = ttdCampaign.CampaignName;
          return (campName && campName !== tCampName)
        }
      }
      return false
    };

/**
 * verifies if the campaign pacing mode is being updated
 * @param camp - package campaign row object
 * @param ttdCampData - object containing all ttd campaigns from API
 */
//prettier-ignore
export const checkCampaignPacingUpdate = (camp: ICampaign, ttdCampData: ITtdCampaignObject) => {
  var campId = camp.CampaignId;
  if (campId) {
    var ttdCampaign = ttdCampData[campId];
    var pm =trimStr(camp.PacingMode || '')
    if (pm) {
      var tCampPm = ttdCampaign.PacingMode || '';
      return (pm && pm!== tCampPm)
    }
  }
  return false
};

/**
 * verifies if the campaign time zone is being updated
 * @param camp - package campaign row object
 * @param ttdCampData - object containing all ttd campaigns from API
 */
//prettier-ignore
export const checkCampaignTzUpdate = (camp: ICampaign, ttdCampData: ITtdCampaignObject) => {
  var campId = camp.CampaignId;
  if (campId) {
    var ttdCampaign = ttdCampData[campId];
    var pm =trimStr(camp.TimeZone || '')
    if (pm) {
      var tCampPm = ttdCampaign.TimeZone || '';
      return (pm && pm!== tCampPm)
    }
  }
  return false
};

export const checkDate = (sDt: string) => {
  var dtRe = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2]\d|3[0-1])((T|\s)([0-1]\d|2[0-3]):[0-5]\d(:[0-5]\dZ?)?)$/;
  var sErr = '';
  if (sDt && !dtRe.test(sDt)) {
    sErr += 'Date has to be in the format YYYY-DD-MM HH24:mm:ss.\n';
  }
  // if (!sDtErr && !eDtErr) {
  //   let sDt = new Date(StartDateInclusiveUTC.replace('T', ' ') + ' GMT');
  //   let eDt = new Date(EndDateExclusiveUTC.replace('T', ' ') + ' GMT');
  //   if (eDt <= sDt) {
  //     sErr += 'End date must be after start date.\n';
  //   }
  // }
  return sErr;
};

// const transformCloneJson = ({
//   cloneJson,
//   errorRet
// }: {
//   cloneJson: any;
//   errorRet: boolean;
// }): any => {
//   // modifies cloneJson with errors as well
//   // var agStrArr = ['AdGroupsToClone', 'AdGroupsToEnable'];
//   var agStrArr = ['AdGroupsToClone'];
//   // ad arrays for cloned and enabled ad groups
//   errorRet = false;
//   var cloneAgJson = cloneJson.map((cEl: any, cIdx: any, arr: any) => {
//     let clEl = { ...cEl };
//     agStrArr.forEach((agCol, idx) => {
//       var agEl = cEl[agCol];
//       if (agEl !== undefined) {
//         var agArr = agEl.split(';').map((el: string) => el.trim());
//         var dupSet = getDuplicatesNamesSet(agArr);
//         if (dupSet.has('') || agArr.includes('')) {
//           dupSet.delete('');
//           // flag extra delimiter
//           errorRet = setReportStatus(
//             cIdx,
//             cloneJson,
//             'Potential extra semicolon in ad groups for ' +
//               (idx === 0 ? 'cloning' : 'enabling')
//           );
//         }
//         var msg = 'Duplicate ad group names ';
//         if (idx === 0) {
//           clEl.agCl = agArr;
//           msg += 'for cloning detected';
//         } else {
//           clEl.agEn = agArr;
//           msg += 'for enabling detected';
//         }
//         if (dupSet.size > 0) {
//           errorRet = setReportStatus(cIdx, cloneJson, msg);
//         }
//       }
//     });
//     // compare adgroups to be cloned / enabled if we have both
//     if (clEl.agCl && clEl.agEn) {
//       // look for adgroups to enable, which don't exist in adgroups to clone (error)
//       let compSet = namesExistSet(clEl.agEn, clEl.agCl, false);
//       let compLen = compSet.size;
//       if (compLen > 0) {
//         let msg =
//           Array.from(compSet).join(', ') +
//           (compLen > 1 ? ' are' : ' is') +
//           " missing in AdGroupsToClone and can't be enabled";
//         errorRet = setReportStatus(cIdx, cloneJson, msg);
//       }
//     }
//     // console.log(cEl);
//     return clEl;
//   });
//   // console.log('transform cloneAgJson', cloneAgJson);
//   // console.log('transform cloneJson', cloneJson);
//   return cloneAgJson;
// };

export const namesOk = (
  nameBaseArr: Array<string>,
  nameCompArr: Array<string>
) => {
  return (
    nameBaseArr.filter((el) => {
      return nameCompArr.includes(el);
    }).length === nameBaseArr.length
  );
};

export const namesExistSet = (
  nameBaseArr: Array<string>,
  nameCompArr: Array<string>,
  notOperation: boolean = false // if true, notExists will be tested instead
) => {
  let compSet = new Set(nameCompArr);
  let retSet = new Set(
    // make base unique for comparison
    Array.from(new Set(nameBaseArr)).filter((el: string) => {
      if (!notOperation) return !compSet.has(el);
      else return compSet.has(el);
    })
  );
  // console.log(Array.from(retSet));
  return retSet;
};

export const getDuplicatesSet = (stringArray: Array<string>) => {
  const uniqueItemsSet = new Set();
  const duplicatesSet = new Set();
  for (const value of stringArray) {
    if (uniqueItemsSet.has(value)) {
      duplicatesSet.add(value);
      // memory optimization
      uniqueItemsSet.delete(value);
    } else {
      uniqueItemsSet.add(value);
    }
  }
  return duplicatesSet;
};

// get duplicates in stringArray and check if they exist in compareArray
export const getDuplicatesNamesSet = (
  baseArray: Array<string>,
  compareArray: Array<string> = baseArray
) => {
  var baseDups = getDuplicatesSet(baseArray);
  // console.log('duplicate names ', Array.from(baseDups));
  if (baseDups.size > 0 && baseArray !== compareArray) {
    // only filter if we are comparing different arrays
    return new Set(
      compareArray.filter((el: any) => {
        return baseDups.has(el);
      })
    );
  }
  return baseDups;
};

export const parseUTCDate = (dtEl: number, date1904: boolean): Date => {
  //we have a number
  // XLSX.SSF.parse_date_code(43473.791666666664, {date1904:false})
  //{'D':43473,'T':68399,'u':0,'y':2019,'m':1,'d':8,'H':19,'M':0,'S':0,'q':2}
  /*
    y: year
    m: month
    d: day
    T: number of seconds since midnight.
    H: hours in the 24-hour system
    M: minutes
    S: seconds
    u: fraction of a second (unfortunately needed for some odd formatting issues)
    */
  let parsed = SSF.parse_date_code(dtEl, { date1904 });
  // construct date (month are 0 based)
  return new Date(
    Date.UTC(parsed.y, parsed.m - 1, parsed.d, parsed.H, parsed.M, parsed.S)
  );
};

export const parseESTDate = (dtEl: number, date1904: boolean): Date => {
  //we have a number
  // XLSX.SSF.parse_date_code(43473.791666666664, {date1904:false})
  //{'D':43473,'T':68399,'u':0,'y':2019,'m':1,'d':8,'H':19,'M':0,'S':0,'q':2}
  /*
    y: year
    m: month
    d: day
    T: number of seconds since midnight.
    H: hours in the 24-hour system
    M: minutes
    S: seconds
    u: fraction of a second (unfortunately needed for some odd formatting issues)
    */
  let parsed = SSF.parse_date_code(dtEl, { date1904 });
  // construct date (month are 0 based)
  return new Date(
    parsed.y,
    parsed.m - 1,
    parsed.d,
    parsed.H,
    parsed.M,
    parsed.S
  );
};

// const formatDateConvertUTC = (dtEl: number, date1904: boolean): DateStr => {
//   let mDt = parseDate(dtEl, date1904);
//   let dtStr = mDt.toISOString().replace('Z', '+00:00') as DateStr;
//   // console.log('formatDateUTC date:' + mDt.toLocaleString() + ' to ' + dtStr);
//   return dtStr;
// };
export const formatDateUTC = (dtEl: number, date1904: boolean): DateStr => {
  if (!isNaN(dtEl)) {
    let mDt = parseUTCDate(dtEl, date1904);
    let dtStr = mDt.toISOString();
    dtStr = dtStr.replace('.000', '');
    // console.log('formatDateUTC date:' + mDt.toLocaleString() + ' to ' + dtStr);
    return dtStr as DateStr;
  }
  return '' as DateStr;
};

export const formatDateEST = (
  dtEl: number | string,
  date1904: boolean
): DateStr => {
  if (typeof dtEl === 'number' && !isNaN(dtEl)) {
    let mDt = parseESTDate(dtEl, date1904);
    let dtStr = mDt.toISOString();
    dtStr = dtStr.replace('.000', '');
    // console.log('formatDateUTC date:' + mDt.toLocaleString() + ' to ' + dtStr);
    return dtStr as DateStr;
  } else if (typeof dtEl === 'string' && checkDate(dtEl) === '') {
    let dtStr = dtEl.replace('.000', '');
    // console.log('formatDateUTC date:' + mDt.toLocaleString() + ' to ' + dtStr);
    return dtStr as DateStr;
  }
  return '' as DateStr;
};

export const convertDateUTCtoEST = (dtStrUTC: string): DateStr => {
  // Assume `dateString` is in ISO format (e.g., "2023-11-05T15:00:00Z")
  // console.log('convertDateUTCtoEST: UTC_Date - ' + dtStrUTC);

  // Ensure input format compatibility by replacing any space with "T" and adding "Z" if needed
  dtStrUTC = dtStrUTC.replace(' ', 'T') + (dtStrUTC.endsWith('Z') ? '' : 'Z');

  // Create a Date object from the UTC date string
  var utcDate = new Date(dtStrUTC);
  // console.log('convertDateUTCtoEST: UTC_Date_Object -' + utcDate);
  // Convert to Eastern Time (EST/EDT depending on the date)
  const options: Intl.DateTimeFormatOptions = {
    timeZone: 'America/New_York',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  };

  // Format date to the correct timezone
  const easternTimeStr = utcDate.toLocaleString('en-US', options);

  // Parse the result into components to build ISO string
  var [datePart, timePart] = easternTimeStr.split(', ');
  const [month, day, year] = datePart.split('/');
  if (timePart === '24:00:00') {
    timePart = '00:00:00';
  }
  const isoDate = `${year}-${month}-${day} ${timePart}`;

  // console.log('convertDateUTCtoEST: EST_Date (ISO Format) - ' + isoDate);
  return isoDate as DateStr;
};

export const convertESTtoUTC = (estDateStr: string): DateStr => {
  // console.log('convertESTtoUTC: EST_Date - ' + estDateStr);

  // Parse the input string as a local date (no timezone assumed)
  const localDate = new Date(estDateStr.replace(' ', 'T').replace('Z', ''));

  // Helper function to determine if the date is in DST for "America/New_York"
  // a test using the specific time zone is required, as DST timing is different e.g. for BST and EST
  const isEasternDST = (date: Date): boolean => {
    const nyFormatter = new Intl.DateTimeFormat('en-US', {
      timeZone: 'America/New_York',
      timeZoneName: 'short'
    });
    const timeZoneName = nyFormatter
      .formatToParts(date)
      .find((part) => part.type === 'timeZoneName')?.value;
    return timeZoneName === 'EDT';
  };

  // Calculate UTC time by adjusting based on whether it's DST or not
  const offset = isEasternDST(localDate) ? 4 * 60 : 5 * 60; // 4 hours for EDT, 5 hours for EST
  const utcDate = new Date(localDate.getTime() + offset * 60 * 1000);

  // Format the result in ISO format (UTC)
  const utcIsoString = utcDate.toISOString();
  // console.log('convertESTtoUTC: UTC_Date - ' + utcIsoString);
  return utcIsoString as DateStr;
};

export const formatDates = (
  jsonObj: Array<any>,
  columns: Array<string>,
  date1904: boolean = false
) => {
  columns.forEach((col: any) => {
    jsonObj.forEach((el, idx) => {
      let cEl = el[col];
      // columns could be undefined, which is valid, so only test existing ones
      if (cEl) {
        //el[col] = formatDateConvertUTC(cEl, date1904);
        var dtStr = formatDateEST(cEl, date1904);
        el[col] = convertESTtoUTC(dtStr);
      }
    });
  });
};

export const checkValueExists = (
  jsonObj: Array<any>,
  column: Array<string>,
  name: string
) => {
  // the JSON itself will not have entries for missing values, so we need to check in testArray instead and then apply the message to the JSON
  let checkError = false;
  column.forEach((cEl: any, idx: number) => {
    // console.log('checkValueExists - ' + cEl);
    if (typeof cEl === 'undefined') {
      let msg = name + ' is required';
      checkError = setReportStatus(idx, jsonObj, msg);
    }
  });
  return checkError;
};

export const checkColumns = (
  jsonObj: Array<any>,
  columns: Array<string>,
  date1904: boolean = false
) => {
  let checkError = false;
  columns.forEach((col: any) => {
    let fType = tCFHeader[col];
    jsonObj.forEach((el, idx) => {
      let msg: string = ' is an invalid ';
      let cEl = el[col];
      let mError = false;
      // columns could be undefined, which is valid, so only test existing ones
      if (cEl !== undefined && cEl !== '') {
        switch (fType) {
          case 'date':
            msg += 'date';
            // test if number
            // console.log('testing date: ' + cEl);
            // TODO: dod a similar test for date strings to cover potential copy / paste value scenarios
            if (!isNaN(cEl) && cEl > 0) {
              //we have a number -> parse it as date
              // build ISO date with +00:00 as timezone
              let mDt = parseDate(cEl, date1904);
              let compDate = new Date('2019-12-31');
              if (mDt < compDate) {
                msg = ' should start in 2020 or later';
                console.log('error - past date');
                mError = true;
              }
            } else {
              // console.log('error - wrong date');
              mError = true;
              // error
            }
            break;
          case 'number':
            msg += 'number';
            // test if number
            // console.log('testing number: ' + cEl);
            if (isNaN(cEl)) {
              // console.log('error - wrong number');
              mError = true;
            } else {
              if (cEl < 0) {
                msg = ' needs to be >= 0';
                // console.log('error - not greater 0');
                mError = true;
              }
            }
            break;
          case 'int':
            msg += 'integer';
            // test if number
            // console.log('testing integer: ' + cEl);

            if (isNaN(cEl)) {
              mError = true;
            } else {
              if (cEl <= 0) {
                msg = ' needs to be greater than 0';
                // console.log('error - not greater 0');
                mError = true;
              }
              if (parseFloat(cEl) !== parseInt(cEl)) {
                // console.log('error - wrong int');
                mError = true;
              } else {
                cEl = cEl as Int;
              }
            }
            break;
          case 'string':
            msg += 'non blank string value';
            cEl = (cEl + '').trim();
            if (cEl === '') {
              //  console.log('error - wrong string');
              mError = true;
            }
            break;
          case 'pacing':
            msg += ' needs to be PaceToEndOfFlight,PaceAhead,Off';
            // console.log('testing pacing: ' + cEl);
            if (!/PaceToEndOfFlight|PaceAhead|Off/.test(cEl)) {
              //  console.log('error - wrong pacing');
              mError = true;
            }
            break;
        }
        if (mError) {
          checkError = setReportStatus(idx, jsonObj, col + msg);
        }
      }
    });
  });
  return checkError;
};

export const markError = (
  base: string[],
  lookupSet: Set<string>,
  outputObj: Array<any>,
  message: string,
  ignoreBase?: string[]
): boolean => {
  if (lookupSet.size === 0) return false;
  let found = false;
  var igEl: string, ignoreSet: Set<string>;
  if (ignoreBase) {
    // just build set once
    ignoreSet = getDuplicatesNamesSet(ignoreBase) as Set<string>;
  }
  base.forEach((el, idx) => {
    if (lookupSet.has(el)) {
      if (
        !ignoreBase || // only check ignore Base if required
        ((igEl = ignoreBase[idx]), !ignoreSet.has(igEl))
      )
        found = setReportStatus(idx, outputObj, message);
      // console.log(outputObj);
    }
  });
  return found;
};

export const alertDuplicates = (
  baseArr: Array<string>, // searched for duplicates (could be a different source, e.g names from API)
  outputObj: Array<any>, // outputObject will be a XLSX generated JSON that gets another column and is modified in place
  message: string, // error message
  outputCompareArr: Array<string> = baseArr, // lookup object with names for outputObj, so we can flag the error there
  ignoreBase?: Array<string> // ignore entries which match those to avoid similar errors
) => {
  var dups = getDuplicatesNamesSet(baseArr, outputCompareArr) as Set<string>;
  // console.log(Array.from(dups));
  return markError(outputCompareArr, dups, outputObj, message, ignoreBase);
};

export const setReportStatus = (
  index: number, // index of row in outputObj
  outputObj: Array<any>, // outputObject will be a XLSX generated JSON that gets another column and is modified in place
  message: string // error message
) => {
  var found = false;
  if (outputObj && outputObj[index]) {
    let outEl = outputObj[index][outputColumn];
    // append message if we already have one
    if (outEl) {
      if (message) {
        if (!message.endsWith('.')) {
          outEl += ';';
        }
        outEl += '\x0d\n' + message;
      }
      outputObj[index][outputColumn] = outEl;
    } else {
      outputObj[index][outputColumn] = message;
    }
    found = true;
  }
  return found;
};

export const getColumn = (columLetter: string, obj: Object) => {
  let rExp = new RegExp(columLetter + '[a-z]*([2-9]{1}|[0-9]{2,})', 'i');
  var oArr = Object.keys(obj);
  let retArr = oArr.filter((el) => rExp.test(el));
  return retArr;
};
export const getRow = (rowNumber: string, obj: Object) => {
  var l = rowNumber.length;
  let rExp = new RegExp('([a-z]+)' + rowNumber + '{' + l + '}$', 'i');
  var oArr = Object.keys(obj);
  let retArr = oArr.filter((el) => rExp.test(el));
  return retArr;
};

/**
 * generates an Array of count columns following startCol
 * and requires startCol is a single letter
 */
export const genCol = (count, startCol) => {
  var retArr: string[] = [];
  if (startCol.length !== 1) {
    // safety - return [] on error
    return retArr;
  }
  var baseCode = startCol.charCodeAt(0);
  var baseVal = baseCode - 65;
  for (let i = 0; i < count; i++) {
    var curVal = baseVal + i + 1,
      prefix = Math.floor(curVal / 26),
      endChar = curVal % 26;
    var str = '';
    if (prefix) {
      str = String.fromCharCode(prefix + 64);
    }
    str += String.fromCharCode(endChar + 65);
    retArr.push(str);
  }
  return retArr;
};

// const writeExcel = (
//   cloneJson: Array<any>,
//   // creativeJson?: Array<any>,
//   filterErrors: boolean = false
// ) => {
//   var hasAdvertiser = false,
//     hasAutoOpt = false;
//   if (cloneJson) {
//     if (filterErrors) {
//       if (outputColumn === 'Status Report') {
//         cloneJson = cloneJson.filter((el: any) =>
//           /Failed/.test(el[outputColumn])
//         );
//       } else {
//         cloneJson = cloneJson.filter(
//           (el: any) => el[outputColumn] !== undefined
//         );
//       }
//     }
//     // var advIncluded = false;
//     // filter TargetAdvertiserId if we have it
//     cloneJson = cloneJson.map((el: any, idx) => {
//       var elKeys = Object.keys(el);
//       var retEl = {};
//       elKeys.forEach((key: String) => {
//         if (key !== 'TargetAdvertiserId') {
//           //@ts-ignore
//           retEl[key] = el[key];
//         }
//       });
//       // if (!advIncluded) {
//       //   if (
//       //     elKeys.includes('Target Advertiser') &&
//       //     !xlCloneHeader.includes('Target Advertiser')
//       //   ) {
//       //     // insert 'Target Advertiser' as 2nd element
//       //     xlCloneHeader.splice(1, 0, 'Target Advertiser');
//       //     hasAdvertiser = true;
//       //     advIncluded = true;
//       //   }
//       // }
//       return retEl;
//     });
//     if (xlCloneHeader.includes('Target Advertiser')) {
//       hasAdvertiser = true;
//     }
//     if (xlCloneHeader.includes('KeepAdGroupAutoOptimization')) {
//       hasAutoOpt = true;
//     }
//     var filename = 'processed_template.xlsx',
//       title = 'Processed Upload';
//     // create new workbook
//     var wb = XLSX.utils.book_new();
//     if (!wb.Props) wb.Props = {};
//     wb.Props.Title = title;
//     var ws = XLSX.utils.json_to_sheet(cloneJson, {
//       dateNF: 22,
//       header: xlCloneHeader.concat(
//         outputColumn === 'Status Report' ? ['Campaign Link'] : [],
//         [outputColumn]
//       )
//     });
//     /* add worksheet to workbook */
//     XLSX.utils.book_append_sheet(wb, ws, 'CampaignsToClone');
//     var wscols;
//     let textStyle = {
//       font: { name: 'Arial', sz: 10 },
//       alignment: { wrapText: true, vertical: 'center', horizontal: 'center' }
//     };
//     if (hasAutoOpt) {
//       //set custom Date format to show date and time
//       ['D', 'E'].forEach((col) => {
//         getColumn(col, ws).forEach((el) => {
//           ws[el].z = 'yyyy-mm-dd hh:mm:ss';
//         });
//       });
//       ['N', 'O'].forEach((col) => {
//         getColumn(col, ws).forEach((el) => {
//           ws[el].s = textStyle;
//         });
//       });
//       // hyperlinks for cloned campaigns
//       ['M'].forEach((col) => {
//         getColumn(col, ws).forEach((el) => {
//           ws[el].l = { Target: ws[el].v, Tooltip: ws[el].v };
//         });
//       });
//       wscols = [
//         { wch: 30 },
//         { wch: 30 },
//         { wch: 30 },
//         { wch: 21 },
//         { wch: 21 },
//         { wch: 9.5 },
//         { wch: 9.5 },
//         { wch: 9.5 },
//         { wch: 9.5 },
//         { wch: 21 },
//         { wch: 8 },
//         { wch: 21 },
//         { wch: 21 },
//         { wch: 45 },
//         { wch: 45 }
//       ];
//     }
//     if (hasAdvertiser) {
//       //set custom Date format to show date and time
//       ['D', 'E'].forEach((col) => {
//         getColumn(col, ws).forEach((el) => {
//           ws[el].z = 'yyyy-mm-dd hh:mm:ss';
//         });
//       });
//       ['M', 'N'].forEach((col) => {
//         getColumn(col, ws).forEach((el) => {
//           ws[el].s = textStyle;
//         });
//       });
//       // hyperlinks for cloned campaigns
//       ['L'].forEach((col) => {
//         getColumn(col, ws).forEach((el) => {
//           ws[el].l = { Target: ws[el].v, Tooltip: ws[el].v };
//         });
//       });
//       wscols = [
//         { wch: 30 },
//         { wch: 30 },
//         { wch: 30 },
//         { wch: 21 },
//         { wch: 21 },
//         { wch: 9.5 },
//         { wch: 9.5 },
//         { wch: 9.5 },
//         { wch: 9.5 },
//         { wch: 21 },
//         { wch: 8 },
//         { wch: 21 },
//         { wch: 45 },
//         { wch: 45 }
//       ];
//     } else {
//       //set custom Date format to show date and time
//       ['C', 'D'].forEach((col) => {
//         getColumn(col, ws).forEach((el) => {
//           ws[el].z = 'yyyy-mm-dd hh:mm:ss AM/PM';
//         });
//       });
//       ['L', 'M'].forEach((col) => {
//         getColumn(col, ws).forEach((el) => {
//           ws[el].s = textStyle;
//         });
//       });
//       // hyperlinks for cloned campaigns
//       ['K'].forEach((col) => {
//         getColumn(col, ws).forEach((el) => {
//           ws[el].l = { Target: ws[el].v, Tooltip: ws[el].v };
//         });
//       });
//       wscols = [
//         { wch: 30 },
//         { wch: 30 },
//         { wch: 21 },
//         { wch: 21 },
//         { wch: 9.5 },
//         { wch: 9.5 },
//         { wch: 9.5 },
//         { wch: 9.5 },
//         { wch: 21 },
//         { wch: 8 },
//         { wch: 21 },
//         { wch: 45 },
//         { wch: 45 }
//       ];
//     }

//     ws['!cols'] = wscols;
//     let wsRows = [{ hpx: 15.75 }].concat(
//       cloneJson.map((el: any) => {
//         let rowHeight = 15.75;
//         let msg = el[outputColumn] as string;
//         if (msg)
//           if (msg.includes(';')) {
//             rowHeight = 64;
//           } else {
//             rowHeight = 32;
//           }
//         return { hpx: rowHeight };
//       })
//     );
//     // debugger;
//     ws['!rows'] = wsRows;
//     // if (creativeJson) {
//     //   var ws1 = XLSX.utils.json_to_sheet(creativeJson, {
//     //     header: xlAssignHeader
//     //   });
//     //   /* add worksheet to workbook */
//     //   XLSX.utils.book_append_sheet(wb, ws1, 'CreativeAssignment');
//     // }
//     XLSX.writeFile(wb, filename, {
//       bookType: 'xlsx',
//       type: 'file',
//       cellDates: false,
//       cellStyles: true
//     });
//   }
// };

/**
 * returns array of unexpected headers in Order or []
 * @param aExpectedHeaders - array of headers to expect
 * @param aAllHeaders - array of headers to check
 */
const getExtraHeaders = (
  aExpectedHeaders: Array<string>,
  aAllHeaders: Array<string>
) => {
  var extraHeaders = [];
  if (
    aExpectedHeaders &&
    aExpectedHeaders.length > 0 &&
    aAllHeaders &&
    aAllHeaders.length > 0
  ) {
    var idx = 0;
    extraHeaders = aAllHeaders.reduce((acc, el) => {
      if (el && !(aExpectedHeaders.indexOf(el) > -1)) {
        //@ts-ignore
        acc[idx] = el;
        idx++;
      }
      return acc;
    }, []);
  }
  return extraHeaders;
};

/**
 * verifies header
 * @param sArr - array of required headers
 * @param ws - worksheet object to analyze
 */
export const verifyHeader = (sArr: Array<string>, ws: Object) => {
  var rowArr = getRow('1', ws);
  var hArr = rowArr.map((el, idx) => {
    //@ts-ignore
    return ws[el].v;
  });
  var ok = namesOk(sArr, hArr);
  var extraHeaders = getExtraHeaders(sArr, hArr);
  return { ok, extraHeaders };
};

export const verifyTemplateHeader = (ws: Object, sheetName: string) => {
  if (sheetName && ws) {
    var headerArray = templateHeaders[sheetName];
    if (headerArray) {
      return verifyHeader(headerArray, ws);
    }
  }
  return { ok: false, extraHeaders: undefined };
};

/**
 * pushes message from errorArr into correct position in verification errors
 * @param errorArr - array of error messages to process
 * @param verificationErrors - stores all error messages
 * @param startIdx - index to calculate offset from
 * @param errIdx - error offset from startIdx
 */
const uErrorHandler = (
  errorArr: string[] | string,
  verificationErrors: errorEntry[],
  startIdx: number,
  strIdx: number,
  bRepeat: boolean = false
) => {
  const assignIdxVal = (bIdx, val) => {
    var errEl = verificationErrors[bIdx];
    if (!errEl) {
      errEl = [];
      verificationErrors[bIdx] = errEl;
    }
    if (Array.isArray(val)) {
      val.forEach((el) => {
        errEl.push(el);
      });
    } else {
      errEl.push(val);
    }
  };

  if (typeof errorArr === 'string') {
    // assignIdxVal(strIdx, errorArr);
    var bEndIdx = strIdx;
    if (bRepeat) {
      bEndIdx = startIdx;
    }
    for (var i = strIdx; i >= bEndIdx; i--) {
      assignIdxVal(i, errorArr);
    }
  } else {
    errorArr.forEach((err: string | string[], errIdx: number) => {
      assignIdxVal(startIdx + errIdx, err);
    });
  }
};

/**
 * combines errors for each row
 * @returns boolean - true if we have any error entry
 */
const combineVerificationErrors = (verificationErrors: errorEntry[]) => {
  return verificationErrors.map((elArr: string[]) => {
    return elArr.join(';\x0d\n');
  });
};

/**
 * verifies matching AdvertiserId for 2 package entries
 * @param pkgO - package entry
 * @param pkgComp  - next package entry
 * @param errFunc
 * @returns
 */
const checkAdvertiserIds = (
  pkgO: IPackage,
  pkgC: IPackage,
  errFunc: Function
) => {
  var advId = trimStr(pkgO.AdvertiserId),
    ok = true;
  if (pkgC) {
    var cAdvId = trimStr(pkgC.AdvertiserId);
    if (advId && cAdvId && advId !== cAdvId) {
      errFunc('Only 1 AdvertiserId per package is supported');
      ok = false;
    }
  }
  return ok;
};

/**
 * checks advId is in advList if advID is a valid string
 * @param advId - advertiser id to check
 * @param advList - list of advertiser Ids
 */
const checkNewAdvertiserId = (
  advId: string | undefined,
  advList: string[],
  errFunc: Function
) => {
  var sAdv = trimStr(advId),
    advIdx;
  if (sAdv) {
    advIdx = advList.indexOf(sAdv);
    if (advIdx === -1) {
      errFunc('AdvertiserId is invalid or not accessible');
    }
    return advIdx !== -1;
  }
  return true;
};

// ttdCampNames needs to contain the names for campaigns to be created with a count + a dummy lookup id (which needs to be updated after campaign creation)
export const determinePackageOperations = (
  pkgJson: IPackage[],
  ttdPkgData: IIdxPackageObject,
  ttdCampData: ITtdCampaignObject,
  ttdCampNames: ICampNameRegIdx,
  ttdAdvIds: string[]
) => {
  var pkgUpdate: IIdxOpPackage = {},
    pkgCreate: IIdxOpPackage = {};

  var verificationErrors: errorEntry[] = [];
  var pkgStart = 0,
    oPkg: IOpPackage,
    pIdx = 0;

  /**
   * pushes message from errorArr into correct position in verification errors
   * @param errorArr - stringArray of error(s)
   *@param - endIdx - optional end index for repeated messages
   */
  const pkgErrHandler = (errorArr: string[] | string, bRepeat?: boolean) => {
    uErrorHandler(errorArr, verificationErrors, pkgStart, pIdx, bRepeat);
  };

  /**
   * registers a new package and verifies consistent advertiser id
   * @param index - package id (op = u) or name (op = c)
   * @param pkg - package entry
   * @param op - c for create, u for update
   * @returns - previous campaign name if index already exists
   */
  const registerPackage = (index: string, op: 'c' | 'u') => {
    var pkgList: IIdxOpPackage;
    if (op === 'u') {
      pkgList = pkgUpdate;
    } else {
      pkgList = pkgCreate;
    }

    // check if we already have the package in our list
    let cRef = pkgList[index];
    if (cRef) {
      oPkg = cRef;
      return cRef.PackageName || '__undefined__';
    } else {
      // update campaign list to include new package
      pkgList[index] = oPkg;
      oPkg.op = op;
      return '';
    }
  };

  /**
   * Verifies Package Id is known and keeps the same advertiser id
   * @param pkg - package  row object
   * @param idx - package entry row
   * @param errFunc - errorHandler
   * @returns packageid or -idx-1 if errors are encountered
   */
  // prettier-ignore
  const checkPackageIdAdvertiserId = (pkg:IPackage, idx: number, errFunc:Function ) => {
    var ttdPackage : IIdxPackage | undefined,
     pkgId = trimStr(pkg.PackageId);
    if(pkgId) {
      // check if package id is known
      ttdPackage= ttdPkgData[pkgId]
    }
    if (!ttdPackage) {
      // prettier-ignore
      errFunc(['Provided PackageId does not exist']);
      pkgId = -idx-1;
    }else {
      var advId =pkg.AdvertiserId;
      if(advId && advId !== ttdPackage.AdvertiserId){
        errFunc(['Updating the AdvertiserId for a package is not supported. Please create a new package.'])
        pkgId = -idx-1;
      }
    }
    return pkgId;
  };
  /**
   * Verifies Campaign Id is known
   * @param pkg - package  row object
   * @param errFunc - errorHandler
   * @returns campaignid or '' if not know
   */
  // prettier-ignore
  const checkCampaignId = (campId: string,   errFunc:Function ) => {
    var ttdCampaign : ITtdCampaign | undefined,
     campaignId = campId;
    if(campaignId) {
      // check if campain id is known
      ttdCampaign= ttdCampData[campaignId]
    }
    if (!ttdCampaign) {
      // prettier-ignore
      errFunc(['Provided CampaignId does not exist']);
      campaignId = '';
    }
    return campaignId;
  };

  /**
   * looks up campaignId by campaignname
   * @param pkg - package  row object
   * @param errFunc - errorHandler
   * @returns campaignid or '' if lookup failed
   */
  const lookupIdByCampaignName = (
    cmpName: string | undefined,
    errFunc: Function
  ) => {
    var campaignId: string = '';
    // campaign name must be known to do a lookup
    var campName = trimStr(cmpName);
    if (!campName) {
      errFunc(['CampaignName must be provided']);
    } else {
      var campEntry = ttdCampNames[campName];
      if (!campEntry) {
        errFunc(['Campaign must be created first via Package_Campaigns']);
      } else {
        if (campEntry.count > 1) {
          errFunc([
            'CampaignName is not unique - CampaignId must be used for assignment'
          ]);
        } else {
          campaignId = campEntry.id;
        }
      }
    }
    return campaignId;
  };

  const registerCampaignMemberId = (pkg: IPackage, errFunc: Function) => {
    /**
     * registers id in campaigns + updates membership if bUpdateMembers is true
     */
    const registerCampaign = (id: string, bNoPush = false) => {
      if (id) {
        var camp = oPkg.Campaigns[id];
        if (!camp) {
          oPkg.Campaigns[id] = id;
          if (!bNoPush) {
            // not a new campaign
            oPkg.Members.push((id as unknown) as IIdxCamp);
          }
        }
      }
    };
    var campId = trimStr(pkg.CampaignId);
    if (campId) {
      campId = checkCampaignId(campId, errFunc);
      registerCampaign(campId);
    } else {
      campId = lookupIdByCampaignName(pkg.CampaignName, errFunc);
      if (campId) {
        if (campId.indexOf('n_') === -1) {
          registerCampaign(campId);
        } else {
          registerCampaign(pkg.CampaignName as string, true);
        }
      }
    }
    return campId;
  };

  pkgJson.forEach((pkg, idx) => {
    var pkgId = trimStr(pkg.PackageId),
      advOk = true;
    //@ts-ignore
    oPkg = { Campaigns: {}, Members: [] };
    pIdx = idx;
    var pkgName;
    if (pkgId) {
      // packageUpdate
      pkgName = registerPackage(pkgId, 'u');
      pkgStart = idx;
      if (pkgName) {
        if (pkgName !== pkg.PackageName) {
          pkgErrHandler([
            'PackageName needs to be consistent for package name updates '
          ]);
        }
      }
      // check package id is valid + advertiser id is consistent
      // @prettier-ignore
      oPkg.PackageId = checkPackageIdAdvertiserId(pkg, idx, pkgErrHandler);
    } else {
      pkgName = trimStr(pkg.PackageName);
      //package create
      pkgName = registerPackage(pkgName, 'c');
      pkgStart = idx;

      if (!pkgName) {
        // check advertiser id is accessible
        // @prettier-ignore
        advOk = checkNewAdvertiserId(
          pkg.AdvertiserId,
          ttdAdvIds,
          pkgErrHandler
        );
      } else {
        // check advertiser id is consistent
        advOk = checkAdvertiserIds(oPkg, pkg, pkgErrHandler);
      }
    }
    // registers campaign member
    registerCampaignMemberId(pkg, pkgErrHandler);
    // verify advertiser id, verify package name
    // prettier-ignore
    let  obj = checkObjColumns(pkg, ['AdvertiserId', 'PackageName' ], false, pkgErrHandler);
    Object.assign(oPkg, obj);
    if (!advOk) {
      // set advertiser id to invalid if we had a problem earlier
      oPkg.AdvertiserId = '__invalid__';
    }
    oPkg.rowIdx = idx;
  });
  var combErrors = combineVerificationErrors(verificationErrors);
  var fErrors = combErrors.filter((el) => el);
  var pkgError = fErrors.length > 0;
  return { pkgError, pkgVerifyErrors: combErrors, pkgUpdate, pkgCreate };
};

/**
 * identifies campaign update / create, flight update / create + data errors for
 * package campaigns
 * @param campJson
 * @param ttdCampData
 * @param date1904
 * @returns campError - true for verification errors ,
 *  verificationErrors, array of verification errors
 * flightCreate, flightUpdate, - flight operations
 * campCreate, campUpdate - campaign operations
 */
// prettier-ignore
export const determineCampaignAndFlightOperations = (campJson: ICampaign[], ttdCampData: ITtdCampaignObject, campNameIdx:ICampNameRegIdx, date1904: boolean) => {
  // prettier-ignore
  var flightUpdate: CampaignFlight[] = [], flightCreate: CampaignFlight[] = [], campCreate: ITtdCampaignObject = {},  campUpdate: ITtdCampaignObject = {}, agBudgetUpdateCamps: Array<string> = [];

  // holds error messages
  var verificationErrors: errorEntry[] = [];
  // prettier-ignore
  var campStart = 0, flIdx: number = 0,
      oCamp: ITtdCampaign,
      campComp: ICampaign,
      bCheckAllCampaignRows = false,
      bCampaignNameDuplicates = false,
      cIdx = 0;
  /**
   * pushes message from errorArr into correct position in verification errors
   * @param errorArr - stringArray of error(s)
   * *@param - endIdx - optional end index for repeated messages
   */
  const campErrHandler = (errorArr: string[]|string, bRepeat?:boolean) => {
    uErrorHandler(errorArr, verificationErrors, campStart, cIdx, bRepeat);
  };

  /**
   * registers a new campaign id for updating or returns it's lookup entry
   * @param index - campaign id (op = u) or campaign name (op = c)
   * @param op - c for create, u for update
   * @returns - previous campaign name if index already exists
   */
  const registerCampaign = (index:string, op:'c'|'u') => {
    var campList:ITtdCampaignObject;
    if(op==='u'){
      campList = campUpdate;
    }else {
      campList = campCreate;
    }
    // check if we already have the campaign in our list
    let cRef =campList[index];
    if (cRef) {
      oCamp = cRef;
      return cRef.CampaignName || '__undefined__'
    }else {
      // update campaign list to include new campaign
      campList[index] = oCamp;
      oCamp.op = op;
      return ''
    }
  }
  /**
   * registers flight update vs. creation after verifying data
   * @param camp - package campaign row object
   * @param oCamp - current campaign object
   * @param bUpdate - true for update, false for create
   * @param flightIdx - number as alternative campaign
   *                    flight index
   * @param errFunc - error handler function
   */
  // prettier-ignore
  const registerFlightDataOp = (camp:ICampaign, oCamp:ITtdCampaign, bUpdate:boolean, flightIdx:number, date1904:boolean, errFunc:Function ) => {
    var  obj  = verifyFlightData(camp, bUpdate, date1904, errFunc);
    // for valid flights we have no message in msg
    var flightId = flightIdx,
    op:'u'|'c' = 'c', pushOk = true;
    if(bUpdate){
      op = 'u';
      flightId =obj.CampaignFlightId as number;
    }
    if (oCamp.cfl && flightId>0 ) {
      // flightId === 0 marks a new flight for an existing campaign and we deal with it by creating new campaign flights instead
        if(oCamp.cfl  && oCamp.cfl[flightId]){
          errFunc(['CampaignFlightIds must only be updated once'])
          pushOk = false;
        }
        oCamp.cfl[flightId] = obj;
    }
    if(flightIdx > 0){
      // ensure we set no flight id for campaign flights in new campaigns
      obj.CampaignFlightId = undefined
    }else {
      obj.op = op;
      if(!obj.CampaignFlightId){
        obj.CampaignFlightId = 0 as Int
      }
    }
    // for Kokai bulk ops we need the advertiser id even for campaign flights
    if(oCamp.AdvertiserId && oCamp.Version && oCamp.Version === 'Kokai' ){
      obj.AdvertiserId = oCamp.AdvertiserId;
    }
    if(pushOk){
      oCamp.CampaignFlights.push(obj);
      if(flightIdx === 0){
        // remember row
        obj.rowIdx = cIdx;
        // only for existing campaigns we need flight updates
        if(op === 'u'){
          flightUpdate.push(obj);
        }else {
          flightCreate.push(obj)
        }
      }
    }
  }

  /**
   * Verifies data for Campaign Flight updates / creations and sets the flight operation to 'c' / 'u'
   * @param camp - package campaign row object
   * @param ttdCampaign - ttd API campaign object
   * @returns true for update, false for create
   */
  // prettier-ignore
  const verifyCampaignFlightCreateUpdate = (camp: ICampaign,  ttdCampaign: ITtdCampaign | undefined, errFunc:Function ) => {
    var campFlightId = camp.CampaignFlightId;
    if (campFlightId && ttdCampaign) {
      var tCFtObj = ttdCampaign.cfl || {};
      var tCampFlight = tCFtObj[campFlightId];
      // check if Campaign Flight Id is valid
      if (!tCampFlight) {
        // prettier-ignore
        errFunc(['Provided CampaignFlightId does not exist for provided CampaignId']);
      } else {
        //  Campaign Flight Id valid ->  check for modifications
        // TODO: - skip all old flights
        var bUpdate = isFlightModified(tCampFlight, camp, date1904);
        if (bUpdate) {
          // ToDo - skip all old flights due to API changes not allowing modifications
          registerFlightDataOp(camp, oCamp, bUpdate, 0, date1904, errFunc);
        }
      }
    } else {
      // flightgets created with new campaign - verify only
      // verifyFlightData(camp, false, date1904, errFunc);
      registerFlightDataOp(camp, oCamp,false, flIdx, date1904,errFunc);
    }
  };

  const verifyAdgroupBudgetUpdate = (camp:ICampaign, oCamp:ITtdCampaign, errFunc:Function ) => {
    var agStr = trimStr(camp.UpdateAdGroupBudgets), agUpdateOld = oCamp.UpdateAdGroupBudgets, testRe = /^yes$|^no$|^$/i,testReYes = /^yes$/i, agUpdateOk = testRe.test(agStr),
    agUpdateVal = testReYes.test(agStr);
    if(agStr !== '' && agStr !== undefined && !agUpdateOk){
      errFunc('UpdateAdGroupBudgets must be "yes", "no" or empty')
      return oCamp
    }
    // for Kokai, we rely on fluid budgets here, as this is the scenario Nissan uses
    // and in such case automatic (value) budget adjustment is supported either with or without manual priorities
    if(!oCamp.Version){
      // Solimar handling
      if(agUpdateOld === undefined){
        oCamp.UpdateAdGroupBudgets = agUpdateVal;
        if(agUpdateVal){
          // updates only make sense for existing campaigns as new campaigns we create instead of cloning them don't have any ad groups assigned to them yet
          var cId = trimStr(camp.CampaignId);
          if(cId && !agBudgetUpdateCamps.includes(cId)){
            agBudgetUpdateCamps.push(cId)
          }
        }
      }else if(agUpdateOld !== agUpdateVal){
        oCamp.budgetUpdateError = true;
      }
    }
    return oCamp;
  }

  campJson.forEach((camp: ICampaign, idx) => {
    var campId = trimStr(camp.CampaignId);
    // @ts-ignore
    oCamp = { cfl: {}, CampaignFlights: [] };
    campComp= campJson[idx+1];
    // only check flight dates once we collected all flights for the current campaign
    bCheckAllCampaignRows = false;
    //
    bCampaignNameDuplicates = false;
    cIdx = idx;
    var ttdCampaign;
  // an existing campaign id means we might need to update
    if (campId) {
      bCheckAllCampaignRows = shouldCheckAllCampaignRows(camp, campComp);
      // prettier-ignore
      let campName = registerCampaign(campId, 'u')
      if (!campName) {
        // remember row where new campaign starts
        campStart = idx;
        flIdx = 0;
      }
      if (campStart !== idx && campName !== camp.CampaignName) {
        campErrHandler(['CampaignName must be consistent for campaign name updates']);
      }
      //  campaign id should be in our list
      ttdCampaign = ttdCampData[campId];
      if (ttdCampaign) {
        oCamp.CampaignId = campId;
        verifyAdvertiserId(camp, ttdCampData, campErrHandler);
        oCamp.nameUpdate = checkCampaignNameUpdate(camp, ttdCampData);
        oCamp.pacingUpdate = checkCampaignPacingUpdate(camp, ttdCampData);
        oCamp.tzUpdate = checkCampaignTzUpdate(camp, ttdCampData);
        // TODO: add extra props into Excel template after discussion
        campaignCopyColumns.forEach(key => {
          if(ttdCampaign[key]){
            oCamp[key] = ttdCampaign[key]
          }
        })
      } else {
        campErrHandler(['Provided CampaignId does not exist']);
      }
      oCamp = verifyAdgroupBudgetUpdate(camp, oCamp, campErrHandler);
    } else {
      let cName = trimStr(camp.CampaignName);
      let advId = trimStr(camp.AdvertiserId);
      if (filter_advertiser_ids.includes(advId)) {
        campErrHandler(['Provided AdvertiserId does not exist']);
      }
      let campName = registerCampaign(cName, 'c')
      let ttdCampaign = campNameIdx[cName];
      if (!campName) {
        // remember row where new campaign starts
        campStart = idx;
        flIdx = 0;
      }
      bCheckAllCampaignRows = shouldCheckAllCampaignRows(camp, campComp);
      if(ttdCampaign) {
        bCampaignNameDuplicates = true;
      }
      else{ // register new campaign name
        if(bCheckAllCampaignRows){
          campNameIdx[cName] =  { id:'n_'+idx, count: 1 };
        }
      }
      flIdx++;
    }
    // prettier-ignore
    let  obj = checkObjColumns(camp, ['AdvertiserId', 'CampaignName', 'PacingMode', 'TimeZone'], date1904, campErrHandler);
    oCamp.rowIdx = idx;
    Object.assign(oCamp, obj);

    // test if Campaign flight needs to be updated or created
    // we need to do that after assigning PacingMode
    // prettier-ignore
    verifyCampaignFlightCreateUpdate(camp, ttdCampaign, campErrHandler);

    if (bCheckAllCampaignRows) {
      // ensure dates don't overlap and there's at max 1 flight without end date
      let cf = checkFlightDateRanges(oCamp.CampaignFlights, campErrHandler);
      // verify budget settings + end date if pacing !== off
      checkFlightDatesBudget(
        oCamp.CampaignFlights,
        oCamp.PacingMode,
        date1904,
        campErrHandler
      );
      // formatDates will also take care of the time zone adjustment from EST -> UTC for later API usage below
      formatDates(
        cf,
        ['StartDateInclusiveUTC', 'EndDateExclusiveUTC'],
        date1904
      );
      oCamp.CampaignFlights = cf;
      if(bCampaignNameDuplicates){
        campErrHandler('Duplicate CampaignName - please make it unique e.g. by adding a number', true)
      }
      if(oCamp.budgetUpdateError){
        campErrHandler('UpdateAdGroupBudgets must be consistent across campaign flights', true)
      }
      // }
    }

    // var vError = verificationErrors[idx],  errMsg = '';
    // if(vError ){
    //   errMsg = vError.join(';')
    // }
    // console.log(JSON.stringify(camp) + '\n' + errMsg);
  });

  var combErrors = combineVerificationErrors(verificationErrors);
  var fErrors =combErrors.filter( el => el)
  var campError = fErrors.length > 0;
return {campError, campVerifyErrors: combErrors, flightCreate, flightUpdate, campCreate, campUpdate, agBudgetUpdateCamps}

};

/**
 * verifies adgroups in agStr exist in campDataObj and are not containing adgroups with non-unique names; clCampObj is updates with valid adgroups and adgroup names / count; errHandler is called for errors
 */
export const verifyAdGroups = (
  agStr: string | undefined,
  clCampObj: ITtdCloneCampaign,
  campDataObj: ITtdCampaign,
  errHandler: Function
) => {
  const verifyAgName = (name: string) => {
    var agIdx = (campDataObj && campDataObj.agNames) || {},
      agDupl = (campDataObj && campDataObj.agDupl) || [];
    var agId = agIdx[name],
      nameDuplicate = agDupl.includes(name);
    return { agId, nameDuplicate };
  };

  var useAllAdgroups = false,
    gotError = false,
    agArr: string[] = [];
  if (!agStr) {
    // if empty we use all adgroups
    useAllAdgroups = true;
  } else {
    // all is an alternative for using all adgroups
    useAllAdgroups = /^all$|^$/i.test(agStr);
    if (!useAllAdgroups) {
      // otherwise we separate adgroups by ";"
      agArr = agStr.split(';').map((el) => trimStr(el));
    }
  }
  if (useAllAdgroups && campDataObj) {
    // copy adgroup related properties from campaign
    Object.assign(
      clCampObj,
      ...['agIds', 'agNames', 'agCount'].map((k) => ({
        [k]: campDataObj[k]
      }))
    );
  } else {
    var agIds = {},
      agNames = {},
      count = 0;
    // verify each ad group name exists and is not a duplicate
    agArr.forEach((ag) => {
      var { agId, nameDuplicate } = verifyAgName(ag);
      if (agId && !nameDuplicate) {
        agIds[agId] = ag;
        agNames[ag] = agId;
        count++;
      } else {
        gotError = true;
        if (nameDuplicate) {
          errHandler(
            'Duplicate Adgroup name ' +
              ag +
              ' not supported for adgroup selection. Please rename the adgroup to make it unique first.'
          );
        } else {
          errHandler(
            'Adgroup "' + ag + '" does not exist in the base campaign.'
          );
        }
      }
    });
    clCampObj.agIds = agIds;
    clCampObj.agNames = agNames;
    clCampObj.agCount = count;
  }
  return !gotError;
};

export const determineCampaignsToClone = (
  campJson: ICloneCampaign[],
  ttdCampData: ITtdCampaignObject,
  campNameIdx: ICampNameRegIdx,
  date1904: boolean
) => {
  // prettier-ignore
  var flightCreate: CampaignFlight[] = [],    campCreate: ITtdCloneCampaignObject = {};

  // holds error messages
  var verificationErrors: errorEntry[] = [];
  // prettier-ignore
  var campStart = 0, flIdx: number = 0,
      oCamp: ITtdCloneCampaign,
      campComp: ICloneCampaign,
      bCheckAllCampaignRows = false,
      bCampaignNameDuplicates = false,
      cIdx = 0;

  /**
   * pushes message from errorArr into correct position in verification errors
   * @param errorArr - stringArray of error(s)
   * @param repeat - optional for repeated messages
   */
  const campErrHandler = (errorArr: string[] | string, bRepeat?: boolean) => {
    uErrorHandler(errorArr, verificationErrors, campStart, cIdx, bRepeat);
  };

  /**
   * checks if either field1 or field2 is defined in camp and calls errHandler if both are missing
   * @param camp - object to check
   * @param field1 - 1st field name
   * @param field2 - 2nd field name
   * @param errHandler - error handler
   */
  const checkFieldAlternatives = (
    camp: ICloneCampaign | ICampaign,
    field1: string,
    field2: string,
    errHandler: Function
  ) => {
    if (camp) {
      let f1 = camp[field1],
        f2 = camp[field2];
      if ((f1 === undefined || f1 === '') && (f2 === undefined || f2 === '')) {
        errHandler('Either ' + field1 + ' or ' + field2 + ' must be provided');
      }
    }
  };
  /**
   * compare adgroups to clone
   * @param ag1
   * @param ag2
   * @returns
   */
  const cloneAgIsDifferent = (ag1, ag2) => {
    var allRe = /^all$|^$/i,
      allAg1 = allRe.test(trimStr(ag1)),
      allAg2 = allRe.test(trimStr(ag2));
    if (allAg1 && allAg2) {
      return false;
    }
    // use simple verification for non-All adgroup set
    return ag1 !== ag2;
  };
  /**
   * registers a new campaign id for updating or returns it's lookup entry and verifies use of same baseCampaign and adgroups for cloning
   * @param index - campaign id (op = u) or campaign name (op = c)
   * @returns - previous campaign name if index already exists
   */
  const registerCampaign = (index: string) => {
    var campList: ITtdCloneCampaignObject;
    campList = campCreate;
    // check if we already have the campaign in our list
    let cRef = campList[index];
    if (cRef) {
      // verify BaseCampaigns
      if (cRef.BaseCampaignId !== oCamp.BaseCampaignId && cRef.baseCampIdArr) {
        cRef.baseCampIdArr.push(oCamp.BaseCampaignId as string);
      }
      // simple verification of same adgroup set
      if (
        cloneAgIsDifferent(cRef.AdGroupsToClone, oCamp.AdGroupsToClone) &&
        cRef.agCloneArr
      ) {
        // add mismatch to agCloneArr
        cRef.agCloneArr.push(trimStr(cRef.AdGroupsToClone));
      }
      oCamp = cRef;
      return cRef.TargetCampaignName || '__undefined__';
    } else {
      // update campaign list to include new campaign
      if (index) {
        campList[index] = oCamp;
        oCamp.baseCampIdArr = [];
        oCamp.baseCampIdArr.push(trimStr(oCamp.BaseCampaignId));
        oCamp.agCloneArr = [];
        oCamp.agCloneArr.push(trimStr(oCamp.AdGroupsToClone || ''));
      }
      return '';
    }
  };
  /**
   * registers flight update vs. creation after verifying data
   * @param camp - package campaign row object
   * @param oCamp - current campaign object
   * @param flightIdx - number as alternative campaign
   *                    flight index
   * @param errFunc - error handler function
   */
  // prettier-ignore
  const registerFlightDataOp = (camp:ICloneCampaign, oCamp:ITtdCloneCampaign, flightIdx:number, date1904:boolean, errFunc:Function ) => {
    var  obj  = verifyFlightData(camp, false, date1904, errFunc);
      oCamp.CampaignFlights.push(obj);
      if(flightIdx === 0){
        // remember row
        obj.rowIdx = cIdx;
          flightCreate.push(obj)
    }
  }

  /**
   * Verifies data for Campaign Flight updates / creations and sets the flight operation to 'c' / 'u'
   * @param camp - package campaign row object
   * @param ttdCampaign - ttd API campaign object
   * @returns true for update, false for create
   */
  // prettier-ignore
  const verifyCampaignFlightCreate = (camp: ICloneCampaign,   errFunc:Function ) => {
      // flightgets created with new campaign - verify only
      // verifyFlightData(camp, false, date1904, errFunc);
      registerFlightDataOp(camp, oCamp, flIdx, date1904,errFunc);
    // }
  };
  const verifyAdgroupBudgetUpdate = (
    camp: ICloneCampaign,
    oCamp: ITtdCloneCampaign,
    errFunc: Function
  ) => {
    var agStr = trimStr(camp.UpdateAdGroupBudgets),
      agUpdateOld = oCamp.UpdateAdGroupBudgets,
      testRe = /^yes$|^no$|^$/i,
      testReYes = /^yes$/i,
      agUpdateOk = testRe.test(agStr),
      agUpdateVal = testReYes.test(agStr);
    if (agStr && !agUpdateOk) {
      errFunc('UpdateAdGroupBudgets must be "yes", "no" or empty');
      return oCamp;
    }
    if (oCamp.Version && oCamp.Version.toLowerCase() == 'kokai') {
      oCamp.UpdateAdGroupBudgets = false;
    } else {
      if (agUpdateOld === undefined) {
        oCamp.UpdateAdGroupBudgets = agUpdateVal;
      } else if (agUpdateOld !== agUpdateVal) {
        oCamp.budgetUpdateError = true;
      }
    }
    return oCamp;
  };

  // ensure base campaign ids are looked up and the JSON is sorted after lookup
  // @prettier-ignore
  campJson = updateBaseCampaignIds(campJson, ttdCampData, campNameIdx);

  campJson.forEach((camp: ICloneCampaign, idx) => {
    var campId = trimStr(camp.BaseCampaignId),
      // @ts-ignore
      ttdBaseCampaign: ITtdCampaign = {};
    // @ts-ignore
    oCamp = { cfl: {}, CampaignFlights: [] };
    campComp = campJson[idx + 1];
    // only check flight dates once we collected all flights for the current campaign
    bCheckAllCampaignRows = false;
    bCampaignNameDuplicates = false;
    cIdx = idx;

    if (campId) {
      // baseCampaign is exists -> verify we have it in the index
      //  campaign id should be in our list
      ttdBaseCampaign = ttdCampData[campId];
      if (ttdBaseCampaign) {
        oCamp.BaseCampaignId = campId;
        verifyAdvertiserId(
          { CampaignId: campId, AdvertiserId: camp.BaseAdvertiserId },
          ttdCampData,
          campErrHandler
        );
        // oCamp.nameChange = checkCampaignNameUpdate(camp, ttdCampData);
      } else {
        campErrHandler('Provided BaseCampaignId does not exist');
      }
    } else {
      let bCampName = trimStr(camp.BaseCampaignName);
      // lookup BaseCampaign by name instead of index
      let campNameEl = campNameIdx[bCampName];
      if (campNameEl && campNameEl.count === 1) {
        // campaign name is unique
        campId = campNameEl.id;
        if (!campId.startsWith('n_')) {
          oCamp.BaseCampaignId = campId;
          verifyAdvertiserId(
            { CampaignId: campId, AdvertiserId: camp.BaseAdvertiserId },
            ttdCampData,
            campErrHandler
          );
          ttdBaseCampaign = ttdCampData[campId];
        } else {
          campErrHandler(
            'Provided BaseCampaignName is not unique. Please provide a BaseCampaignId instead'
          );
        }
      }
    }
    var clAgTrim = trimStr(camp.AdGroupsToClone) || '';
    // ensure adgroup names to clone exist and are unique
    // prettier-ignore
    verifyAdGroups(clAgTrim,oCamp,ttdBaseCampaign,campErrHandler);
    // ensure we copy over adgroups to clone before regstering because of verifications happening as part of campaign registration
    oCamp.AdGroupsToClone = clAgTrim;

    let cName = trimStr(camp.TargetCampaignName);
    let campName = registerCampaign(cName);
    let ttdCampaign = campNameIdx[cName];
    if (!campName) {
      // remember row where new campaign starts
      campStart = idx;
      flIdx = 0;
    } else {
      // campaign with more than 1 flight
      if (oCamp.baseCampIdArr && oCamp.baseCampIdArr.length > 1) {
        campErrHandler(
          'The same target campaign name must not clone more than one base campaign.',
          true
        );
      }
      if (oCamp.agCloneArr && oCamp.agCloneArr.length > 1) {
        campErrHandler(
          'The same adgroup cloning list must be used in all flights with the same target campaign name.',
          true
        );
      }
    }
    bCheckAllCampaignRows = shouldCheckAllCloneCampaignRows(camp, campComp);
    if (ttdCampaign) {
      bCampaignNameDuplicates = true;
    } else {
      // register new campaign name
      if (bCheckAllCampaignRows && cName) {
        campNameIdx[cName] = { id: 'n_' + idx, count: 1 };
      }
    }
    flIdx++;
    // patch object with potentially looked up BasedCampaignId
    let xCamp: ICloneCampaign = {};
    Object.assign(xCamp, camp);
    xCamp.BaseCampaignId = campId;
    verifyCampaignFlightCreate(xCamp, campErrHandler);
    // prettier-ignore
    checkFieldAlternatives(camp,'BaseCampaignId','BaseCampaignName', campErrHandler  )
    // prettier-ignore
    checkFieldAlternatives(camp,'BaseAdvertiserId','TargetAdvertiserId', campErrHandler  )
    // prettier-ignore
    let  obj = checkObjColumns(xCamp, ['BaseAdvertiserId', 'BaseCampaignId', 'BaseCampaignName', 'TargetAdvertiserId', 'TargetCampaignName', /*'ShouldCloneAdGroupBudgets',*//* 'UpdateAdGroupBudgets'*/], date1904, campErrHandler, tCLHeader, clRqHeader);
    oCamp.rowIdx = idx;
    Object.assign(oCamp, obj);
    if (ttdBaseCampaign) {
      // Kokai - add version
      if (ttdBaseCampaign.Version) {
        oCamp.Version = ttdBaseCampaign.Version;
      }
    }
    oCamp = verifyAdgroupBudgetUpdate(camp, oCamp, campErrHandler);
    if (bCheckAllCampaignRows) {
      // ensure dates don't overlap and there's at max 1 flight without end date
      let cf = checkFlightDateRanges(oCamp.CampaignFlights, campErrHandler);
      // verify budget settings + end date if pacing !== off
      var pacingMode: campPacing = 'PaceToEndOfFlight';
      // @ts-ignore
      if (ttdBaseCampaign) {
        pacingMode = ttdBaseCampaign.PacingMode;
        // Kokai - add version
        if (ttdBaseCampaign.Version) {
          oCamp.Version = ttdBaseCampaign.Version;
        }
      }
      checkFlightDatesBudget(
        oCamp.CampaignFlights,
        pacingMode,
        date1904,
        campErrHandler
      );
      // campErrHandler will update campError if needed
      // if (!campError) {
      // formatDates will also take care of the time zone adjustment from EST -> UTC for later API usage below
      formatDates(
        cf,
        ['StartDateInclusiveUTC', 'EndDateExclusiveUTC'],
        date1904
      );
      oCamp.CampaignFlights = cf;
      if (oCamp.budgetUpdateError) {
        campErrHandler(
          'UpdateAdGroupBudgets must be consistent across campaign flights',
          true
        );
      }
      if (bCampaignNameDuplicates) {
        campErrHandler(
          'Duplicate TargetCampaignName - please make it unique e.g. by adding a number',
          true
        );
      }

      // }
    }
    // var vError = verificationErrors[idx],  errMsg = '';
    // if(vError ){
    //   errMsg = vError.join(';')
    // }
    // console.log(JSON.stringify(camp) + '\n' + errMsg);
  });

  var combErrors = combineVerificationErrors(verificationErrors);
  var fErrors = combErrors.filter((el) => el);
  var campError = fErrors.length > 0;
  return {
    clCampError: campError,
    clCampVerifyErrors: combErrors,
    clCampCreate: campCreate
  };
};

// export const processExcelFile_o = async (props) => {
//   const SetMessage = (message: string) => {};

//   // const exSetShowUpload = (show: boolean) => {
//   //   // debugger;
//   //   setShowUpload(show);
//   // };
//   var { setProgress, wb, mySheet } = props;
//   // exSetShowUpload(false);
//   setProgress({
//     text: 'Loading Excel file',
//     percent: 50,
//     show: true
//   });
//   // //verify Sheet names - not required - we use 1st sheet instead
//   //
//   // if (!namesOk(['CampaignsToClone', 'CreativeAssignment'], wb.SheetNames)) {
//   //   //  updateGridMessage(gm, '', 'error -> incorrect Sheet names');
//   //   setProgress({
//   //     show: false,
//   //     immediate: true
//   //   });
//   //   //clearGridMessages();
//   //   SetMessage('wrong template (incorrect sheet names)');
//   //   return;
//   // }

//   // sIndex = wb.SheetNames.indexOf('CreativeAssignment');
//   // var creativeJson = utils.sheet_to_json(wb.Sheets[wb.SheetNames[sIndex]]);

//   // shows if unusual 1904 based format is used

//   // var date1904 = !!((wb.Workbook || {}).WBProps || {}).date1904;
//   // read file with blanks for header detection
//   var cloneJson = props.cloneJSON;
//   // cloneJson = utils.sheet_to_json(mySheet, {
//   //   dateNF: 22
//   // });
//   // dateNF 22 = m/d/yy hh:mm:ss and avoids potential date conversion issues
//   // header will be available as object keys, as long as there are at least 2 rows in the sheet

//   console.log('clone Json:', cloneJson);
//   console.log(JSON.stringify(cloneJson));

//   // cloning will be done separately - not required anymore
//   // // clone sheet exists if we get here, so index can't be -1
//   // var sIndex = wb.SheetNames.indexOf('CampaignsToClone');

//   if (cloneJson.length === 0 || !cloneJson[0]) {
//     SetMessage('No data for cloning provided');
//     setProgress({
//       show: false,
//       immediate: true
//     });
//     return;
//   }
//   // debugger;
//   // enforce RowLimit
//   if (cloneJson.length > RowLimit) {
//     SetMessage('Only ' + RowLimit + ' rows can be processed at once');
//     setProgress({
//       show: false,
//       immediate: true
//     });
//     return;
//   }

//   if (!verifyHeader(xlCloneHeader, mySheet)) {
//     SetMessage('Wrong cloning header');
//     setProgress({
//       show: false,
//       immediate: true
//     });
//     return;
//   }

//   // updateGridMessage(gm, '', 'done');
//   //gm = setGridMessage('Verifying campaign configuration', 'running...');
//   setProgress({
//     text: 'Template verification',
//     percent: 8
//   });

//   let fmtErr;
//   // let fmtErr = checkColumns(
//   //   cloneJson,
//   //   ['StartDate', 'EndDate'],
//   //   'date',
//   //   date1904
//   // );

//   // fmtErr =
//   //   checkColumns(cloneJson, ['Budget', 'Daily Budget Target'], 'number') ||
//   //   fmtErr;

//   // fmtErr =
//   //   checkColumns(
//   //     cloneJson,
//   //     ['Impression Budget', 'Daily Impression Target'],
//   //     'int'
//   //   ) || fmtErr;

//   // fmtErr =
//   //   checkColumns(cloneJson, ['ShouldCloneAdGroupBudgets'], 'Yes/No') || fmtErr;

//   // if (xlCloneHeader.includes('KeepAdGroupAutoOptimization')) {
//   //   fmtErr =
//   //     checkColumns(cloneJson, ['KeepAdGroupAutoOptimization'], 'Yes/No') ||
//   //     fmtErr;
//   // }

//   // test base and clone targets for duplicates (needs to be done 1st)
//   var cBase = 'Campaign Name (Existing)';
//   var cTarget = 'New Campaign Name';
//   var bcTestArr = cloneJson.map((el: any) =>
//     JSON.stringify({
//       cb: el[cBase],
//       cc: el[cTarget]
//     })
//   );
//   // console.log(bcTestArr);

//   fmtErr =
//     alertDuplicates(
//       bcTestArr,
//       cloneJson,
//       'Duplicate target name for cloning of base campaign detected'
//     ) || fmtErr;

//   //test clone targets for duplicates

//   var cTestArr = cloneJson.map((el: any) => el[cTarget]);
//   // console.log(cTestArr);
//   fmtErr =
//     alertDuplicates(
//       cTestArr,
//       cloneJson,
//       'Duplicate target name detected',
//       cTestArr,
//       bcTestArr // avoid errors which are already flagged via base clone combinations
//     ) || fmtErr;

//   // create Array of Base campaigns for later lookups
//   var bTestArr = cloneJson.map((el: any) => el[cBase]);

//   // verify we have all base and target names
//   fmtErr =
//     checkValueExists(cloneJson, bTestArr, 'Campaign Name (Existing)') || fmtErr;
//   fmtErr = checkValueExists(cloneJson, cTestArr, 'New Campaign Name') || fmtErr;

//   //check endDate >= Startdate
//   // fmtErr = checkStartEndDate_o('StartDate', 'EndDate', cloneJson) || fmtErr;

//   // verify start date is provided if we have any budget setting

//   // construct Set of unique merged campaign names for further checks
//   var bcNameSet = new Set(
//     /*build subArray of names and fold those back into normal array elements using flatMap*/
//     cloneJson.flatMap((el: any) => [el[cBase], el[cTarget]])
//   );

//   // console.log('combined names: ' + JSON.stringify(bcNameArr));
//   // console.log('base names: ' + JSON.stringify(bTestArr));
//   // console.log('clone names: ' + JSON.stringify(cTestArr));
//   setProgress({
//     text: 'Template verification',
//     percent: 15
//   });
//   var data = []; // await loadCampaignData(() => {});
//   setProgress({
//     text: 'Template verification',
//     percent: 25
//   });
//   if (data && data.length !== 0) {
//     let apiCamp = data;
//     // now filter out the campaigns we might need by looking at the names in the spreadsheet
//     //@ts-ignore
//     apiCamp = data.filter((el: any) => {
//       return bcNameSet.has(el.CampaignName);
//     });
//     console.log('apiCamp:', apiCamp);
//     let apiCampNames = apiCamp.map((el: any) => {
//       return el.CampaignName;
//     });
//     let apiCampNameId = apiCamp.reduce((acc: any, curEl: any) => {
//       acc[curEl.CampaignName] = curEl.CampaignId;
//       return acc;
//     }, {});

//     // paging determines end date requirements or if daily budgets are allowed
//     let apiCampNamePacing = apiCamp.reduce((acc: any, curEl: any) => {
//       acc[curEl.CampaignName] = curEl.PacingMode;
//       return acc;
//     }, {});

//     // console.log(apiCampNames);
//     // console.log(JSON.stringify(apiCampNamePacing));
//     // console.log(JSON.stringify(apiCampNameId));
//     let nameError = false;
//     // ensure we only use existing campaigns as base -> mark Excel Campaigns that don't exist in api campaign names
//     nameError = markError(
//       bTestArr,
//       namesExistSet(
//         bTestArr.filter((el: any) => !(typeof el === 'undefined')),
//         apiCampNames,
//         false
//       ),
//       cloneJson,
//       'Base campaign not found'
//     );
//     // mark cloned Campaign names that already exist as error
//     // nameError =
//     //   markError(
//     //     cTestArr,
//     //     namesExistSet(
//     //       cTestArr.filter((el: any) => !(typeof el === 'undefined')),
//     //       apiCampNames,
//     //       true
//     //     ),
//     //     cloneJson,
//     //     'New campaign name already exists'
//     //   ) || nameError;
//     // look for duplicates in existing api campaigns

//     // nameError =
//     //   alertDuplicates(
//     //     apiCampNames,
//     //     cloneJson,
//     //     'Base campaign exists more than once in UI - please rename it first',
//     //     bTestArr
//     //   ) || nameError;
//     // setProgress({
//     //   text: 'Template verification',
//     //   percent: 30
//     // });
//     //debugger;

//     // create JSON with split adgroups + mark error in errorRet
//     let errorRet = false;
//     let cloneAgJson = transformCloneJson({ cloneJson, errorRet });

//     console.log('cloneAgJson', cloneAgJson);
//     // add CampaignIDs + names for cloning to cloneAgJson
//     cloneAgJson = cloneAgJson.map((el: any) => {
//       el.CampaignId = apiCampNameId[el[cBase]];
//       el.CampaignName = el[cTarget];
//       return el;
//     });

//     setProgress({
//       text: 'Template verification',
//       percent: 35
//     });
//     // debugger;
//     // fmtErr = checkFlightDatesBudget(cloneJson, apiCampNamePacing) || fmtErr;

//     setProgress({
//       text: 'Template verification',
//       percent: 40
//     });

//     console.log(
//       'cloneAgJson with BaseCampaignIds and target campaign names',
//       cloneAgJson
//     );

//     // only look at campaigns with some adgroup settings
//     var cloneAgJsonFilt = cloneAgJson.filter(
//       // potential adgroup related warning might require ad groups even if those are not specified for enabling / cloning when budgets are set
//       (el: any) =>
//         el.agCl ||
//         el.agEn ||
//         (el.ShouldCloneAdGroupBudgets !== 'No' && el.Budget)
//     );
//     console.log('cloneAgJsonFilt', cloneAgJsonFilt);

//     // //get unique campaign ids for Adgroup lookup
//     var campIdsAg = Array.from(
//       new Set(cloneAgJsonFilt.map((el: any) => el.CampaignId))
//     ).filter((el: any) => el !== undefined); // avoid undefined elements;

//     // var campIdsAg = Array.from(
//     //   new Set(
//     //     cloneAgJson
//     //       .map((el: any) => el.CampaignId)
//     //       .filter((el: any) => el !== undefined) // avoid undefined elements
//     //   )
//     // );

//     // kf template change enable
//     // // all campaign ids
//     // var campIdsAg = apiCamp.map((el: any) => {
//     //   return el.CampaignId;
//     // });
//     // kf template change enable

//     console.log('campIdsAg', campIdsAg);
//   }
// };
